const userRolesLookup = {
  ADMIN: 'Admin',
  SUPPORT: 'Support',
  ACCOUNT_OWNER: 'Account Owner',
  ACCOUNT_ASSISTANT: 'Account Assistant',
  BUSINESS_UNIT_MANAGER: 'Business Unit Manager',
  TECHNICAL_LEAD: 'Technical Lead',
  DEVELOPER: 'Developer',
  DEMAND_MANAGER: 'Demand Manager',
};

const assignableRoles = (userRoles) => {
  const newLookup = {};
  Object.keys(userRolesLookup).forEach((key) => {
    if (userRoles.includes(key)) {
      newLookup[key] = userRolesLookup[key];
    }
  });
  return newLookup;
};

export { userRolesLookup, assignableRoles };
