import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PaginatedTable from '../../components/PaginatedTable';
import Auth from '../../api/auth';
import usePaginatedApiCall from '../../hooks/usePaginatedApiCall';

const JuiceGroups = () => {
  const navigate = useNavigate();
  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState('');
  const tableSize = 10;
  const [data, totalItems] = usePaginatedApiCall(
    Auth.fetchV2JuiceGroups,
    offset,
    tableSize,
    search,
  );

  const columns = [
    {
      id: 'juicegroup',
      title: 'JuiceGroup',
    },
    {
      id: 'type',
      title: 'Type',
    },
    {
      id: 'organization',
      title: 'Organization',
    },
  ];

  const mapToRows = data?.juiceGroups?.map(({
    juiceGroup, type, productId, projectId, organization,
  }) => ({
    key: String(juiceGroup),
    onRowClick: () => navigate(`/products/${productId}`),
    productId,
    projectId,
    organizationId: organization.id,
    data: [
      {
        field: 'juicegroup',
        value: juiceGroup,
      },
      {
        field: 'type',
        value: type === 'evaluation' ? 'compute' : type,
      },
      {
        field: 'organization',
        value: organization.enterpriseName,
      },
    ],
  }));

  return (
    <PaginatedTable
      header="JuiceGroups"
      fields={columns}
      totalItems={totalItems}
      tableSize={tableSize}
      rows={mapToRows || []}
      onPagination={setOffset}
      onSearch={setSearch}
    />
  );
};

export default JuiceGroups;
