import { useFormik } from 'formik';
import { TextField } from '@mui/material';
import * as Yup from 'yup';
import { ScaleButton } from '@telekom/scale-components-react';
import PropTypes from 'prop-types';

import { regexValidator } from '../../../../helpers/regexValidator';

const validationSchema = Yup.object().shape({
  osProjectName: Yup.string()
    .required('Required')
    .matches(regexValidator.noSpace, 'Spaces not allowed'),
});

const EvaluationForm = ({ disabled, onSubmit }) => {
  const formik = useFormik({
    initialValues: { osProjectName: '' },
    validationSchema,
    onSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        fullWidth
        disabled={disabled}
        label="Project Name"
        value={formik.values.osProjectName}
        placeholder="Enter Development Project Name"
        onChange={(e) => formik.setFieldValue('osProjectName', e.target.value)}
        onBlur={formik.handleBlur}
        error={Boolean(formik.errors.osProjectName)}
        helperText={formik.errors.osProjectName}
      />
      <ScaleButton
        variant="primary"
        size="small"
        disabled={!formik.dirty || (!formik.isValid || formik.isSubmitting)}
      >
        Submit
      </ScaleButton>
    </form>
  );
};

EvaluationForm.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default EvaluationForm;
