import { Container } from '@mui/material';
import useMarkdownFile from '../../hooks/useMarkdownFile';
import contactUs from '../../markdowns/contactUs.md';

const ContactUs = () => {
  const { markdownContent } = useMarkdownFile(contactUs);

  return (
    <Container maxWidth="md">
      {markdownContent}
    </Container>
  );
};

export default ContactUs;
