import { of } from 'rxjs';
import { enqueueSnackbar } from './notifier/actions';
import { setError, requestFailed } from './status/actions';

export const handleError = (error) => {
  if (error.status === 503) {
    return of(
      requestFailed(),
      setError('NETWORK_ERROR'),
    );
  }

  return of(
    requestFailed(),
    enqueueSnackbar({
      message: error.message,
      options: { variant: 'error' },
    }),
  );
};
