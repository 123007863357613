import http from './httpClient';


class Demands {
  static getDemands() {
    return http.get(`${import.meta.env.VITE_DEMANDS_V0_BASE}/demands`);
  }

  static getDemand(demandId) {
    return http.get(`${import.meta.env.VITE_DEMANDS_V0_BASE}/demands/${demandId}`);
  }

  static createDemand(data) {
    return http.post(`${import.meta.env.VITE_DEMANDS_V0_BASE}/demands`, data);
  }

  static updateDemand(demandId, data) {
    return http.patch(`${import.meta.env.VITE_DEMANDS_V0_BASE}/demands/${demandId}`, data, {
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    });
  }
}

export default Demands;
