import { ofType, combineEpics } from 'redux-observable';
import { mergeMap, catchError } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { fetchProductMembers } from '../productMembers/actions';
import { handleError } from '../../errorHandler';
import Safescarf from '../../../api/safescarf';
import {
  CREATE_SAFESCARF_PRODUCT_STARTED,
  createProductFulfilled,
  FETCH_SAFESCARF_PRODUCTS_STARTED,
  fetchProductsFulfilled,
  UPDATE_SAFESCARF_PRODUCT_STARTED,
  updateProductFulfilled,
  DELETE_SAFESCARF_PRODUCT_STARTED,
  deleteProductFulfilled,
} from './actions';

const createProductEpic = (action$) => action$.pipe(
  ofType(CREATE_SAFESCARF_PRODUCT_STARTED),
  mergeMap((action) => from(Safescarf.createSafescarfProduct(action.payload)).pipe(
    mergeMap((response) => of(
      createProductFulfilled(response.data),
      fetchProductMembers(),
    )),
    catchError(handleError),
  )),
);
const fetchProductsEpic = (action$) => action$.pipe(
  ofType(FETCH_SAFESCARF_PRODUCTS_STARTED),
  mergeMap((action) => from(Safescarf.fetchSafescarfProducts(action.payload)).pipe(
    mergeMap((response) => of(
      fetchProductsFulfilled(response.data),
    )),
    catchError(handleError),
  )),
);
const updateProductEpic = (action$) => action$.pipe(
  ofType(UPDATE_SAFESCARF_PRODUCT_STARTED),
  mergeMap((action) => from(Safescarf.updateSafescarfProduct(action.payload)).pipe(
    mergeMap((response) => of(
      updateProductFulfilled(response.data),
    )),
    catchError(handleError),
  )),
);
const deleteProductEpic = (action$) => action$.pipe(
  ofType(DELETE_SAFESCARF_PRODUCT_STARTED),
  mergeMap((action) => from(Safescarf.deleteSafescarfProduct(action.payload)).pipe(
    mergeMap(() => of(
      deleteProductFulfilled(action.payload),
    )),
    catchError(handleError),
  )),
);

export default combineEpics(
  createProductEpic,
  fetchProductsEpic,
  updateProductEpic,
  deleteProductEpic,
);
