import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { ScaleModal } from '@telekom/scale-components-react';

import CreateProductForm from './CreateProductForm';
import rbacCheck from '../../../../../rbac/rbacCheck';
import Table from '../../../../../components/Table';

import { fetchProducts } from '../../../../../redux/safescarf/products/actions';
import { fetchProductTypes } from '../../../../../redux/safescarf/productTypes/actions';

const Products = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const intl = useIntl();
  const userData = useSelector((state) => state.user);
  const [createModalOpened, setCreateModalOpened] = useState(false);

  const products = useSelector((state) => state.safescarf.products);

  const productTypes = useSelector((state) => state.safescarf.productTypes);

  useEffect(() => {
    if (!products.length) dispatch(fetchProducts());
    if (!productTypes.length) dispatch(fetchProductTypes());
  }, []);

  const canCreateProduct = rbacCheck(userData.roles, '/products/safescarf/products:POST');

  const productTypeLookup = productTypes.reduce((acc, productType) => {
    acc[productType.id] = productType.name;
    return acc;
  }, {});

  const productRows = products.map((product) => ({
    key: product.id.toString(),
    onRowClick: () => navigate(product.id.toString()),
    data: [
      { field: 'name', value: product.name },
      { field: 'description', value: product.description },
      { field: 'prod_type', value: productTypeLookup[product.prod_type] },
    ],
  }));


  return (
    <>
      {createModalOpened && (
        <ScaleModal
          opened
          size="small"
          heading="Create Product"
          onScale-close={() => setCreateModalOpened(false)}
        >
          <CreateProductForm
            onCancel={() => setCreateModalOpened(false)}
            onSubmit={() => setCreateModalOpened(false)}
          />
        </ScaleModal>
      )}
      <Table
        header="Products"
        fields={[
          { title: 'Name', id: 'name' },
          { title: 'Description', id: 'description' },
          { title: 'Product type', id: 'prod_type' },
        ]}
        rows={productRows}
        createAction={{
          disabled: !canCreateProduct,
          onClick: () => setCreateModalOpened(true),
          tooltip: !canCreateProduct ? intl.formatMessage({ id: 'createProductDisabled' }) : '',
        }}
      />
    </>
  );
};


export default Products;
