import PropTypes from 'prop-types';
import { ScaleButton } from '@telekom/scale-components-react';
import { Typography } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import { useIntl } from 'react-intl';

const Success = ({
  messageId, onSubmit,
}) => {
  const intl = useIntl();
  return (
    <>
      <DoneIcon style={{
        fontSize: '7rem', color: 'limegreen', display: 'flex', margin: 'auto',
      }}
      />
      <Typography>
        {intl.formatMessage({ id: messageId })}
      </Typography>
      <ScaleButton
        slot="action"
        onClick={() => {
          onSubmit();
        }}
      >
        Okay
      </ScaleButton>
    </>
  );
};

Success.propTypes = {
  messageId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default Success;
