import { useParams } from 'react-router-dom';
import JuiceGroupContacts from './JuiceGroupContacts';
import JuiceGroupInfo from './JuiceGroupInfo';
import Section from '../../components/Section';


const HypervisorDetails = () => {
  const { virtualMachine } = useParams();

  return (
    <>
      <Section header={virtualMachine}>
        <JuiceGroupInfo />
      </Section>
      <Section>
        <JuiceGroupContacts />
      </Section>
    </>
  );
};

export default HypervisorDetails;
