import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Container } from '@mui/material';
import { ScaleModal } from '@telekom/scale-components-react';
import rbacCheck from '../../rbac/rbacCheck';
import Table from '../../components/Table';
import useApiCall from '../../hooks/useApiCall';
import CreateOrganizationForm from './CreateOrganizationForm';
import Auth from '../../api/auth';
import Page404 from '../Error/Page404';

const Organizations = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const createOrganizationCall = useApiCall(Auth.createV1Organization);
  const getOrganizationsCall = useApiCall(Auth.fetchV1Organizations);
  const formRef = useRef(null);
  const userData = useSelector((state) => state.user);

  const [organizations, setOrganizations] = useState([]);
  const [createModalOpened, setCreateModalOpened] = useState(false);
  const [notFound, setNotFound] = useState(false);

  const canCreateOrganization = rbacCheck(userData.roles, '/organization:POST');

  const getOrganizations = async () => {
    const [data, error] = await getOrganizationsCall();
    if (error && error.status === 404) setNotFound(true);
    if (data) setOrganizations(data);
  };

  const createOrganization = async (formValues) => {
    const [data, error] = await createOrganizationCall(formValues);
    if (error) setCreateModalOpened(false);
    if (data) {
      getOrganizations();
      setCreateModalOpened(false);
    }
  };

  useEffect(() => {
    getOrganizations();
  }, []);

  const mapToRows = organizations.map((org) => ({
    key: org._id,
    onRowClick: () => navigate(org._id),
    data: [
      {
        field: 'enterpriseName',
        value: org.enterpriseName,
      },
      {
        field: 'crmid',
        value: org.CRMId,
      },
      {
        field: 'accountowner',
        value: org.accountOwner?.email,
      },
    ],
  }));

  return notFound ? <Page404 /> : (
    <>
      {createModalOpened && (
        <ScaleModal
          heading="Create Organization"
          size="small"
          opened={createModalOpened}
          onScale-close={() => {
            formRef.current?.resetForm();
            setCreateModalOpened(false);
          }}
        >
          <CreateOrganizationForm
            formRef={formRef}
            onSubmit={createOrganization}
            onCancel={() => setCreateModalOpened(false)}
          />
        </ScaleModal>
      )}
      <Container maxWidth="lg">
        <Table
          header="Organizations"
          fields={[
            {
              title: 'Name',
              id: 'name',
            },
            {
              title: 'CRMid',
              id: 'crmid',
            },
            {
              title: 'Account Owner',
              id: 'accountowner',
            },
          ]}
          rows={mapToRows}
          createAction={{
            disabled: !canCreateOrganization,
            onClick: () => setCreateModalOpened(true),
            tooltip: !canCreateOrganization ? intl.formatMessage({ id: 'organizationCreationPermissionErr' }) : '',
          }}
        />
      </Container>
    </>
  );
};

export default Organizations;
