import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ScaleTable } from '@telekom/scale-components-react';
import useApiCall from '../../../../../hooks/useApiCall';

import Provision from '../../../../../api/provision';

const CloudTable = ({ usage }) => {
  const { total } = usage;
  const byComputeNodeType = usage.by_compute_node_type;
  const CNTypes = ['shared_local', 'shared', 'dedicated', 'dedicated_local'];
  return (
    <ScaleTable>
      <table>
        <thead>
          <tr>
            <th>CN Type</th>
            <th>Server #</th>
            <th>Storage Volume (GB)</th>
            <th>vCPU (Shared)</th>
            <th>vCPU (Dedicated)</th>
            <th>RAM (Shared)(GB)</th>
            <th>RAM (Dedicated)(GB)</th>
            <th>Root disk local (GB)</th>
            <th>Root disk (GB)</th>
          </tr>
        </thead>
        <tbody>
          {CNTypes.map((CNTYpe) => (
            <tr key={CNTYpe}>
              <td>{CNTYpe}</td>
              <td>{byComputeNodeType[CNTYpe].vms_total}</td>
              <td>-</td>
              <td>{byComputeNodeType[CNTYpe].cpus_shared_total}</td>
              <td>{byComputeNodeType[CNTYpe].cpus_dedicated_total}</td>
              <td>{byComputeNodeType[CNTYpe].ram_shared_gigabytes}</td>
              <td>{byComputeNodeType[CNTYpe].ram_dedicated_gigabytes}</td>
              <td>{byComputeNodeType[CNTYpe].root_disk_local_gigabytes}</td>
              <td>{byComputeNodeType[CNTYpe].root_disk_gigabytes}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td>total</td>
            <td>{total.vms_total}</td>
            <td>{total.volume_gigabytes}</td>
            <td>{total.cpus_shared_total}</td>
            <td>{total.cpus_dedicated_total}</td>
            <td>{total.ram_shared_gigabytes}</td>
            <td>{total.ram_dedicated_gigabytes}</td>
            <td>{total.root_disk_local_gigabytes}</td>
            <td>{total.root_disk_gigabytes}</td>
          </tr>
        </tfoot>
      </table>
    </ScaleTable>
  );
};

const UsageSummary = ({ juiceGroup, cloudId }) => {
  const [virtualResources, setVirtualResources] = useState([]);
  const fetchVirtualResources = useApiCall(Provision.fetchOSVirtualResources, null, null);

  useEffect(() => {
    async function getVirtualResources() {
      const [data] = await fetchVirtualResources(juiceGroup, [cloudId]);
      if (data) setVirtualResources(data);
    }
    if (cloudId && juiceGroup) getVirtualResources();
  }, [cloudId, juiceGroup]);

  if (!virtualResources.length) return <p>No usage data found</p>;
  return (
    <CloudTable usage={virtualResources[0].usage} />
  );
};


CloudTable.propTypes = {
  usage: PropTypes.objectOf(PropTypes.oneOfType(
    [
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
    ],
  )).isRequired,
};
UsageSummary.propTypes = {
  juiceGroup: PropTypes.string.isRequired,
  cloudId: PropTypes.string.isRequired,
};

export default UsageSummary;
