import {
  useField,
  useFormikContext,
} from 'formik';
import PropTypes from 'prop-types';
import { Autocomplete } from '../../InputFields';

export const FormikAutocomplete = ({
  name,
  label,
  autoFocus,
  disabled,
  options,
}) => {
  const [field, meta, helpers] = useField(name);
  const { values } = useFormikContext();
  const isDisabled = typeof disabled === 'string' ? !values[disabled] : disabled;
  const valueObjects = (field.value).map((value) => {
    const matchingOption = options.find((option) => option.value === value);
    return matchingOption || null;
  });

  return (
    <Autocomplete
      id={field.name}
      name={field.name}
      value={valueObjects}
      onChange={(_event, newValue) => {
        helpers.setValue(newValue.map((i) => i.value));
      }}
      onBlur={field.onBlur}
      label={label}
      autoFocus={autoFocus}
      disabled={isDisabled}
      onOpen={() => helpers.setTouched(true)}
      options={options}
      helperText={meta.touched ? meta.error : ''}
    />
  );
};

FormikAutocomplete.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

FormikAutocomplete.defaultProps = {
  autoFocus: false,
  disabled: false,
};
