import {
  useField,
  useFormikContext,
} from 'formik';
import { PropTypes } from 'prop-types';
import { Checkbox } from '../../InputFields';

export const FormikCheckbox = ({
  name,
  label,
  importanceRef,
  childFields,
}) => {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();

  const handleChange = (e) => {
    const isChecked = e.target.checked;
    if (!meta.initialValue && !isChecked && Array.isArray(childFields)) {
      childFields.forEach((f) => setFieldValue(f, ''));
    }
    field.onChange(e);
  };

  return (
    <Checkbox
      id={field.name}
      name={field.name}
      importanceRef={importanceRef}
      onBlur={field.onBlur}
      label={label}
      onChange={handleChange}
      checked={field.value}
    />
  );
};

FormikCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  importanceRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
  childFields: PropTypes.arrayOf(PropTypes.string),
};

FormikCheckbox.defaultProps = {
  importanceRef: null,
  childFields: [],
};
