import {
  CREATE_SAFESCARF_USER_FULFILLED,
  FETCH_SAFESCARF_USERS_FULFILLED,
  UPDATE_SAFESCARF_USER_FULFILLED,
} from './actions';


const usersReducer = (state = [], action) => {
  switch (action.type) {
    case CREATE_SAFESCARF_USER_FULFILLED:
      return [...state, action.payload];
    case FETCH_SAFESCARF_USERS_FULFILLED:
      return action.payload;
    case UPDATE_SAFESCARF_USER_FULFILLED:
      return state.map((user) => {
        if (user.username === action.payload.username) {
          return { ...user, ...action.payload };
        }
        return user;
      });
    default:
      return state;
  }
};

export { usersReducer };

