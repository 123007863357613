import { Grid } from '@mui/material';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ScaleDropdownSelect, ScaleDropdownSelectItem } from '@telekom/scale-components-react';
import Provision from '../../api/provision';
import { ReportsContext } from './ReportsContext';
import useApiCall from '../../hooks/useApiCall';
import Table from '../../components/Table';

const tenantFields = [
  { id: 'name', title: 'Name' },
  { id: 'juiceGroup', title: 'Juice Group' },
];

const TenantsReport = () => {
  const navigate = useNavigate();
  const getVirtualCloudsCall = useApiCall(Provision.fetchVirtualClouds);
  const {
    juiceGroup: {
      cloudId,
      setCloudId,
      osProjects,
      setOsProjects,
      clouds,
    },
  } = useContext(ReportsContext);

  const getVirtualClouds = async (selectedCloudId) => {
    const [data] = await getVirtualCloudsCall({ cloudId: selectedCloudId });
    if (data) {
      const projects = data.flatMap(
        (virtualCloud) => virtualCloud.projects.map((project) => ({
          key: project.id.toString(),
          onRowClick: () => navigate(`${selectedCloudId}/${virtualCloud.juice_group.name}/${project.name}`),
          data: [
            { field: 'name', value: project.name },
            { field: 'juiceGroup', value: virtualCloud.juice_group.name },
          ],
        })),
      );
      setOsProjects(projects);
    }
  };

  return (
    <Grid
      container
      direction="column"
      rowSpacing={10}
    >
      <Grid item>
        <ScaleDropdownSelect
          controlled
          name="cloudDropdown"
          label="Select a cloud to see the available tenants"
          value={cloudId}
          onScale-change={(event) => {
            setCloudId(event.detail.value);
            getVirtualClouds(event.detail.value);
          }}
        >
          { clouds.map(
            (item) => (
              <ScaleDropdownSelectItem
                key={item.id}
                value={item.id}
              >
                {item.name}
              </ScaleDropdownSelectItem>
            ),
          )}
        </ScaleDropdownSelect>
      </Grid>
      <Grid item>
        <Table
          header="Tenants Report"
          fields={tenantFields}
          rows={osProjects}
        />
      </Grid>
    </Grid>
  );
};

export default TenantsReport;
