import { useSelector } from 'react-redux';
import { ScaleLoadingSpinner } from '@telekom/scale-components-react';

const Overlay = () => {
  const loading = useSelector((state) => state.status.loading);

  return (
    <div
      style={{
        position: 'fixed',
        display: loading ? 'flex' : 'none',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        backgroundColor: '#dddddd78',
        height: '100vh',
        width: '100vw',
        zIndex: 1000,
      }}
    >
      <ScaleLoadingSpinner
        alignment="horizontal"
        size="large"
      />
    </div>
  );
};

export default Overlay;
