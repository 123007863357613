export const FETCH_ROLES_STARTED = 'FETCH_ROLES_STARTED';
export const FETCH_ROLES_FULFILLED = 'FETCH_ROLES_FULFILLED';

export const fetchRoles = () => (
  { type: FETCH_ROLES_STARTED }
);

export const fetchRolesFulfilled = (data) => (
  { type: FETCH_ROLES_FULFILLED, payload: data }
);
