export const FETCH_SAFESCARF_USERS_STARTED = 'FETCH_SAFESCARF_USERS_STARTED';
export const FETCH_SAFESCARF_USERS_FULFILLED = 'FETCH_SAFESCARF_USERS_FULFILLED';
export const CREATE_SAFESCARF_USER_STARTED = 'CREATE_SAFESCARF_USER_STARTED';
export const CREATE_SAFESCARF_USER_FULFILLED = 'CREATE_SAFESCARF_USER_FULFILLED';
export const UPDATE_SAFESCARF_USER_STARTED = 'UPDATE_SAFESCARF_USER_STARTED';
export const UPDATE_SAFESCARF_USER_FULFILLED = 'UPDATE_SAFESCARF_USER_FULFILLED';

export const fetchUsers = (params) => (
  { type: FETCH_SAFESCARF_USERS_STARTED, payload: params }
);

export const fetchUsersFulfilled = (data) => (
  { type: FETCH_SAFESCARF_USERS_FULFILLED, payload: data }
);

export const createUser = (data) => (
  { type: CREATE_SAFESCARF_USER_STARTED, payload: data }
);

export const createUserFulfilled = (data) => (
  { type: CREATE_SAFESCARF_USER_FULFILLED, payload: data }
);

export const updateUser = (data) => (
  { type: UPDATE_SAFESCARF_USER_STARTED, payload: data }
);

export const updateUserFulfilled = (data) => (
  { type: UPDATE_SAFESCARF_USER_FULFILLED, payload: data }
);
