import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { ScaleModal } from '@telekom/scale-components-react';

import Table from '../../../components/Table';
import CreateBusinessUnitForm from './CreateBusinessUnitForm';
import rbacCheck from '../../../rbac/rbacCheck';
import useApiCall from '../../../hooks/useApiCall';
import Auth from '../../../api/auth';

const BusinessUnits = () => {
  const { organizationId } = useParams();
  const navigate = useNavigate();
  const intl = useIntl();
  const userData = useSelector((state) => state.user);
  const [businessUnits, setBusinessUnits] = useState([]);
  const [createModalOpened, setCreateModalOpened] = useState(false);
  const canCreateBusinessUnit = rbacCheck(userData.roles, '/business-units:POST');

  const createBusinessUnitCall = useApiCall(Auth.createV1BusinessUnit);
  const getBusinessUnitsCall = useApiCall(Auth.fetchV1BusinessUnits);

  const getBusinessUnits = async () => {
    const [data] = await getBusinessUnitsCall({ organizationId });
    if (data) setBusinessUnits(data);
  };

  const createBusinessUnit = async (formValues) => {
    const [data, error] = await createBusinessUnitCall({
      ...formValues,
      businessUnitManager: formValues.businessUnitManager || null,
      organizationId,
    });
    if (error) setCreateModalOpened(false);
    if (data) {
      getBusinessUnits();
      setCreateModalOpened(false);
    }
  };

  useEffect(() => {
    getBusinessUnits();
  }, []);

  const mapBusinessUnitsToRows = businessUnits.map((bu) => ({
    key: bu._id,
    onRowClick: () => navigate(`/business-units/${bu._id}`),
    data: [
      {
        field: 'name',
        value: bu.name,
      },
      {
        field: 'description',
        value: bu.description,
      },
      {
        field: 'businessUnitManager',
        value: bu.businessUnitManager?.email,
      },
    ],
  }));

  return (
    <>
      {createModalOpened && (
        <ScaleModal
          opened
          size="small"
          heading="Create Business Unit"
          onScale-close={() => setCreateModalOpened(false)}
        >
          <CreateBusinessUnitForm
            onSubmit={createBusinessUnit}
            onCancel={() => setCreateModalOpened(false)}
          />
        </ScaleModal>
      )}
      <Table
        header="Business Units"
        fields={[
          {
            id: 'name',
            title: 'Name',
          },
          {
            id: 'description',
            title: 'Description',
          },
          {
            id: 'businessUnitManager',
            title: 'Business Unit Manager',
          },
        ]}
        rows={mapBusinessUnitsToRows}
        createAction={{
          disabled: !canCreateBusinessUnit,
          onClick: () => setCreateModalOpened(true),
          tooltip: !canCreateBusinessUnit ? intl.formatMessage({ id: 'businessUnitCreatePermissionErr' }) : '',
        }}
      />
    </>
  );
};

export default BusinessUnits;
