import { ofType, combineEpics } from 'redux-observable';
import { mergeMap, catchError } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { fetchProductTypeMembers } from '../productTypeMembers/actions';
import { handleError } from '../../errorHandler';
import Safescarf from '../../../api/safescarf';
import {
  CREATE_PRODUCT_TYPE_STARTED,
  createProductTypeFulfilled,
  FETCH_PRODUCT_TYPES_STARTED,
  fetchProductTypesFulfilled,
  UPDATE_PRODUCT_TYPE_STARTED,
  updateProductTypeFulfilled,
} from './actions';

// PRODUCT_TYPES
const createProductTypeEpic = (action$) => action$.pipe(
  ofType(CREATE_PRODUCT_TYPE_STARTED),
  mergeMap((action) => from(Safescarf.createSafescarfProductType(action.payload)).pipe(
    mergeMap((response) => of(
      createProductTypeFulfilled(response.data),
      fetchProductTypeMembers(),
    )),
    catchError(handleError),
  )),
);
const fetchProductTypesEpic = (action$) => action$.pipe(
  ofType(FETCH_PRODUCT_TYPES_STARTED),
  mergeMap(() => from(Safescarf.fetchSafescarfProductTypes()).pipe(
    mergeMap((response) => of(
      fetchProductTypesFulfilled(response.data),
    )),
    catchError(handleError),
  )),
);
const updateProductTypeEpic = (action$) => action$.pipe(
  ofType(UPDATE_PRODUCT_TYPE_STARTED),
  mergeMap((action) => from(Safescarf.updateSafescarfProductType(action.payload)).pipe(
    mergeMap((response) => of(
      updateProductTypeFulfilled(response.data),
    )),
    catchError(handleError),
  )),
);

export default combineEpics(
  createProductTypeEpic,
  fetchProductTypesEpic,
  updateProductTypeEpic,
);
