import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { ScaleModal, ScaleTag } from '@telekom/scale-components-react';

import rbacCheck from '../../../../rbac/rbacCheck';
import CreateProductForm from './CreateProductForm';
import Table from '../../../../components/Table';
import useApiCall from '../../../../hooks/useApiCall';
import Auth from '../../../../api/auth';

const Products = () => {
  const fetchProducts = useApiCall(Auth.fetchV2Products);
  const navigate = useNavigate();
  const intl = useIntl();
  const formRef = useRef(null);
  const { projectId } = useParams();
  const userData = useSelector((state) => state.user);
  const [createProductModalOpened, setCreateProductModalOpened] = useState(false);
  const [products, setProducts] = useState([]);

  async function getProducts() {
    const [data] = await fetchProducts({ projectId });
    if (data) setProducts(data);
  }

  useEffect(() => {
    getProducts();
  }, []);

  const canCreateProduct = (products.length === 0 || products.find((product) => product.type !== 'evaluation'))
    && rbacCheck(userData.roles, '/products:POST');

  const CreateProductTooltip = () => {
    if (products.find((product) => product.type === 'evaluation')) return intl.formatMessage({ id: 'evaluationProductCreationDisabled' });
    return intl.formatMessage({ id: 'productCreationPermissionErr' });
  };

  const rows = products.map((product) => ({
    key: product._id,
    onRowClick: () => navigate(`/products/${product._id}`),
    data: [
      { field: 'name', value: product.name },
      { field: 'description', value: product.description },
      { field: 'type', value: product.type },
      { field: 'juiceGroup', value: product.details.juiceGroup },
      { field: 'approved', value: product.approved ? 'True' : 'False' },
      {
        field: 'tags',
        value: product.details.tags?.map((tag) => (
          <ScaleTag
            type="strong"
            style={{ marginRight: '0.3em' }}
            size="small"
            key={tag}
          >
            {tag}
          </ScaleTag>
        )) || null,
      },
    ],
  }));

  const productTableFields = [
    {
      title: 'Name',
      id: 'name',
    },
    {
      title: 'Description',
      id: 'description',
    },
    {
      title: 'Type',
      id: 'type',
    },
    {
      title: 'Juicegroup',
      id: 'juicegroup',
    },
    {
      title: 'Approved',
      id: 'approved',
    },
    {
      title: 'Tags',
      id: 'tags',
    },
  ];

  return (
    <>
      {createProductModalOpened && (
      <ScaleModal
        heading="Create a new product"
        size="small"
        opened={createProductModalOpened}
        onScale-close={() => setCreateProductModalOpened(false)}
      >
        <CreateProductForm
          formRef={formRef}
          onSubmit={() => {
            setCreateProductModalOpened(false);
            getProducts();
          }}
          onCancel={() => setCreateProductModalOpened(false)}
        />
      </ScaleModal>
      )}
      <Table
        header="Products"
        fields={productTableFields}
        rows={rows}
        createAction={{
          disabled: !canCreateProduct,
          onClick: () => setCreateProductModalOpened(true),
          tooltip: !canCreateProduct ? CreateProductTooltip() : '',
        }}
      />
    </>
  );
};

export default Products;
