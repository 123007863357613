/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import { Formik, Form, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { ScaleTabNav, ScaleButton, ScaleIconActionRestart } from '@telekom/scale-components-react';

import useApiCall from '../../hooks/useApiCall';
import Demands from '../../api/demands';
import { filterDataUpdates } from '../../helpers/objects';

import GeneralTab from './tabs/GeneralTab';
import TimelineTab from './tabs/TimelineTab';
import ResourcesTab from './tabs/ResourcesTab';
import PortfolioTab from './tabs/PortfolioTab';


function deleteNullFields(object) {
  return Object.fromEntries(Object.entries(object).filter((e) => e[1] !== null));
}


const defaultValues = {
  title: '',
  description: '',
  serviceReceivers: [],
  deliveryDate: '',
  deliveryNotes: '',
  fte: 0.0,
  fteNotes: '',
  important: false,
  importanceDetails: {
    department: '',
    notes: '',
  },
  contactPerson: '',
  technicalDomain: '',
  leadingTribe: '',
  involvedTribes: [],
  tribeInvolvementNotes: '',
};

const DemandInfo = ({ demand }) => (
  <Grid container>
    <Grid item xs={6}>
      <Typography component="span" variant="h4" color="primary">{demand.demandId}</Typography>
    </Grid>
    <Grid item xs={6}>
      <Grid
        container
        flexDirection="column"
        alignItems="flex-end"
      >
        <Grid item xs={12}>
          <Typography variant="subtitle2" component="span">Created by </Typography>
          <Link>
            <Typography component="span">{demand.ownerEmail}</Typography>
          </Link>
          <Typography component="span">{` (${new Date(demand.createdAt).toDateString()})`}</Typography>
        </Grid>
        {demand.updatedAt && (
          <Grid item xs={12}>
            <Typography variant="subtitle2" component="span">Last updated by </Typography>
            <Link>
              <Typography component="span">{demand.lastUpdatedByEmail}</Typography>
            </Link>
            <Typography component="span">{` (${new Date(demand.updatedAt).toDateString()})`}</Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  </Grid>
);

DemandInfo.propTypes = {
  demand: PropTypes.shape({
    demandId: PropTypes.string,
    ownerEmail: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    lastUpdatedByEmail: PropTypes,
  }).isRequired,
};

const ActionButtons = () => {
  const formik = useFormikContext();
  const { status } = formik.values;

  return (
    <Grid
      container
      justifyContent="flex-end"
      spacing={1}
    >
      <Grid item>
        <ScaleButton
          iconOnly
          type="button"
          size="small"
          disabled={!formik.dirty}
          onClick={formik.resetForm}
        >
          <ScaleIconActionRestart />
        </ScaleButton>
      </Grid>
      <Grid item>
        <ScaleButton
          size="small"
          type="submit"
          variant="primary"
          disabled={!formik.isValid || !formik.dirty}
        >
          Save
        </ScaleButton>
      </Grid>
      <Grid item>
        <ScaleButton
          size="small"
          type="button"
          variant="primary"
          disabled={formik.dirty || status === 'APPROVED'}
          onClick={() => {
            formik.setFieldValue('status', 'APPROVED');
            formik.submitForm();
          }}
        >
          Approve
        </ScaleButton>
      </Grid>
      <Grid item>
        <ScaleButton
          size="small"
          type="button"
          variant="primary"
          disabled={formik.dirty || status === 'REJECTED'}
          onClick={() => {
            formik.setFieldValue('status', 'REJECTED');
            formik.submitForm();
          }}
        >
          Reject
        </ScaleButton>
      </Grid>
    </Grid>
  );
};

const today = new Date().toISOString().split('T')[0];


const DemandEdit = () => {
  const { id: demandId } = useParams();
  const [demand, setDemand] = useState();
  const getDemandApiCall = useApiCall(Demands.getDemand);
  const updateDemandCall = useApiCall(Demands.updateDemand);


  const getDemand = async (id) => {
    const [data] = await getDemandApiCall(id);
    if (data) {
      const demandWithNoNulls = deleteNullFields(data);
      setDemand({ ...defaultValues, ...demandWithNoNulls });
    }
  };

  const updateDemand = async (id, updates) => {
    const [data] = await updateDemandCall(id, updates);
    if (data) {
      const demandWithNoNulls = deleteNullFields(data);
      setDemand({ ...defaultValues, ...demandWithNoNulls });
    }
  };

  useEffect(() => {
    getDemand(demandId);
  }, []);


  return demand && (
    <Container maxWidth="lg">
      <Grid container direction="column">
        <Grid item xs={12}>
          <DemandInfo demand={demand} />
        </Grid>
        <Grid item>
          <Formik
            enableReinitialize
            initialValues={demand}
            validationSchema={Yup.object().shape({
              title: Yup.string().required('Required'),
              description: Yup.string().required('Required'),
              serviceReceivers: Yup.array().required('Required').min(1, 'Select at least 1 service receiver'),
              deliveryDate: Yup.date().min(today, 'Date cannot be earlier than today').required('Required'),
              important: Yup.boolean(),
              importanceDetails: Yup.object().when('important', {
                is: true,
                then: () => Yup.object().shape({
                  department: Yup.string().required('Department is required'),
                  notes: Yup.string().required('Notes are required'),
                }),
                otherwise: (schema) => schema.notRequired(),
              }),
              contactPerson: Yup.string().email('Email format is required'),
            })}
            onSubmit={(values) => {
              const updates = filterDataUpdates(demand, values);
              const importantUpdateToFalse = Object.hasOwn(updates, 'important') && !updates.important;
              if (importantUpdateToFalse) {
                updates.importanceDetails = null;
              }
              updateDemand(demandId, updates);
            }}
          >
            {() => (
              <Form>
                <ActionButtons />
                <ScaleTabNav>
                  <GeneralTab />
                  <TimelineTab />
                  <ResourcesTab />
                  <PortfolioTab />
                </ScaleTabNav>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Container>
  );
};

export default DemandEdit;
