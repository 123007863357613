import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Container } from '@mui/material';

import useApiCall from '../../hooks/useApiCall';
import Demands from '../../api/demands';
import OverviewTable from '../../components/OverviewTable/OverviewTable';


function toTitleCase(str) {
  return str
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, (firstChar) => firstChar.toUpperCase());
}

const Demand = () => {
  const { id: demandId } = useParams();
  const [rows, setRows] = useState([]);
  const getDemandApiCall = useApiCall(Demands.getDemand);

  async function getDemand(id) {
    const [data] = await getDemandApiCall(id);

    if (data) {
      delete data.owner;
      const serviceReceivers = data.serviceReceivers.join(',');
      data.serviceReceivers = serviceReceivers;
      delete data.organizationId;
      if (data.important) {
        const { department, notes } = data.importanceDetails;
        data.importanceDepartment = department;
        data.importanceNotes = notes;
        data.important = 'True';
        delete data.importanceDetails;
      }

      const dataRows = Object.keys(data).map((key) => ({
        title: toTitleCase(key),
        value: data[key],
      }));
      setRows(dataRows);
    }
  }

  useEffect(() => {
    getDemand(demandId);
  }, []);

  return (
    <Container maxWidth="md">
      <OverviewTable rows={rows} />
    </Container>
  );
};


export default Demand;
