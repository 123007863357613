import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import {
  ScaleSwitch, ScaleTextField, ScaleButton, ScaleTag,
} from '@telekom/scale-components-react';
import {
  Formik,
  Form,
  useFormikContext,
  useField,
} from 'formik';

import * as Yup from 'yup';
import Provision from '../../api/provision';
import useApiCall from '../../hooks/useApiCall';


const GeoRedundancyField = ({ juiceGroupInfo, setJuiceGroupInfo, juiceGroup }) => {
  const setGeoRedundancy = useApiCall(Provision.setGeoRedundancy, null, null);

  const setGeoRedundancyCall = async () => {
    const [data] = await setGeoRedundancy({
      juiceGroup,
      geoRedundancy: !juiceGroupInfo.geoRedundancy,
    });
    if (data)setJuiceGroupInfo(data);
  };

  return (
    <div style={{ display: 'flex' }}>
      <Typography variant="body1" gutterBottom style={{ fontWeight: 'bold' }}>
        Geo Redundancy:
      </Typography>
      <ScaleSwitch
        color="primary"
        label="Activate"
        size="small"
        checked={juiceGroupInfo.geoRedundancy}
        onScale-change={() => { setGeoRedundancyCall(); }}
      />
    </div>
  );
};

GeoRedundancyField.propTypes = {
  juiceGroupInfo: PropTypes.shape({ geoRedundancy: PropTypes.bool }).isRequired,
  setJuiceGroupInfo: PropTypes.func.isRequired,
  juiceGroup: PropTypes.string.isRequired,
};

const ExternalServiceField = () => {
  const { setFieldTouched } = useFormikContext();
  const [field, meta] = useField({ name: 'externalService' });
  return (
    <>
      <Typography variant="body1" gutterBottom style={{ fontWeight: 'bold' }}>
        External Services:
      </Typography>
      <ScaleTextField
        {...field}
        label="Add external service"
        helperText={meta.touched ? meta.error : null}
        onScale-change={(e) => {
          field.onChange(e);
          setFieldTouched('externalService');
        }}
      />
    </>
  );
};

const ExistingServicesTagsField = ({ juiceGroupInfo, setJuiceGroupInfo, juiceGroup }) => {
  const removeExternalService = useApiCall(Provision.removeExternalService, null, null);
  const removeExternalServiceCall = async (externalService) => {
    const [data] = await removeExternalService({
      juiceGroup,
      externalService,
    });
    if (data)setJuiceGroupInfo(data);
  };

  return (
    <div style={{ display: 'block', marginBottom: '0.3rem' }}>
      { juiceGroupInfo.externalServices?.map((service) => (
        <ScaleTag
          key={service}
          dismissable
          type="strong"
          style={{ marginLeft: '0.3rem', marginTop: '0.3rem ', display: 'inline-block' }}
          onScale-close={() => {
            removeExternalServiceCall(service);
          }}
        >
          {service}
        </ScaleTag>
      ))}
    </div>
  );
};

ExistingServicesTagsField.propTypes = {
  juiceGroupInfo: PropTypes.shape({
    externalServices: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  setJuiceGroupInfo: PropTypes.func.isRequired,
  juiceGroup: PropTypes.string.isRequired,
};

const JuiceGroupInfoForm = ({ formRef, juiceGroupInfo, setJuiceGroupInfo }) => {
  const { juiceGroup } = useParams();
  const addExternalService = useApiCall(Provision.addExternalService, null, null);

  const ValidationSchema = Yup.object().shape({
    externalService: Yup.string()
      .notOneOf([juiceGroupInfo?.externalServices, null], 'External Service already exists'),
  });

  const addExternalServiceCall = async (externalService) => {
    const [data] = await addExternalService({ juiceGroup, externalService });
    if (data)setJuiceGroupInfo(data);
  };

  return (
    <>
      <GeoRedundancyField
        juiceGroupInfo={juiceGroupInfo}
        setJuiceGroupInfo={setJuiceGroupInfo}
        juiceGroup={juiceGroup}
      />
      <Formik
        enableReinitialize
        innerRef={formRef}
        initialErrors={{ externalService: '' }}
        initialValues={{ externalService: '' }}
        validationSchema={ValidationSchema}
        onSubmit={({ externalService }) => { addExternalServiceCall(externalService); }}
      >
        {({ dirty, isValid }) => (
          <Form align="right">
            <div style={{ display: 'flex' }}>
              <ExternalServiceField />
              <ScaleButton
                type="submit"
                disabled={!dirty || !isValid}
              >
                Add
              </ScaleButton>
            </div>
          </Form>
        )}
      </Formik>
      <ExistingServicesTagsField
        juiceGroupInfo={juiceGroupInfo}
        setJuiceGroupInfo={setJuiceGroupInfo}
        juiceGroup={juiceGroup}
      />
    </>
  );
};

JuiceGroupInfoForm.propTypes = {
  formRef: PropTypes.shape({}).isRequired,
  juiceGroupInfo: PropTypes.shape({
    geoRedundancy: PropTypes.bool,
    externalServices: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  setJuiceGroupInfo: PropTypes.func.isRequired,
};

export default JuiceGroupInfoForm;
