export const bytesToSize = (bytes) => {
  if (bytes > 1000000000) {
    return (`${(bytes / 1000000000).toFixed(2)} Gb/s`);
  } if (bytes > 1000000) {
    return (`${(bytes / 1000000).toFixed(2)} Mb/s`);
  } if (bytes > 1000) {
    return (`${(bytes / 1000).toFixed(2)} Kb/s`);
  }
  return (`${bytes} b/s`);
};

export const convertUnits = (value, unit) => {
  if (unit === 'bps') {
    return bytesToSize(value);
  } if (unit === 'decgbytes') {
    return (`${(value / 1000).toFixed(2)} TB`);
  } if (unit === 'dectbytes') {
    return `${value.toFixed(0)} TB`;
  } if (unit === 'percent') {
    return `${value.toFixed(2)} %`;
  }
  return value % 1 === 0 ? value : value.toFixed(2);
};

