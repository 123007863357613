import {
  FETCH_PRODUCT_MEMBERS_FULFILLED,
  UPDATE_PRODUCT_MEMBER_ROLE_FULFILLED,
} from './actions';

const productMembersReducer = (state = [], action) => {
  switch (action.type) {
    case FETCH_PRODUCT_MEMBERS_FULFILLED: {
      return action.payload;
    }
    case UPDATE_PRODUCT_MEMBER_ROLE_FULFILLED:
      if (!state.find((member) => member.id === action.payload.memberId)) {
        return [...state, action.payload.data];
      }
      return state.map((member) => {
        if (member.id === action.payload.memberId) {
          return action.payload.data;
        }
        return member;
      });
    default:
      return state;
  }
};

export { productMembersReducer };

