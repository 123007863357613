import { ofType } from 'redux-observable';
import {
  mergeMap, catchError,
} from 'rxjs/operators';
import { from, of } from 'rxjs';
import { handleError } from '../errorHandler';
import Auth from '../../api/auth';
import {
  FETCH_USERS_STARTED,
  fetchUsersFulfilled,
} from './actions';

const fetchUsersEpic = (action$) => action$.pipe(
  ofType(FETCH_USERS_STARTED),
  mergeMap((action) => from(Auth.fetchV2Users(action.payload)).pipe(
    mergeMap((response) => of(
      fetchUsersFulfilled(response.data),
    )),
    catchError(handleError),
  )),
);

export default fetchUsersEpic;
