import { Container } from '@mui/material';
import useMarkdownFile from '../../../hooks/useMarkdownFile';
import safecsarfSpecification from '../../../markdowns/Safescarf/specification.md';

const SafescarfSpecification = () => {
  const { markdownContent } = useMarkdownFile(safecsarfSpecification);

  return (
    <Container maxWidth="lg">
      {markdownContent}
    </Container>
  );
};

export default SafescarfSpecification;
