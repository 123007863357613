import { useState } from 'react';
import { Grid } from '@mui/material';
import { ScaleSidebarNav, ScaleSidebarNavItem } from '@telekom/scale-components-react';
import {
  Link, useLocation, Outlet,
} from 'react-router-dom';

const Account = () => {
  const [virtualClouds, setVirtualClouds] = useState(undefined);

  const { pathname } = useLocation();

  const menuItems = [
    { title: 'Overview' },
    { title: 'Security', path: 'security' },
  ];

  return (
    <Grid
      container
      justifyContent="space-between"
    >
      <Grid item xs={2}>
        <ScaleSidebarNav>
          {menuItems.map((item) => (
            <ScaleSidebarNavItem
              key={item.title}
              active={pathname.split('/')[2] === item.path}
            >
              <Link to={item.path || ''}>{item.title}</Link>
            </ScaleSidebarNavItem>
          ))}
        </ScaleSidebarNav>
      </Grid>
      <Grid item xs={9}>
        <Outlet context={[virtualClouds, setVirtualClouds]} />
      </Grid>
    </Grid>
  );
};

export default Account;
