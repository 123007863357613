import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { ScaleTextField } from '@telekom/scale-components-react';
import { useCallback } from 'react';
import debounce from 'lodash.debounce';
import ActionButtons from './ActionButtons';

const createDebouncedFilter = (setFilter, setCurrentPage) => debounce((value) => {
  setFilter(value);
  setCurrentPage(1);
}, 300);

const Header = ({
  header,
  setFilter,
  setCurrentPage,
  createAction,
  exportData,
}) => {
  const debouncedSetFilter = useCallback(
    createDebouncedFilter(setFilter, setCurrentPage),
    [setFilter, setCurrentPage],
  );

  return (
    <Grid
      container
      justifyContent="space-between"
      pb={2}
    >
      <Grid item>
        <Typography variant="h4" color="primary">
          {header}
        </Typography>
      </Grid>
      <Grid item>
        <Grid
          container
          alignItems="center"
          gap={1}
        >
          <Grid item>
            <ScaleTextField
              label="Search"
              type="search"
              onScale-change={(e) => debouncedSetFilter(e.detail.value)}
            />
          </Grid>
          <Grid item>
            <ActionButtons
              createAction={createAction}
              exportData={exportData}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

Header.propTypes = {
  header: PropTypes.string.isRequired,
  setFilter: PropTypes.func.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  createAction: PropTypes.shape({
    tooltip: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
  }),
  exportData: PropTypes.shape({
    file: PropTypes.instanceOf(Blob),
    fileName: PropTypes.string,
    name: PropTypes.string,
  }),
};

Header.defaultProps = {
  createAction: null,
  exportData: null,
};

export default Header;
