import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ScaleSidebarNav, ScaleSidebarNavItem, ScaleSidebarNavCollapsible } from '@telekom/scale-components-react';
import PropTypes from 'prop-types';

import useApiCall from '../../hooks/useApiCall';
import DocumentationMS from '../../api/documentation';
import PageContent from './PageContent';

const SideNavigationItem = ({ page, activePageTitle, onClick }) => {
  const handlePageChange = (e, title) => {
    e.stopPropagation();
    onClick(`/docs/${title}`);
  };

  if (page.children?.length) {
    return (
      <ScaleSidebarNavCollapsible
        key={page.title}
        label={page.title}
        active={page.title === activePageTitle}
        condensed
        onClick={(e) => handlePageChange(e, page.title)}
      >
        {
          page.children.map((subPage) => (
            <SideNavigationItem
              key={subPage.id}
              page={subPage}
              activePageTitle={activePageTitle}
              onClick={onClick}
            />
          ))
        }
      </ScaleSidebarNavCollapsible>
    );
  }

  return (
    <ScaleSidebarNavItem
      key={page.title}
      active={page.title === activePageTitle}
    >
      <Link
        to={`/docs/${page.title}`}
        onClick={(e) => handlePageChange(e, page.title)}
      >
        {page.title}
      </Link>
    </ScaleSidebarNavItem>
  );
};

SideNavigationItem.propTypes = {
  page: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    children: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  activePageTitle: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

const Documentation = () => {
  const navigate = useNavigate();
  const { pageTitle = import.meta.env.VITE_CONFLUENCE_HOMEPAGE } = useParams();
  const getChildrenPages = useApiCall(DocumentationMS.getChildrenPages);
  const [pages, setPages] = useState([]);

  async function getAllPages() {
    const [data] = await getChildrenPages(import.meta.env.VITE_CONFLUENCE_HOMEPAGE_ID);
    if (data) setPages(data);
  }

  useEffect(() => {
    getAllPages();
  }, []);

  return (
    <Grid container>
      <Grid item xs={2}>
        <ScaleSidebarNav>
          <ScaleSidebarNavItem
            key={pageTitle}
            active={pageTitle === import.meta.env.VITE_CONFLUENCE_HOMEPAGE}
          >
            <Link to={`/docs/${import.meta.env.VITE_CONFLUENCE_HOMEPAGE}`}>
              {import.meta.env.VITE_CONFLUENCE_HOMEPAGE}
            </Link>
          </ScaleSidebarNavItem>
          {
            pages.map((subpage) => (
              <SideNavigationItem
                key={subpage.id}
                page={subpage}
                activePageTitle={pageTitle}
                onClick={navigate}
              />
            ))
          }
        </ScaleSidebarNav>
      </Grid>
      <Grid item xs={10}>
        <PageContent pageTitle={pageTitle} />
      </Grid>
    </Grid>
  );
};

export default Documentation;
