import { useNavigate } from 'react-router-dom';
import { Typography, Grid } from '@mui/material';
import { ScaleButton } from '@telekom/scale-components-react';

const TokenExpired = () => {
  const navigate = useNavigate();

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
    >
      <Grid item xs={12}>
        <Typography variant="h2" gutterBottom>
          Something Went Wrong!
        </Typography>
        <br />
        <Typography gutterBottom>
          Your link has reached expiration time, sadly. Please restart the operation.
          <br />
          {'If you can\'t do this properly please contact our support team.'}
        </Typography>
        <br />
        <ScaleButton variant="primary" onClick={() => navigate('/')}>
          Back to homepage
        </ScaleButton>
      </Grid>
    </Grid>
  );
};

export default TokenExpired;
