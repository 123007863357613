import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { useIntl } from 'react-intl';

const InstanceStatus = ({ progress }) => {
  const intl = useIntl();

  return (
    <>
      <Typography
        variant="h4"
        color="primary"
        gutterBottom
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        {intl.formatMessage({ id: 'safescarfStatusLabel' })}
      </Typography>
      <Typography variant="h3" style={{ display: 'flex', justifyContent: 'center' }}>
        { progress * 100 }
        %
      </Typography>
    </>
  );
};

InstanceStatus.propTypes = { progress: PropTypes.number };
InstanceStatus.defaultProps = { progress: 0.0 };

export default InstanceStatus;
