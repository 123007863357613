import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { ScaleModal } from '@telekom/scale-components-react';
import {
  Breadcrumbs,
  Container,
  Grid,
  Typography,
} from '@mui/material';

import rbacCheck from '../../../../../rbac/rbacCheck';
import OverviewTable from '../../../../../components/OverviewTable/OverviewTable';
import EditProductTypeMemberForm from './EditProductTypeMemberForm';

import { fetchUsers as fetchSafescarfUsers } from '../../../../../redux/safescarf/users/actions';
import { fetchMachineAccounts } from '../../../../../redux/safescarf/machineAccounts/actions';
import { fetchRoles } from '../../../../../redux/safescarf/roles/actions';
import { fetchProductTypes } from '../../../../../redux/safescarf/productTypes/actions';
import { fetchProductTypeMembers } from '../../../../../redux/safescarf/productTypeMembers/actions';


function getMember(user, productTypeMembers, isMachineAccount, productTypeId) {
  const productTypeMember = productTypeMembers.find((m) => m.product_type === Number(productTypeId)
  && m.user === user.id);

  return {
    userId: user.id,
    username: user.username,
    createdBy: user.email,
    globalRole: user.globalRole,
    productTypeMemberId: productTypeMember?.id,
    productTypeMemberRole: productTypeMember?.role,
    isMachineAccount,
  };
}

const ProductTypeMemberDetails = () => {
  const { productTypeId, safescarfUserId } = useParams();
  const dispatch = useDispatch();
  const [editModalOpened, setEditModalOpened] = useState(false);
  const [member, setMember] = useState();
  const [currentUser, setCurrentUser] = useState();
  const userData = useSelector((state) => state.user);
  const roles = useSelector((state) => state.safescarf.roles);
  const productTypes = useSelector((state) => state.safescarf.productTypes);
  const productTypeMembers = useSelector((state) => state.safescarf.productTypeMembers);
  const safescarfUsers = useSelector((state) => state.safescarf.users);
  const machineAccounts = useSelector((state) => state.safescarf.machineAccounts);

  const machineAccount = machineAccounts.find((ma) => ma.id === Number(safescarfUserId));
  const productType = productTypes.find((pt) => pt.id === Number(productTypeId));

  useEffect(() => {
    if (!safescarfUsers.length) dispatch(fetchSafescarfUsers());
    if (!productTypes.length) dispatch(fetchProductTypes());
    if (!machineAccounts.length) dispatch(fetchMachineAccounts());
    if (!roles.length) dispatch(fetchRoles());
    if (!productTypeMembers.length) dispatch(fetchProductTypeMembers());
  }, []);

  useEffect(() => {
    if (userData.email && safescarfUsers.length && productTypeMembers.length) {
      const user = safescarfUsers.find((u) => u.username === userData.email);
      setCurrentUser(getMember(user, productTypeMembers, false, productTypeId));
    }
  }, [userData, safescarfUsers, productTypeMembers]);

  useEffect(() => {
    const productTypeMember = safescarfUsers.find((user) => user.id === Number(safescarfUserId));
    if (machineAccount && productTypeMembers.length) {
      setMember(getMember(machineAccount, productTypeMembers, true, productTypeId));
    } else if (productTypeMember && productTypeMembers.length) {
      setMember(getMember(productTypeMember, productTypeMembers, false, productTypeId));
    }
  }, [machineAccount, productTypeMembers, safescarfUsers]);

  const canEditProductTypeMembers = rbacCheck(
    userData.roles,
    '/products/safescarf/product-types:PUT_MEMBERS',
    currentUser,
  );

  const roleLookup = roles.reduce((acc, role) => {
    acc[role.id] = role.name;
    return acc;
  }, {});


  return (
    <>
      {editModalOpened && (
        <ScaleModal
          opened
          size="small"
          heading="Edit Product Type"
          onScale-close={() => setEditModalOpened(false)}
        >
          <EditProductTypeMemberForm
            productTypeMember={member}
            onCancel={() => setEditModalOpened(false)}
            onSubmit={() => setEditModalOpened(false)}
          />
        </ScaleModal>
      )}
      <Grid
        className="content-with-header"
        container
        direction="column"
        rowGap={4}
      >
        <Grid item alignSelf="flex-start">
          <Breadcrumbs key={productType?.name} separator=">">
            <Link to="/products/safescarf">Safescarf</Link>
            <Link to="/products/safescarf/product-types">product types</Link>
            <Link to={`/products/safescarf/product-types/${productType?.id}`}>{productType?.name}</Link>
            <Link to={`/products/safescarf/product-types/${productType?.id}`}>members</Link>
            <Typography>{member?.username}</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Container maxWidth="lg">
            <OverviewTable
              header={member?.username}
              rows={[
                { title: 'Product type:', value: productType?.name },
                { title: 'Machine account:', value: member?.isMachineAccount ? 'Yes' : 'No' },
                { title: 'Created by:', value: member?.createdBy },
                { title: 'Role:', value: roleLookup[member?.productTypeMemberRole] || 'Not assigned' },
                member?.globalRole && { title: 'Global role:', value: roleLookup[member?.globalRole] },
              ].filter((r) => r)}
              editAction={{
                onClick: () => setEditModalOpened(true),
                disabled: !canEditProductTypeMembers,
                tooltip: 'editProductTypeMemberDisabled',
              }}
            />
          </Container>
        </Grid>
      </Grid>
    </>
  );
};


export default ProductTypeMemberDetails;
