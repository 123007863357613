import { useLocation } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Page404 from '../../pages/Error/Page404';

const Authenticator = ({ children }) => {
  const { keycloak, initialized } = useKeycloak();
  const reduxError = useSelector((state) => state.status.error);
  const { pathname } = useLocation();

  const protectedPaths = [
    '/account',
    '/organizations',
    '/organization',
    '/reports',
    '/demand-management',
    '/monitoring-dashboard',
    '/projects',
    '/products',
    '/users',
    '/business-units',
  ];

  const publicPaths = [
    '/products/compute/specification',
    '/products/safescarf/specification',
    '/products/caas-on-openstack/specification',
  ];

  const authenticatedPath = protectedPaths.find(
    (path) => pathname.startsWith(path) && !publicPaths.includes(pathname),
  );

  if (authenticatedPath) {
    if (reduxError === 'KEYCLOAK_INIT_ERROR') return <Page404 />;
    if (!initialized) return null;
    if (!keycloak.authenticated) {
      keycloak.login({ redirectUri: `${window.location.origin}/${pathname}` });
    }
  }

  return children;
};

Authenticator.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Authenticator;
