import PropTypes from 'prop-types';
import { ScaleTable } from '@telekom/scale-components-react';

function ramToGigabytes(ram) {
  return Math.floor(ram / 1024);
}

const CloudTable = ({ virtualCloud }) => (
  <ScaleTable>
    <table>
      <thead>
        <tr>
          <th>Quota</th>
          <th>vCPU</th>
          <th>RAM (GB)</th>
          <th>Volumes (GB)</th>
          <th>Object Storage (GB)</th>
          <th>Floating IPs</th>
        </tr>
      </thead>
      <tbody>
        <tr key="allowed-quota">
          <td>Current allowed</td>
          <td>{virtualCloud.allowed_quota.compute.cores}</td>
          <td>{ramToGigabytes(virtualCloud.allowed_quota.compute.ram)}</td>
          <td>{virtualCloud.allowed_quota.volume.gigabytes}</td>
          <td>{virtualCloud.allowed_quota.volume.objects_megabytes}</td>
          <td>{virtualCloud.allowed_quota.network.floating_ips}</td>
        </tr>
        <tr key="allocated-quota">
          <td>Current allocated</td>
          <td>{virtualCloud.used_quota.compute.cores}</td>
          <td>{ramToGigabytes(virtualCloud.used_quota.compute.ram)}</td>
          <td>{virtualCloud.used_quota.volume.gigabytes}</td>
          <td>{virtualCloud.used_quota.volume.objects_megabytes}</td>
          <td>{virtualCloud.used_quota.network.floating_ips}</td>
        </tr>
      </tbody>
    </table>
  </ScaleTable>
);

const VirtualCloudsSummary = ({ virtualCloud }) => {
  if (!virtualCloud) return <p>No Virtual clouds were found</p>;
  return <CloudTable virtualCloud={virtualCloud} />;
};

CloudTable.propTypes = {
  virtualCloud: PropTypes.objectOf(PropTypes.oneOfType(
    [PropTypes.object, PropTypes.number, PropTypes.array],
  )).isRequired,
};
VirtualCloudsSummary.propTypes = {
  virtualCloud: PropTypes.objectOf(PropTypes.oneOfType(
    [PropTypes.object, PropTypes.number, PropTypes.array],
  )).isRequired,
};

export default VirtualCloudsSummary;
