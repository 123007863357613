export const FETCH_PRODUCT_TYPE_MEMBERS_STARTED = 'FETCH_PRODUCT_TYPE_MEMBERS_STARTED';
export const FETCH_PRODUCT_TYPE_MEMBERS_FULFILLED = 'FETCH_PRODUCT_TYPE_MEMBERS_FULFILLED';
export const UPDATE_PRODUCT_TYPE_MEMBER_ROLE_STARTED = 'UPDATE_PRODUCT_TYPE_MEMBER_ROLE_STARTED';
export const UPDATE_PRODUCT_TYPE_MEMBER_ROLE_FULFILLED = 'UPDATE_PRODUCT_TYPE_MEMBER_ROLE_FULFILLED';

export const fetchProductTypeMembers = (productTypeId) => (
  { type: FETCH_PRODUCT_TYPE_MEMBERS_STARTED, payload: productTypeId }
);

export const fetchProductTypeMembersFulfilled = (data) => (
  { type: FETCH_PRODUCT_TYPE_MEMBERS_FULFILLED, payload: data }
);

export const updateProductTypeMemberRole = (data) => (
  { type: UPDATE_PRODUCT_TYPE_MEMBER_ROLE_STARTED, payload: data }
);

export const updateProductTypeMemberRoleFulfilled = (data) => (
  { type: UPDATE_PRODUCT_TYPE_MEMBER_ROLE_FULFILLED, payload: data }
);
