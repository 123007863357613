export const FETCH_PRODUCT_MEMBERS_STARTED = 'FETCH_PRODUCT_MEMBERS_STARTED';
export const FETCH_PRODUCT_MEMBERS_FULFILLED = 'FETCH_PRODUCT_MEMBERS_FULFILLED';
export const UPDATE_PRODUCT_MEMBER_ROLE_STARTED = 'UPDATE_PRODUCT_MEMBER_ROLE_STARTED';
export const UPDATE_PRODUCT_MEMBER_ROLE_FULFILLED = 'UPDATE_PRODUCT_MEMBER_ROLE_FULFILLED';

export const fetchProductMembers = (productId) => (
  { type: FETCH_PRODUCT_MEMBERS_STARTED, payload: productId }
);

export const fetchProductMembersFulfilled = (data) => (
  { type: FETCH_PRODUCT_MEMBERS_FULFILLED, payload: data }
);

export const updateProductMemberRole = (data) => (
  { type: UPDATE_PRODUCT_MEMBER_ROLE_STARTED, payload: data }
);

export const updateProductMemberRoleFulfilled = (data) => (
  { type: UPDATE_PRODUCT_MEMBER_ROLE_FULFILLED, payload: data }
);
