import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {
  Formik,
  Form,
  useFormikContext,
  useField,
} from 'formik';
import {
  ScaleButton,
  ScaleTextField,
  ScaleDropdownSelect,
  ScaleDropdownSelectItem,
} from '@telekom/scale-components-react';

import { updateProduct } from '../../../../../redux/safescarf/products/actions';
import { fetchProductTypes } from '../../../../../redux/safescarf/productTypes/actions';


const NameField = () => {
  const { setFieldTouched } = useFormikContext();
  const [field, meta] = useField({ name: 'name' });
  return (
    <div className="form-field">
      <ScaleTextField
        {...field}
        label="Name"
        helperText={meta.touched ? meta.error : null}
        onScale-change={(e) => {
          field.onChange(e);
          setFieldTouched('name');
        }}
      />
    </div>
  );
};

const DescriptionField = () => {
  const { setFieldTouched } = useFormikContext();
  const [field, meta] = useField({ name: 'description' });
  return (
    <div className="form-field">
      <ScaleTextField
        {...field}
        label="Description"
        helperText={meta.touched ? meta.error : null}
        onScale-change={(e) => {
          field.onChange(e);
          setFieldTouched('description');
        }}
      />
    </div>
  );
};

const ProductTypesField = ({ productTypes }) => {
  const { setFieldTouched } = useFormikContext();
  const [field] = useField({ name: 'prod_type' });

  return (
    <div className="form-field">
      <ScaleDropdownSelect
        {...field}
        floatingStrategy="fixed"
        label="Product Type"
        onScale-change={(e) => {
          field.onChange(e);
          setFieldTouched('prod_type');
        }}
      >
        {productTypes.map(
          (pt) => (
            <ScaleDropdownSelectItem key={pt.id} value={pt.id}>
              {pt.name}
            </ScaleDropdownSelectItem>
          ),
        )}
      </ScaleDropdownSelect>
    </div>
  );
};

ProductTypesField.propTypes = {
  productTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
};


const EditProductForm = ({ product, onSubmit, onCancel }) => {
  const dispatch = useDispatch();
  const { productId } = useParams();
  const productTypes = useSelector((state) => state.safescarf.productTypes);

  useEffect(() => {
    if (!productTypes.length) dispatch(fetchProductTypes());
  }, []);

  const initialValues = {
    name: product.name,
    description: product.description,
    prod_type: product.prod_type.toString(),
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    description: Yup.string().required('Required'),
    prod_type: Yup.string().required('Required'),
  });

  const submit = async (formValues) => {
    dispatch(updateProduct({ ...formValues, id: productId }));
    onSubmit();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={submit}
    >
      {({ dirty, isValid }) => (
        <Form>
          <NameField />
          <DescriptionField />
          {productTypes.length ? <ProductTypesField productTypes={productTypes} /> : null}
          <div className="form-buttons">
            <ScaleButton
              variant="primary"
              disabled={!dirty || !isValid}
            >
              Save
            </ScaleButton>
            <ScaleButton
              type="button"
              variant="secondary"
              style={{ marginLeft: '1em' }}
              onClick={onCancel}
            >
              Cancel
            </ScaleButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

EditProductForm.propTypes = {
  product: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    prod_type: PropTypes.number,
  }),
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

EditProductForm.defaultProps = {
  product: {},
};


export default EditProductForm;
