import { useRef } from 'react';
import {
  Formik,
  Form,
} from 'formik';
import { Grid } from '@mui/material';
import { ScaleButton } from '@telekom/scale-components-react';
import validator from 'validator';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';

import Auth from '../../../api/auth';
import useApiCall from '../../../hooks/useApiCall';
import { FormikPasswordField } from '../../../components/Formik/index';


const UserPasswordChange = () => {
  const intl = useIntl();
  const formRef = useRef(null);
  const updatePassword = useApiCall(Auth.updateUserPassword, 'userPasswordChanged');

  const initialValues = {
    currentPassword: '',
    newPassword: '',
    newPasswordConfirm: '',
  };

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string()
      .required('Required'),
    newPassword: Yup.string()
      .test(
        'Strong password',
        intl.formatMessage({ id: 'ERR_PASSWORD_INVALID' }),
        (value) => (value ? validator.isStrongPassword(value, { minLength: 10 }) : true),
      ).required('Required'),
    newPasswordConfirm: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], intl.formatMessage({ id: 'userPasswordMismatch' }))
      .required('Required'),
  });

  const changeUserPassword = async (formData) => {
    const newPasswordData = {
      password: formData.currentPassword,
      newPassword: formData.newPassword,
    };
    const [data] = await updatePassword(newPasswordData);
    if (data !== null) formRef.current.resetForm();
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={changeUserPassword}
    >
      {({
        dirty,
        isValid,
        isSubmitting,
      }) => (
        <Form>
          <Grid
            container
            direction="column"
            alignItems="flex-end"
            rowGap={2}
            width="20rem"
            paddingTop="3rem"
          >
            <FormikPasswordField
              name="currentPassword"
              label="Current password"
            />
            <FormikPasswordField
              name="newPassword"
              label="New password"
            />
            <FormikPasswordField
              name="newPasswordConfirm"
              label="Confirm new password"
            />
            <ScaleButton
              disabled={!dirty || (!isValid || isSubmitting)}
              type="submit"
              size="small"
            >
              Submit
            </ScaleButton>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default UserPasswordChange;
