import { useMemo, useState } from 'react';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { HashLink } from 'react-router-hash-link';
import useAppContext from '../../hooks/useAppContext';
import cookieBanner from '../../markdowns/cookieBanner.md';
import { scrollWithOffset } from '../../helpers/scrollWithOffset';
import useMarkdownFile from '../../hooks/useMarkdownFile';

const CookieBanner = () => {
  const intl = useIntl();
  const { pathname } = useLocation();
  const { cookiesAccepted, setCookiesAccepted } = useAppContext();
  const { markdownContent } = useMarkdownFile(cookieBanner);
  const [confirmChecked, setConfirmChecked] = useState(false);

  const excludedPaths = useMemo(() => ['/data-privacy', '/about-us'], []);
  const showCookieBanner = useMemo(
    () => !cookiesAccepted && !excludedPaths.includes(pathname) && markdownContent,
    [cookiesAccepted, pathname, markdownContent],
  );

  const handleCheckboxChange = (e) => {
    const { checked } = e.target;
    setConfirmChecked(checked);
    if (checked) {
      setCookiesAccepted(true);
    }
  };

  const renderLinks = useMemo(() => (
    <Grid
      container
      justifyContent="space-between"
      gap={2}
    >
      <Grid item>
        <Link to="/data-privacy">
          <Typography>Privacy Policy</Typography>
        </Link>
      </Grid>
      <Grid item>
        <Link to="/about-us">
          <Typography>Imprint</Typography>
        </Link>
      </Grid>
      <Grid item>
        <HashLink
          to="/data-privacy#partner-list"
          scroll={scrollWithOffset}
        >
          <Typography>Partner List (2)</Typography>
        </HashLink>
      </Grid>
    </Grid>
  ), []);

  return (
    showCookieBanner && (
      <Dialog
        open
        sx={{
          '& .MuiDialog-paper': {
            maxHeight: '60vh',
          },
        }}
      >
        <DialogTitle color="primary">
          {intl.formatMessage({ id: 'CookieBannerTitle' })}
        </DialogTitle>
        <DialogContent>
          {markdownContent}
          <Checkbox
            checked={confirmChecked}
            onChange={handleCheckboxChange}
          />
          <Typography component="span">
            {intl.formatMessage({ id: 'CookieBannerCheckboxMessage1' })}
            <Link to="/data-privacy">
              <Typography component="span" display="inline">
                Privacy Policy
              </Typography>
            </Link>
            {intl.formatMessage({ id: 'CookieBannerCheckboxMessage2' })}
          </Typography>
        </DialogContent>
        <DialogActions sx={{ mb: 2, mx: 2 }}>
          {renderLinks}
        </DialogActions>
      </Dialog>
    )
  );
};

export default CookieBanner;
