import { connect } from 'react-redux';
import { Fragment } from 'react';
import { IntlProvider } from 'react-intl';

import * as translations from './translations';

const mapStateToProps = (state) => {
  const { locale } = state.user;
  return {
    locale,
    key: locale,
    defaultLocale: 'en',
    messages: translations[locale],
    textComponent: Fragment,
  };
};

export default connect(mapStateToProps)(IntlProvider);
