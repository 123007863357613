import { Grid } from '@mui/material';
import {
  ScaleButton,
  ScaleIconActionAdd,
  ScaleTooltip,
} from '@telekom/scale-components-react';
import PropTypes from 'prop-types';

const ActionButtons = ({ createAction, exportData }) => (
  <>
    {createAction && (
      <Grid item>
        <ScaleTooltip content={createAction.tooltip} disabled={!createAction.tooltip}>
          <ScaleButton title="Create" onClick={createAction.onClick} disabled={createAction.disabled}>
            <ScaleIconActionAdd />
            Create
          </ScaleButton>
        </ScaleTooltip>
      </Grid>
    )}
    {exportData && (
      <Grid item>
        <ScaleButton
          href={URL.createObjectURL(exportData.file)}
          download={exportData.fileName}
          style={{ marginLeft: '0.5rem' }}
        >
          {exportData.name}
        </ScaleButton>
      </Grid>
    )}
  </>
);

ActionButtons.propTypes = {
  createAction: PropTypes.shape({
    tooltip: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
  }),
  exportData: PropTypes.shape({
    file: PropTypes.instanceOf(Blob),
    fileName: PropTypes.string,
    name: PropTypes.string,
  }),
};

ActionButtons.defaultProps = {
  createAction: null,
  exportData: null,
};

export default ActionButtons;
