import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { ScaleTag, ScaleModal } from '@telekom/scale-components-react';
import PropTypes from 'prop-types';

import Provision from '../../api/provision';
import OverviewTable from '../../components/OverviewTable/OverviewTable';
import JuiceGroupInfoForm from './JuiceGroupInfoForm';
import useApiCall from '../../hooks/useApiCall';

const JuiceGroupInfo = ({ tenant }) => {
  const { cloudId, juiceGroup } = useParams();
  const formRef = useRef(null);
  const fetchJuiceGroupInfo = useApiCall(Provision.fetchJuiceGroupInfo);
  const [juiceGroupInfo, setJuiceGroupInfo] = useState({});
  const [editModalOpened, setEditModalOpened] = useState(false);


  useEffect(() => {
    async function fetchJuiceGroupInfoData() {
      const [data] = await fetchJuiceGroupInfo({ juiceGroup });
      if (data) {
        setJuiceGroupInfo(data);
      }
    }
    fetchJuiceGroupInfoData();
  }, [juiceGroup]);

  function getJuiceGroupInfo() {
    return [
      { title: 'Cloud Id', value: cloudId },
      { title: 'Juice Group', value: juiceGroup },
      {
        title: 'Geo-Redundancy',
        value: juiceGroupInfo.geoRedundancy ? 'Active' : 'Inactive',
      },
      {
        title: 'External Services',
        component: (juiceGroupInfo.externalServices?.length && (
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            {juiceGroupInfo.externalServices?.map((service) => (
              <ScaleTag key={service} type="strong" style={{ marginLeft: '0.5rem' }}>
                {service}
              </ScaleTag>
            ))}
          </div>
        )),
      },
    ];
  }

  return (
    <>
      <ScaleModal
        opened={editModalOpened}
        size="small"
        heading="Edit Juice Group Info"
        onScale-before-close={() => {
          setEditModalOpened(false);
          formRef.current.resetForm();
        }}
      >
        <JuiceGroupInfoForm
          formRef={formRef}
          juiceGroupInfo={juiceGroupInfo}
          setJuiceGroupInfo={setJuiceGroupInfo}
        />
      </ScaleModal>
      <OverviewTable
        header={tenant}
        rows={getJuiceGroupInfo()}
        editAction={{
          onClick: () => setEditModalOpened(true),
          disabled: false,
          tooltip: 'EditJuiceGroupInfo',
        }}
      />
    </>
  );
};

JuiceGroupInfo.propTypes = {
  tenant: PropTypes.string.isRequired,
};

export default JuiceGroupInfo;
