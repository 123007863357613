import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { BarChart } from '@mui/x-charts';

import MonitoringMs from '../../../../api/monitoring';
import useApiCall from '../../../../hooks/useApiCall';

const BarChartComponent = ({ panelInfo, dateRange }) => {
  const { dashboardId } = useParams();
  const [values, setValues] = useState({ xAxis: [], series: [] });
  const getPanelDataApiCall = useApiCall(MonitoringMs.getPanel);
  const getPanel = async (targets) => {
    const [data] = await getPanelDataApiCall(targets, dashboardId);
    if (data) {
      const refIds = panelInfo.targets.filter((target) => !target.hide)
        .map((target) => target.refId);
      const panelData = refIds.map((refId) => data.results[refId].frames[0]);

      const xAxisIndex = panelData[0].schema.fields.findIndex((field) => field.name === 'coop_model');
      const xAxis = panelData[0].data.values[xAxisIndex >= 0 ? xAxisIndex : 0];


      const seriesIndex = panelData[0].schema.fields.findIndex((field) => field.type === 'number');
      const series = panelData.map((frame) => (
        {
          data: frame.data.values[seriesIndex >= 0 ? seriesIndex : 1],
          stack: 'A',
          label: frame.schema.fields[seriesIndex >= 0 ? seriesIndex : 1].name,
          scaleType: 'band',
        }
      ));

      setValues({
        xAxis: [{ data: xAxis, scaleType: 'band' }],
        series,
      });
    }
  };

  useEffect(() => {
    const toDate = new Date(dateRange.to).getTime();
    const fromDate = new Date(dateRange.from).getTime();
    if (panelInfo.targets) {
      getPanel({ queries: panelInfo.targets, to: toDate.toString(), from: fromDate.toString() });
    }
  }, []);

  return (
    <BarChart
      series={values.series}
      colors={['#E20074', '#f399c7']}
      xAxis={values.xAxis}
      grid={{ horizontal: true }}
      slotProps={{ legend: { hidden: true } }}
      sx={{
        maxHeight: '100%',
        marginBottom: '2rem',
      }}
    />
  );
};

BarChartComponent.propTypes = {
  panelInfo: PropTypes.shape({
    title: PropTypes.string,
    targets: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    gridPos: PropTypes.shape({
      h: PropTypes.number,
      w: PropTypes.number,
      x: PropTypes.number,
      y: PropTypes.number,
    }),
  }).isRequired,
  dateRange: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
  }).isRequired,
};


export default BarChartComponent;
