import { useContext } from 'react';
import { ScaleDropdownSelect, ScaleDropdownSelectItem } from '@telekom/scale-components-react';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Provision from '../../api/provision';
import { ReportsContext } from './ReportsContext';
import useApiCall from '../../hooks/useApiCall';
import Table from '../../components/Table';


const hypervisorsFields = [
  { id: 'hostName', title: 'Host Name' },
  { id: 'availabilityZone', title: 'Availability Zone' },
  { id: 'computeRole', title: 'Compute Role' },
  { id: 'cpuRatio', title: 'CPU Ratio' },
  { id: 'ramRatio', title: 'RAM Ratio' },
  { id: 'diskRatio', title: 'Disk Ratio' },
];

const hypervisorsDetailsFields = [
  { id: 'vmName', title: 'Virtual Machine Name' },
  { id: 'status', title: 'Status' },
  { id: 'cpu', title: 'CPU' },
  { id: 'ram', title: 'RAM (GB)' },
  { id: 'disk', title: 'Disk (GB)' },
  { id: 'flavorName', title: 'Flavor Name' },
  { id: 'osProjectName', title: 'OS Project Name' },
  { id: 'osDomainName', title: 'OS Domain Name [Juice Group]' },
  { id: 'createdAt', title: 'Created At' },
  { id: 'deletedAt', title: 'Deleted At' },
];

const HypervisorReport = () => {
  const navigate = useNavigate();
  const fetchInstanceDetails = useApiCall(Provision.fetchInstanceDetails);
  const fetchHostsCall = useApiCall(Provision.fetchHosts);
  const {
    hypervisor: {
      cloudId,
      setCloudId,
      hypervisors,
      setHypervisors,
      hypervisorDetails,
      setHypervisorDetails,
      clouds,
    },
  } = useContext(ReportsContext);

  async function getHypervisorDetails(hostName, selectedCloudId) {
    const [data] = await fetchInstanceDetails({ host_name: hostName, cloudIds: [selectedCloudId] });
    if (data) {
      const cloudData = data[0].instances.map((instance) => ({
        key: instance.name,
        onRowClick: () => navigate(
          `${cloudId}/${data[0].metadata.domains[instance.domain_id].name}/${instance.name}`,
        ),
        data: [
          { field: 'vmName', value: instance.name },
          { field: 'status', value: instance.status },
          { field: 'cpu', value: instance.cpus_total },
          { field: 'ram', value: instance.ram_gigabytes },
          { field: 'disk', value: instance.disk_gigabytes },
          { field: 'flavorName', value: instance.flavor_name },
          { field: 'osProjectName', value: data[0].metadata.projects[instance.project_id].name },
          { field: 'osDomainName', value: data[0].metadata.domains[instance.domain_id].name },
          { field: 'createdAt', value: instance.created_at ? new Date(instance.created_at).toISOString().slice(0, 16) : 'N/A' },
          { field: 'deletedAt', value: instance.deleted_at ? new Date(instance.deleted_at).toISOString().slice(0, 16) : 'N/A' },
        ],
      }));

      setHypervisorDetails(cloudData);
    }
  }

  async function fetchHosts(selectedCloudId) {
    const [data] = await fetchHostsCall(selectedCloudId);
    if (data) {
      const tableRows = data.map((d) => ({
        key: d.host,
        onRowClick: () => getHypervisorDetails(d.host, selectedCloudId),
        data: [
          { field: 'hostName', value: d.host },
          { field: 'availabilityZone', value: d.availability_zone },
          { field: 'computeRole', value: d.compute_type },
          { field: 'cpuRatio', value: d.cpu_overcommit_ratio_used },
          { field: 'ramRatio', value: d.ram_mb },
          { field: 'diskRatio', value: d.local_disk_gb },
        ],
      }));
      setHypervisors(tableRows);
    }
  }

  return (
    <Grid
      container
      direction="column"
      rowSpacing={10}
    >
      <Grid item>
        <ScaleDropdownSelect
          controlled
          id="cloudDropdown"
          name="cloudDropdown"
          label="Select a cloud to see the available hypervisors"
          value={cloudId}
          onScale-change={(event) => {
            setCloudId(event.detail.value);
            setHypervisorDetails([]);
            fetchHosts(event.detail.value);
          }}
        >
          {clouds.map((item) => (
            <ScaleDropdownSelectItem key={item.id} value={item.id}>
              {item.name}
            </ScaleDropdownSelectItem>
          ))}
        </ScaleDropdownSelect>
      </Grid>
      <Grid item style={{ maxWidth: '100%' }}>
        <Table
          header="Hypervisors"
          fields={hypervisorsFields}
          rows={hypervisors}
        />
      </Grid>
      <Grid item style={{ maxWidth: '100%' }}>
        <Table
          header="Hypervisor Details"
          fields={hypervisorsDetailsFields}
          rows={hypervisorDetails}
        />
      </Grid>
    </Grid>
  );
};

export default HypervisorReport;
