import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { ScaleList, ScaleListItem } from '@telekom/scale-components-react';
import { useIntl } from 'react-intl';

const ProductUnassignment = ({ resources }) => {
  const intl = useIntl();

  return (
    <>
      <Typography gutterBottom>{intl.formatMessage({ id: 'userUnassignment1' })}</Typography>
      <ScaleList>
        {resources.products.map((product) => (
          <ScaleListItem key={product._id}>
            {`${product.name} (${product.type})`}
          </ScaleListItem>
        ))}
      </ScaleList>
      <Typography gutterBottom>
        {intl.formatMessage({ id: 'userUnassignment2' })}
      </Typography>
    </>
  );
};

ProductUnassignment.propTypes = {
  resources: PropTypes.shape({
    products: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      _id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })).isRequired,
  }).isRequired,
};


export default ProductUnassignment;
