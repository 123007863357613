import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { Formik, Form } from 'formik';
import { ScaleButton } from '@telekom/scale-components-react';

import * as Yup from 'yup';
import validator from 'validator';

import { FormikTextField } from '../../components/Formik';
import Provision from '../../api/provision';
import useApiCall from '../../hooks/useApiCall';


const EditContactForm = ({
  formRef, juiceGroup, contact, onSubmit, onCancel,
}) => {
  const editContact = useApiCall(Provision.editContact, 'ContactEdited', null);

  const submit = async (formValues) => {
    const [data] = await editContact({ ...formValues, juiceGroup, id: contact._id });
    onSubmit(data.contacts);
    formRef.current.resetForm();
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().ensure(),
    phoneNumber: Yup.string().test({
      message: 'Please input valid phone number prefixed with country code',
      test: (value) => (value !== undefined ? validator.isMobilePhone(value, 'any', { strictMode: true }) : true),
    }),
    slackChannel: Yup.string(),
  });

  const initialValues = {
    name: contact.name,
    phoneNumber: contact.phoneNumber,
    slackChannel: contact.slackChannel,
  };

  return (
    <Formik
      enableReinitialize
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={submit}
    >
      {({ dirty, isValid }) => (
        <Form>
          <Grid
            container
            direction="column"
            py={2}
          >
            <Grid item>
              <FormikTextField name="name" label="Name" />
            </Grid>
            <Grid item>
              <FormikTextField name="phoneNumber" label="Phone Number" />
            </Grid>
            <Grid item>
              <FormikTextField name="slackChannel" label="Slack Channel" />
            </Grid>
            <Grid
              container
              justifyContent="end"
              gap={1}
            >
              <Grid item>
                <ScaleButton
                  variant="primary"
                  disabled={!dirty || !isValid}
                >
                  Save
                </ScaleButton>
              </Grid>
              <Grid item>
                <ScaleButton
                  name="cancel"
                  type="button"
                  variant="secondary"
                  onClick={() => {
                    formRef.current.resetForm();
                    onCancel();
                  }}
                >
                  Cancel
                </ScaleButton>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

EditContactForm.propTypes = {
  formRef: PropTypes.shape({
    current: PropTypes.shape({ resetForm: PropTypes.func.isRequired }),
  }).isRequired,
  contact: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.object,
    ]),
  ).isRequired,
  juiceGroup: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};


export default EditContactForm;
