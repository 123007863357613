import { Grid, Paper } from '@mui/material';
import { ScaleIconActionEdit, ScaleTabHeader, ScaleTabPanel } from '@telekom/scale-components-react';
import { useFormikContext } from 'formik';

import {
  FormikAutocomplete,
  FormikCheckbox,
  FormikMultiSelect,
  FormikMultilineTextField,
  FormikSelect,
  FormikTextField,
} from '../../../components/Formik/index';
import { filterDataUpdates } from '../../../helpers/objects';


const serviceReceivers = [
  { value: 'AT', label: 'AT' },
  { value: 'CZ', label: 'CZ' },
  { value: 'GR', label: 'GR' },
  { value: 'HR', label: 'HR' },
  { value: 'HU', label: 'HU' },
  { value: 'ME', label: 'ME' },
  { value: 'MK', label: 'MK' },
  { value: 'NL', label: 'NL' },
  { value: 'PL', label: 'PL' },
  { value: 'RO', label: 'RO' },
  { value: 'SK', label: 'SK' },
  { value: 'DE_DTT', label: 'DE-DTT' },
  { value: 'DE_PK', label: 'DE-PK' },
  { value: 'DE_GK', label: 'DE-GK' },
  { value: 'DE_IOT', label: 'DE-IOT' },
  { value: 'VTI_GT', label: 'VTI-GT' },
  { value: 'VTI_IHUB', label: 'VTI-iHUB' },
  { value: 'VEU', label: 'VEU' },
];

const importanceDepartments = [
  { value: 'REGULATORY', label: 'Regulatory' },
  { value: 'LEGAL', label: 'Legal' },
  { value: 'SECURITY', label: 'Security' },
];

const technicalDomains = [
  { value: 'Engineering', label: 'Engineering' },
  { value: 'CommonCloudPlatforms', label: 'Common Cloud Platforms' },
  { value: 'CloudTransformationAutomation', label: 'Cloud Transformation & Automation' },
];

const tribes = [
  { value: 'Tribe1', label: 'Tribe 1' },
  { value: 'Tribe2', label: 'Tribe 2' },
  { value: 'Tribe3', label: 'Tribe 3' },
];


const GeneralTab = () => {
  const formikContext = useFormikContext();
  const formUpdates = filterDataUpdates(formikContext.initialValues, formikContext.values);

  return (
    <>
      <ScaleTabHeader slot="tab">
        General
        {Boolean(Object.keys(formUpdates).length) && (
          <ScaleIconActionEdit style={{ padding: 5 }} size={16} />
        )}
      </ScaleTabHeader>
      <ScaleTabPanel slot="panel">
        <Grid
          container
          justifyContent="space-between"
          columns={11}
        >
          <Grid item xs={5} padding=".8rem 0">
            <FormikTextField
              name="title"
              label="Title"
            />
          </Grid>
          <Grid item xs={5} padding=".8rem 0">
            <FormikTextField
              name="description"
              label="Description"
            />
          </Grid>
          <Grid item xs={5} padding=".8rem 0">
            <FormikAutocomplete
              name="serviceReceivers"
              label="Service Receivers"
              options={serviceReceivers}
            />
          </Grid>
          <Grid item xs={5} padding=".8rem 0">
            <FormikTextField
              type="email"
              name="contactPerson"
              label="Contact Person"
            />
          </Grid>
          <Grid item xs={5} padding=".8rem 0">
            <FormikSelect
              name="technicalDomain"
              label="Technical Domain"
              menuItems={technicalDomains}
            />
          </Grid>
          <Grid item xs={5} padding=".8rem 0">
            <FormikSelect
              name="leadingTribe"
              label="Leading Tribe"
              menuItems={tribes}
            />
          </Grid>
          <Grid item xs={5} padding=".8rem 0">
            <FormikMultiSelect
              name="involvedTribes"
              label="Involved Tribes"
              menuItems={tribes}
            />
          </Grid>
          <Grid item xs={5} padding=".8rem 0">
            <FormikMultilineTextField
              name="tribeInvolvementNotes"
              label="Tribe Involvement Notes"
            />
          </Grid>
          <Grid item xs={12} padding=".8rem 0">
            <Grid
              container
              component={Paper}
              padding=".5rem"
            >
              <Grid item xs={12}>
                <FormikCheckbox
                  name="important"
                  label="Important"
                  childFields={[
                    'importanceDetails.department',
                    'importanceDetails.notes',
                  ]}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="flex-start"
                  padding=".5rem"
                >
                  <Grid item xs={5}>
                    <FormikSelect
                      name="importanceDetails.department"
                      label="Department"
                      menuItems={importanceDepartments}
                      disabled="important"
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <FormikMultilineTextField
                      name="importanceDetails.notes"
                      label="Notes"
                      disabled="important"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ScaleTabPanel>
    </>
  );
};


export default GeneralTab;
