import { configureStore, Tuple } from '@reduxjs/toolkit';
import { createEpicMiddleware } from 'redux-observable';
import * as Sentry from '@sentry/react';
import reducer from './reducer';
import safescarfEpics from './safescarf/epics';
import userEpics from './user/epics';
import usersEpics from './users/epics';

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

const createReduxStore = (state = {}) => {
  const epicMiddleware = createEpicMiddleware();

  const store = configureStore({
    reducer,
    middleware: () => new Tuple(epicMiddleware),
    preloadedState: state,
    enhancers: (getDefaultEnhancers) => getDefaultEnhancers().concat(sentryReduxEnhancer),
  });

  epicMiddleware.run(safescarfEpics);
  epicMiddleware.run(userEpics);
  epicMiddleware.run(usersEpics);

  return store;
};

export default createReduxStore;
