import PropTypes from 'prop-types';
import { ScaleDivider } from '@telekom/scale-components-react';
import { Typography } from '@mui/material';

const Section = ({ header, divider, children }) => (
  <section style={{ marginBottom: '1.5rem' }}>
    <Typography variant="h4" color="primary" gutterBottom>{header}</Typography>
    {children}
    {divider && <ScaleDivider />}
    <br />
  </section>
);

Section.propTypes = {
  header: PropTypes.string,
  divider: PropTypes.bool,
  children: PropTypes.node.isRequired,
};
Section.defaultProps = {
  divider: false,
  header: '',
};

export default Section;
