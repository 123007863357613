import {
  FormControl as FormControlMUI,
  FormHelperText as FormHelperTextMUI,
  InputLabel as InputLabelMUI,
  MenuItem as MenuItemMUI,
  Select as SelectMUI,
} from '@mui/material';
import PropTypes from 'prop-types';

export const MultiSelect = ({
  id,
  name,
  value,
  onChange,
  onBlur,
  label,
  menuItems,
  disabled,
  helperText,
}) => (
  <FormControlMUI>
    <InputLabelMUI id={id}>{label}</InputLabelMUI>
    <SelectMUI
      multiple
      id={id}
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      label={label}
      disabled={disabled}
    >
      <MenuItemMUI
        key="null_value"
        value=""
      >
        -- No selection --
      </MenuItemMUI>
      {menuItems.map((item) => (
        <MenuItemMUI
          key={item.value}
          value={item.value}
        >
          {item.label}
        </MenuItemMUI>
      ))}
    </SelectMUI>
    <FormHelperTextMUI>{helperText}</FormHelperTextMUI>
  </FormControlMUI>
);

MultiSelect.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  label: PropTypes.string.isRequired,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
    }),
  ),
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
};

MultiSelect.defaultProps = {
  onBlur: () => {},
  menuItems: [],
  disabled: false,
  helperText: '',
};
