import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {
  Formik,
  Form,
} from 'formik';
import { ScaleButton } from '@telekom/scale-components-react';

import { Grid } from '@mui/material';
import { regexValidator } from '../../helpers/regexValidator';
import { FormikTextField } from '../../components/Formik/index';


const CreateOrganizationForm = ({ onSubmit, onCancel }) => {
  const initialValues = {
    enterpriseName: '',
    CRMId: '',
  };

  const validationSchema = Yup.object().shape({
    enterpriseName: Yup.string().required('Required').matches(regexValidator.name, 'Start with letter, all lower-case, no spaces, no special characters(dash only)'),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ dirty, isValid }) => (
        <Form>
          <Grid
            container
            paddingTop="1rem"
            rowGap={2}
            marginBottom="1rem"
            justifyContent="end"
          >
            <FormikTextField
              autoFocus
              name="enterpriseName"
              label="Organization name"
            />
            <FormikTextField
              name="CRMId"
              label="CRM Id"
            />
            <ScaleButton
              variant="primary"
              disabled={!dirty || !isValid}
            >
              Create
            </ScaleButton>
            <ScaleButton
              type="button"
              variant="secondary"
              style={{ marginLeft: '1em' }}
              onClick={onCancel}
            >
              Cancel
            </ScaleButton>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

CreateOrganizationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};


export default CreateOrganizationForm;
