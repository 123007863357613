import { combineReducers } from 'redux';

import safescarfReducers from './safescarf/reducers';
import { statusReducer } from './status/reducer';
import { notifierReducer } from './notifier/reducer';
import { userReducer } from './user/reducer';
import { usersReducer } from './users/reducer';

const reducer = combineReducers({
  user: userReducer,
  notifier: notifierReducer,
  users: usersReducer,
  safescarf: safescarfReducers,
  status: statusReducer,
});

export default reducer;
