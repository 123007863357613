import { ofType } from 'redux-observable';
import { mergeMap, catchError } from 'rxjs/operators';
import { from, of } from 'rxjs';

import Auth from '../../api/auth';
import { requestFailed } from '../status/actions';
import { ORDER_INSTANCE_FULFILLED } from '../safescarf/instance/actions';
import {
  FETCH_USER_DATA_STARTED,
  fetchUserDataFulfilled,
} from './actions';

const fetchUserDataEpic = (action$) => action$.pipe(
  ofType(FETCH_USER_DATA_STARTED, ORDER_INSTANCE_FULFILLED),
  mergeMap((action) => from(Auth.fetchV2Users(action.payload)).pipe(
    mergeMap((response) => of(
      fetchUserDataFulfilled(response.data[0]),
    )),
    catchError(() => of(
      requestFailed(),
    )),
  )),
);

export default fetchUserDataEpic;
