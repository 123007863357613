import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useNavigate, Navigate, useLocation } from 'react-router-dom';
import { Formik, Form } from 'formik';
import validator from 'validator';
import * as Yup from 'yup';
import { ScaleTextField, ScaleButton } from '@telekom/scale-components-react';
import { Container, Typography } from '@mui/material';

import { enqueueSnackbar } from '../../redux/notifier/actions';
import Auth from '../../api/auth';

const NewPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const intl = useIntl();
  const verificationToken = location.pathname.split('/').pop(-1);

  const ValidationSchema = Yup.object().shape({
    password1: Yup.string()
      .test(
        'Strong pasword',
        intl.formatMessage({ id: 'ERR_PASSWORD_INVALID' }),
        (value) => (value ? validator.isStrongPassword(value, { minLength: 10 }) : true),
      ).required('Required'),
    password2: Yup.string()
      .oneOf([Yup.ref('password1'), null], intl.formatMessage({ id: 'userPasswordMismatch' }))
      .required('Required'),
  });

  const resetUserPassword = async (data) => {
    try {
      await Auth.resetUserPassword({ newPassword: data.password1, verificationToken });
      dispatch(enqueueSnackbar({
        message: 'userPasswordReset',
        options: { variant: 'success' },
      }));
      navigate('/');
    } catch (error) {
      dispatch(enqueueSnackbar({
        message: error.message,
        options: { variant: 'error' },
      }));
    }
  };

  if (!verificationToken) {
    return (
      <Navigate to="/" />
    );
  }

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" color="primary" display="inline">
        Password Reset
      </Typography>
      <Formik
        initialValues={{ password1: '', password2: '' }}
        validationSchema={ValidationSchema}
        onSubmit={resetUserPassword}
      >
        {(formik) => (
          <Form>
            <ScaleTextField
              name="password1"
              value={formik.values.password1}
              type="password"
              label="New Password"
              onScale-change={formik.handleChange}
              helperText={formik.errors.password1}
            />
            <ScaleTextField
              name="password2"
              value={formik.values.password2}
              type="password"
              label="Retype New Password"
              onScale-change={formik.handleChange}
              helperText={formik.errors.password2}
            />
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <ScaleButton disabled={!formik.dirty || !formik.isValid}>
                Submit
              </ScaleButton>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default NewPassword;
