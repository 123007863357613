import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { ScaleModal } from '@telekom/scale-components-react';

import rbacCheck from '../../../../../rbac/rbacCheck';
import Table from '../../../../../components/Table';
import CreateProductTypeForm from './CreateProductTypeForm';
import { fetchProductTypes } from '../../../../../redux/safescarf/productTypes/actions';

const ProductTypes = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const intl = useIntl();
  const [createModalOpened, setCreateModalOpened] = useState(false);

  const userData = useSelector((state) => state.user);
  const productTypes = useSelector((state) => state.safescarf.productTypes);

  useEffect(() => {
    if (!productTypes.length) dispatch(fetchProductTypes());
  }, []);

  const canCreateProductType = rbacCheck(userData.roles, '/products/safescarf/product-types:POST');

  const productTypeRows = productTypes.map((productType) => ({
    key: productType.id.toString(),
    onRowClick: () => navigate(productType.id.toString()),
    data: [
      {
        field: 'name',
        value: productType.name,
      },
      {
        field: 'description',
        value: productType.description,
      },
    ],
  }));

  return (
    <>
      {createModalOpened && (
        <ScaleModal
          opened
          size="small"
          heading="Create Product Type"
          onScale-close={() => setCreateModalOpened(false)}
        >
          <CreateProductTypeForm
            onCancel={() => setCreateModalOpened(false)}
            onSubmit={() => setCreateModalOpened(false)}
          />
        </ScaleModal>
      )}
      <Table
        header="Product Types"
        fields={[
          {
            id: 'name',
            title: 'Name',
          },
          {
            id: 'description',
            title: 'Description',
          },
        ]}
        rows={productTypeRows}
        createAction={{
          disabled: !canCreateProductType,
          onClick: () => setCreateModalOpened(true),
          tooltip: !canCreateProductType ? intl.formatMessage({ id: 'createProductTypeDisabled' }) : '',
        }}
      />
    </>
  );
};


export default ProductTypes;
