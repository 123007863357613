export default {
  title: 'TDI Connect (German)',
  iaasDescription: 'Transform Your IT and Telco Operations with Scalable Cloud Solutions (German)',
  iaasSubDescription: 'Empower your business with cloud-based services designed to support the digital transformation of IT and Telco applications across Europe. Explore cutting-edge tools that enable seamless scalability, enhanced efficiency, and reliable operations. (German)',
  iaasDescription1: 'Exclusive Early Access for Selected Partners (German)',
  iaasSubDescription1: 'As we refine our offerings, this service is currently available to selected partners. Gain early access to powerful solutions tailored to your needs and help shape the future of cloud transformation. (German)',
  iaasDescription2: 'Be Part of the Future (German)',
  iaasSubDescription2: 'Join us on this exciting journey. Contact us today to learn more and explore partnership opportunities! (German)',
  productsTitle: 'Products and Services(German)',
  juiceTitle: 'Just a User, Infrastructure and Capacity Engine (German)',
  juiceDescription: 'JUICE Provides an easy-to-use interface for creating a virtual data centers in managed CIT clouds and manages capacities over datacenters. Offers a single service endpoint for cloud admins and customers across all TDI DC sites for managed products. (German)',
  safescarfTitle: 'Security single pane of glass (German)',
  safescarfDescription: 'SafeSCARF provides a set of scanning tools together with complete vulnerability lifecycle management. It is designed to respect the dynamics of the cloud environment, but for non-cloud environments/deployments. It supports multiple report formats out of the box which can be imported/unified. High-quality documentation alongside a user-friendly interface made SafeSCARF easy to use for customers. Free of charge period will be agreed upon individually. (German)',
  computeTitle: 'Compute (German)',
  computeDescription: 'TDI offers cloud computing services under the Infrastructure as a Service (IaaS) model. You can provision instances (virtual machines) as well as the associated networking and storage components and then deploy your application into those instances. The physical infrastructure underneath is fully and transparently managed for easy consumption. (German)',
  caasTitle: 'Container as a Service (German)',
  caasDescription: 'Provides a managed environment for deploying, managing, and scaling containerized applications. Allows software developers to upload, organize, run, scale and manage containers by using managed Kubernetes clusters through API calls. CaaS on OpenStack: Consists of multiple virtual machines grouped together to form a Kubernetes cluster in a dedicated OpenStack tenant. CaaS on BareMetal: Consists of multiple dedicated physical servers grouped together to form a Kubernetes cluster (German)',
  yes: 'Yes (German)',

  /* users */
  userRegistered: 'User was successfully registered (German)',
  userActivated: 'User was successfully activated (German)',
  userPasswordChanged: 'Password was successfully changed. (German)',
  userPasswordMismatch: 'Passwords do not match. (German)',
  userPasswordResetEmail: 'Your password reset email has been sent (German)',
  userPasswordReset: 'User password was successfully saved. Now you can log in (German)',
  userUpdated: 'User was successfully updated (German)',
  cloudPasswordUpdate: 'Cloud password was successfully updated (German)',
  userCreation: 'User {email} was successfully created.(German)',
  userDeleted: 'User was successfully deleted.(German)',
  userAssigned: 'User was successfully assigned.(German)',
  userUnassigned: 'User was successfully unassigned.(German)',
  userAlreadyAssignedToProduct: 'User is already assigned to this product. (German)',
  userDeleteConfirm: 'Do you really wish to delete user (German)',
  userDeletePermissionErr: 'You  don\'t have a permission to delete this user. (German)',
  userEditPermissionErr: 'You  don\'t have a permission to edit this user. (German)',
  userOrgChangeErr: 'You cannot change organization of users. (German)',
  userUnassignment1: 'This user is assigned to the following products: (German)',
  userUnassignment2: 'By proceeding the user will be unassigned from listed products. (German)',
  userUnassignment3: 'Would you like to continue? (German)',
  incorrectToken: 'Incorrect token (German)',
  userActivationErr: 'User activation failed (German)',
  userCreationPermissionErr: 'You  don\'t have the permission to create new user. (German)',

  /* business units */
  businessUnitCreation: 'Business unit {name} was successfully created.(German)',
  businessUnitUpdated: 'Business unit was successfully updated.(German)',
  businessUnitDeleted: 'Business unit {name} was successfully deleted.(German)',
  businessUnitEditPermissionErr: 'You  don\'t have the permission to edit this business unit.(German)',
  businessUnitDeletePermissionErr: 'You  don\'t have the permission to delete this business unit.(German)',
  businessUnitCreatePermissionErr: 'You  don\'t have the permission to create new business unit. (German)',
  businessUnitDeleteConfirmation: 'Enter the name of the business unit you would like to delete. (German)',

  /* organizations */
  organizationCreation: 'Organization {enterpriseName} was successfully created.(German)',
  organizationUpdated: 'Organization was successfully updated.(German)',
  organizationDeleted: 'Organization was successfully deleted.(German)',
  organizationCreationPermissionErr: 'You  don\'t have the permission to create new organization. (German)',
  editOrganizationDisabled: 'You  don\'t have the permission to edit this organization. (German)',

  /* projects */
  createOsProjectFulfilled: 'We are preparing your product in the data center. This operation will take a few minutes. You will be notified when the product is ready, and the service status will be shown as active. You can access all your active products (OpenStack projects) with one password per data center. This data center password is sent to you by e-mail when you create your first product (OpenStack project) in a respective data center. In case you did not receive an e-mail with a password, or you forgot your data center password, you can easily restore it in the portal (under Settings) – or contact our support team. (German)',
  osProjectCreationFailure: 'Please wait a minute to try again or fix the informed error. If the error persist please contact support (German)',
  projectDetails: 'Project {name} details (German)',
  projectCreation: 'Project {name} was successfully created.(German)',
  projectDeleted: 'Project {name} was successfully deleted.(German)',
  projectEdit: 'Project {name} was successfully updated.(German)',
  noUsageData: 'There is no usage data.(German)',
  noInstanceReports: 'There are no VMs to report for this cloud (German)',
  projectCreationPermissionErr: 'You  don\'t have the permission to create new project. (German)',
  editProjectDisabled: 'You  don\'t have the permission to edit this project. (German)',
  deleteProjectDisabled: 'There are existing Products in this project. (German)',
  projectDeleteConfirmation: 'Enter the name of the project you would like to delete. (German)',

  /* products */
  productForecasts: 'Product {name} forecasts (German)',
  copied: 'Succesfully Copied.(German)',
  copyFailed: 'Copy failed.(German)',
  osProjectDeleted: 'Deletion succesfully started (German)',
  osProjectSetDeleted: 'Succesfully Deleted OsProject (German)',
  osProjectPatchFailed: 'Unable to set OsProject to deleted (German)',
  osProjectDeleteDisabled: 'You don\'t have rights to set this OsProject to deleted. Please contact an admin user. (German)',
  productCreationPermissionErr: 'You  don\'t have the permission to create new product. (German)',
  evaluationProductCreationDisabled: 'You can\'t create new evaluation product. (German)',
  osProjectDeleteDisabledCreatedFromJuice: 'This osproject was created from juice and cannot be deleted. (German)',
  productCreation: 'Product {name} was successfully created. (German)',
  productUpdated: 'Product {name} was successfully updated. (German)',
  productDeleted: 'Product is successfully deleted (German)',
  productDeleteDisabled: 'You don\'t have rights to delete this product. Please contact admin user (German)',
  productDeleteUndoable: 'This type of product can\'t  be deleted. (German)',
  productDeleteDisabledOsProjects: 'There are existing OsProjects in this product (German)',
  productDeleteDisabledCaasClusters: 'There are existing CaaS Clusters in this product (German)',
  productDeleteDisabledCaasBm: 'Product with type caasonbaremetal is not deletable (German)',
  editProductDisabled: 'You  don\'t have the permission to edit this product. (German)',
  productDeleteMessage: 'When you delete the product, the juice group accosiated with it will also be deleted. (German)',
  productDeleteConfirmation: 'Enter the name of the product you would like to delete. (German)',

  evaluation: 'EVALUATION - PIAAS',
  computePackage: 'COMPUTE PACKAGE',
  stayConnected: 'STAY CONNECTED',

  /* juiceGroup  info */
  EditJuiceGroupInfo: 'Edit JuiceGroup info',

  /* contact info */
  ContactAdded: 'Contact information was successfully added (German)',
  ContactEdited: 'Contact information was successfully edited (German)',
  ContactRemoved: 'Contact information was successfully removed (German)',
  contactCreateDisabled: 'You don\'t have rights to create contact (German)',
  contactEditDisabled: 'You don\'t have rights to edit contact (German)',
  contactDeleteDisabled: 'You don\'t have rights to delete contact (German)',
  GeoRedundancySet: 'Geo redundancy was successfully set (German)',
  ServiceAdded: 'External service was successfully added (German)',
  ServiceRemoved: 'External service was successfully removed (German)',
  ContactDeleteConfirmation: 'Enter the email of the contact you would like to delete. (German)',
  contactDuplication: 'Contact already exists. (German)',

  /* OsProjects */
  osProjectsDeleteMessage: 'When you delete the Os Project, it will also be deleted from openStack. (German)',
  osProjectsDeleteConfirmation: 'Enter the name of the Os Project you would like to delete. (German)',
  osProjectDuplicationInPortal: 'This OS Project already exists. (German)',
  osProjectDuplicationInJuice: 'This OS Project already exists in Juice. (German)',

  /* Table */
  EmptyTable: 'No records to display. (German)',

  /* Errors */
  ERR_AUTH: 'Permission denied (German)',
  ERR_INPUT_VALIDATION: 'Input validation failure (German)',
  ERR_USER_DELETION_NOT_ENTITLED: 'User doesn\'t have rights to delete that user role (German)',
  ERR_VERIFICATION_TOKEN: 'Verification token mismatch or user not found (German)',
  ERR_INTERNAL_SERVER_ERROR: 'Internal Server Error (German)',
  ERR_USER_DUPLICATION: 'User with provided email is already registered. (German)',
  ERR_WRONG_SPECS: 'Product specs are not aligned with your organization entitlements. Please contact support for more information. (German)',
  ERR_UNAUTHORIZED: 'Not authorized (German)',
  ERR_PASSWORD_INVALID: 'Your password should have at least, 10 characters, 1 uppercase, 1 lowercase, 1 digit and 1 special character. (German)',
  ERR_PASSWORD_DUPLICATE: 'You cannot re-use the current password (German)',
  ERR_EMAIL_INVALID: 'Your email is invalid. (German)',
  ERR_MARKETPLACE_ITEM_NOT_ENABLED: 'Marketplace item not enabled (German)',
  ERR_DUPLICATION: 'Duplication error (German)',
  ERR_SAFESCARF_INSTANCE_CREATION_FAILURE: 'SafeSCARF instance creation failed (German)',
  ERR_ORGANIZATION_DUPLICATION: 'Organization already exists (German)',
  ERR_EXISTING_PRODUCTS: 'Cannot proceed because of existing products (German)',
  ERR_EXISTING_TENANT: 'Cannot proceed because of existing tenants (German)',
  ERR_PRODUCT_ASSIGMENT_DUPLICATION: 'User already assigned (German)',
  ERR_UNABLE_TO_CONNECT_TO_CLOUD: 'Unable to connect to cloud (German)',
  ERR_ITEM_ALREADY_ACTIVATED: 'The selected marketplace item is already active (German)',
  ERR_CONTACT_DUPLICATION: 'Contact already exists (German)',
  ERR_JUICEGROUP_INVALID_LENGTH: 'The Juice Group name must be between 3 and 64 characters (German)',
  ERR_JUICEGROUP_INVALID: 'The Juice Group Name is invalid (German)',

  /* Notification Messages */
  defaultMessage: 'Something went wrong (German)',
  duplicationMessage: 'already exists (German)',
  networkErrorMessage: 'A network or service error occurred (German)',

  /* forecasts */
  forecastsNotFound: 'There is no information in forecast DB for this Juice Group, you cannot create virtual cloud for this project. (German)',
  forecastsApplySuccess: 'Forecast(s) are successfully applied (German)',
  forecastsApplyFailure: 'Forecast(s) applying failed (German)',

  /* safescarf */
  safescarfStatusLabel: 'The progress of your SafeSCARF instance deployment is:(German)',
  safescarfOrderCreated: 'We are preparing your SafeSCARF instance. This operation will take a few minutes. You will be notified when your instance is ready by e-mail, and the instance status will be shown as active.(German)',
  createProductTypeDisabled: 'You are not entitled to create a new product type. (German)',
  editProductTypeDisabled: 'You are not entitled to edit this product type. (German)',
  safescarfProductDuplication: 'Product with this name already exists. (German)',
  safescarfProductTypeDuplication: 'Product type with this name already exists. (German)',
  safescarfInstanceDuplication: 'A SafeScarf instance already exists. (German)',
  deleteProductMessage: 'Are you sure you want to delete this product? (German)',
  deleteProductConfirmation: 'Enter the name of the product you would like to delete. (German)',
  safescarfProductDeleteDisabled: 'You don\'t have rights to delete this product. (German)',
  createProductDisabled: 'You are not entitled to create a new product. (German)',
  editSafescarfProductDisabled: 'You are not entitled to edit this product.(German)',
  editProductMemberDisabled: 'You are not entitled to edit this product member. (German)',
  editProductTypeMemberDisabled: 'You are not entitled to edit this product type member. (German)',

  /* api key */
  apiKeyCopied: 'API key copied. (German)',
  apiKeyCopyErr: 'API key copy failed. (German)',
  apiKeyGenerationErr: 'API key generation failed (German)',

  /* admin overview */
  adminOverviewAdmin: 'You are able to manage Users, Organizations, Business Units, Projects and Marketplace items for organizations using Admin menu. (German)',
  adminOverviewAccountOwner: 'You are able to manage Users, Business Units, and Projects using Admin menu. (German)',
  adminOverviewAccountAssistant: 'You are able to manage Users, Business Units, and Projects using Admin menu. (German)',
  adminOverviewBussinesUnitManager: 'You are able to manage Users, Business Units, and Projects using Admin menu. (German)',
  adminOverviewTechnicalLead: 'You are able to manage Users and Projects using Admin menu. (German)',

  /* Compute Order Text for Project and Resource Allocation */
  heading: 'Specify the project, datacenter and Openstack project name below. (German)',
  projectName: 'A user-friendly name identifying the OS Project. (German)',
  projectDescription: 'The initiative or activity of your organization. Projects can be created only by Admins. (German)',
  productDescription: 'Child of a Project that requires you to instantiate cloud resources. Products can be created only by Admins. (German)',
  dataCenterDescription: 'TDI-operated data centers in Europe, compliant with DT standards and organized in a geo-redundant location concept. (German)',
  openStackProject: 'Created by the OpenStack operating system to isolate access to compute resources for (a set of) users. An OpenStack project is created for a set of instances and networks that are configured as a discrete entity. (German)',
  virtualCPUsAndMemory: 'Amounts to be allocated to your OpenStack project. The vCPUs are offered with a standard processing performance. In case you require high performance processing, please reach out to our support team. (German)',
  notAssignedToProduct: 'You are not assigned to any compute product (German)',
  notApprovedProducts: 'You are not assigned to any approved product (German)',
  userAssignedToProductDuplications: 'User is already assigned to this product. (German)',

  /* Caas */
  CaasRequestCreated: 'We have processed your cluster create request. You will be notified when the cluster is ready and the cluster status will be shown as active on cluster details page. You can access all your active products (CaaS clusters) with the kubeconfig file that will be given in the cluster details. If you have not received an email regarding your cluster status, please contact our support team. (German)',
  CaasClusterKubeconfigGetErr: 'Unexpected error when getting kube config, please contact support. (German)',

  /* Safescarf */
  SafescarfMachineAccountCreated: 'Machine Account {name} was created. Please copy and preserve API key: (German)',

  /* Reports */
  fetchCloudsError: 'Unable to retrieve clouds (German)',

  /* Cookies */
  CookieBannerTitle: 'Cookie Usage (German)',
  CookieBannerCheckboxMessage1: 'By checking this box, you confirm that you have read & understood our (German)',
  CookieBannerCheckboxMessage2: ', which explains how we collect, use, and protect your personal data. (German)',
};
