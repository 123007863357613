import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { ScaleModal } from '@telekom/scale-components-react';
import {
  Breadcrumbs,
  Container,
  Grid,
  Typography,
} from '@mui/material';

import { fetchMachineAccounts } from '../../../../../redux/safescarf/machineAccounts/actions';
import { fetchRoles } from '../../../../../redux/safescarf/roles/actions';
import { fetchProducts } from '../../../../../redux/safescarf/products/actions';
import { fetchProductMembers } from '../../../../../redux/safescarf/productMembers/actions';
import { fetchUsers as fetchSafescarfUsers } from '../../../../../redux/safescarf/users/actions';

import rbacCheck from '../../../../../rbac/rbacCheck';
import OverviewTable from '../../../../../components/OverviewTable/OverviewTable';
import EditProductMemberForm from './EditProductMemberForm';


function getMember(user, productMembers, isMachineAccount, productId) {
  const productMember = productMembers.find((m) => m.product === Number(productId)
  && m.user === user.id);

  return {
    userId: user.id,
    username: user.username,
    createdBy: user.email,
    globalRole: user.globalRole,
    productMemberId: productMember?.id,
    productMemberRole: productMember?.role,
    isMachineAccount,
  };
}

const ProductMemberDetails = () => {
  const { productId, safescarfUserId } = useParams();
  const dispatch = useDispatch();

  const [editModalOpened, setEditModalOpened] = useState(false);
  const [member, setMember] = useState();
  const [currentUser, setcurrentUser] = useState();
  const userData = useSelector((state) => state.user);
  const roles = useSelector((state) => state.safescarf.roles);
  const products = useSelector((state) => state.safescarf.products);
  const productMembers = useSelector((state) => state.safescarf.productMembers);
  const safescarfUsers = useSelector((state) => state.safescarf.users);
  const machineAccounts = useSelector((state) => state.safescarf.machineAccounts);

  const safescarfUser = safescarfUsers.find((user) => user.id === Number(safescarfUserId));
  const product = products.find((pt) => pt.id === Number(productId));
  const machineAccount = machineAccounts.find((ma) => ma.id === Number(safescarfUserId));

  useEffect(() => {
    if (!safescarfUsers.length) dispatch(fetchSafescarfUsers());
    if (!machineAccounts.length) dispatch(fetchMachineAccounts());
    if (!roles.length) dispatch(fetchRoles());
    if (!products.length) dispatch(fetchProducts());
    if (!productMembers.length) dispatch(fetchProductMembers());
  }, []);

  useEffect(() => {
    if (userData.email && safescarfUsers.length) {
      const user = safescarfUsers.find((u) => u.username === userData.email);
      setcurrentUser(getMember(user, productMembers, false, productId));
    }
  }, [userData, safescarfUsers, productMembers]);

  useEffect(() => {
    if (machineAccount && productMembers.length) {
      setMember(getMember(machineAccount, productMembers, true, productId));
    } else if (safescarfUser && productMembers.length) {
      setMember(getMember(safescarfUser, productMembers, false, productId));
    }
  }, [machineAccount, productMembers, safescarfUsers]);

  const canEditProductMembers = rbacCheck(
    userData.roles,
    '/products/safescarf/products:PUT_MEMBERS',
    currentUser,
  );

  const roleLookup = roles.reduce((acc, role) => {
    acc[role.id] = role.name;
    return acc;
  }, {});

  return (
    <>
      {editModalOpened && (
        <ScaleModal
          opened
          size="small"
          heading="Edit Product"
          onScale-close={() => setEditModalOpened(false)}
        >
          <EditProductMemberForm
            productMember={member}
            onCancel={() => setEditModalOpened(false)}
            onSubmit={() => setEditModalOpened(false)}
          />
        </ScaleModal>
      )}
      <Grid
        className="content-with-header"
        container
        direction="column"
        rowGap={4}
      >
        <Grid item alignSelf="flex-start">
          <Breadcrumbs key={product?.id} separator=">">
            <Link to="/products/safescarf">Safescarf</Link>
            <Link to="/products/safescarf/products">products</Link>
            <Link to={`/products/safescarf/products/${product?.id}`}>{product?.name}</Link>
            <Link to={`/products/safescarf/products/${product?.id}`}>members</Link>
            <Typography>{member?.username}</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Container maxWidth="lg">
            <OverviewTable
              header={member?.username}
              rows={[
                { title: 'Product:', value: product?.name },
                { title: 'Machine account:', value: member?.isMachineAccount ? 'Yes' : 'No' },
                { title: 'Created by:', value: member?.createdBy },
                { title: 'Role:', value: roleLookup[member?.productMemberRole] || 'Not assigned' },
                member?.globalRole && { title: 'Global role:', value: roleLookup[member?.globalRole] },
              ].filter((r) => r)}
              editAction={{
                onClick: () => setEditModalOpened(true),
                disabled: !canEditProductMembers,
                tooltip: 'editProductMemberDisabled',
              }}
            />
          </Container>
        </Grid>
      </Grid>
    </>
  );
};


export default ProductMemberDetails;
