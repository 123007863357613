const scrollWithOffset = (element) => {
  const headerOffset = document.querySelector('scale-telekom-header').offsetHeight;
  const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
  const offsetPosition = elementPosition - headerOffset;

  window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
};

export {
  scrollWithOffset,
};
