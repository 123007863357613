import { Container } from '@mui/material';
import useMarkdownFile from '../../hooks/useMarkdownFile';
import aboutUs from '../../markdowns/aboutUs.md';

const AboutUs = () => {
  const { markdownContent } = useMarkdownFile(aboutUs);

  return (
    <Container maxWidth="md">
      {markdownContent}
    </Container>
  );
};

export default AboutUs;
