import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Link,
  Outlet,
  useLocation,
  useParams,
} from 'react-router-dom';
import { Breadcrumbs, Grid, Typography } from '@mui/material';
import { ScaleSidebarNav, ScaleSidebarNavItem } from '@telekom/scale-components-react';

import rbacCheck from '../../rbac/rbacCheck';
import useApiCall from '../../hooks/useApiCall';
import Auth from '../../api/auth';


const OrganizationDetails = () => {
  const { pathname } = useLocation();
  const { organizationId } = useParams();
  const user = useSelector((state) => state.user);
  const [organization, setOrganization] = useState({});
  const currentPath = pathname.split('/')[3];

  const canSeeProjects = rbacCheck(user.roles, '/projects:VIEW');
  const canSeeMonitoring = rbacCheck(user.roles, '/monitoring', organization);

  const getOrganizationsCall = useApiCall(Auth.fetchV1OrganizationById);

  const getOrganization = async () => {
    const [data] = await getOrganizationsCall(organizationId);
    if (data) setOrganization(data);
  };

  useEffect(() => {
    getOrganization();
  }, []);

  const menuItems = [
    { title: 'Overview' },
    { title: 'Users', path: 'users' },
    { title: 'Business Units', path: 'business-units' },
    ...(canSeeProjects ? [{ title: 'Projects', path: 'projects' }] : []),
    ...(canSeeMonitoring ? [{ title: 'Monitoring dashboards', path: 'monitoring-dashboards' }] : []),
  ];

  return (
    <Grid
      className="content-with-header"
      container
      direction="column"
    >
      <Grid item>
        <Breadcrumbs key={currentPath + organization.enterpriseName} separator=" > ">
          <Link to="/organizations">organizations</Link>
          <Typography>{organization.enterpriseName}</Typography>
          {currentPath && <Typography>{currentPath}</Typography>}
        </Breadcrumbs>
      </Grid>
      <Grid item paddingTop="2rem">
        <Grid container justifyContent="space-between">
          <Grid item xs={2}>
            <ScaleSidebarNav>
              {menuItems.map((item) => (
                <ScaleSidebarNavItem
                  key={item.title}
                  active={currentPath === item.path}
                >
                  <Link to={item.path || ''}>{item.title}</Link>
                </ScaleSidebarNavItem>
              ))}
            </ScaleSidebarNav>
          </Grid>
          <Grid item xs={9}>
            <Outlet context={[organization, setOrganization]} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OrganizationDetails;
