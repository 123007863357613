import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {
  Formik,
  Form,
} from 'formik';
import { ScaleButton } from '@telekom/scale-components-react';
import { Grid } from '@mui/material';
import { FormikSelect } from '../../../components/Formik/index';
import ProductUnassignment from './ProductUnassignment';
import useApiCall from '../../../hooks/useApiCall';
import Auth from '../../../api/auth';

const EditUserForm = ({
  onSubmit, onCancel, user, roles, canEditOrg,
}) => {
  const [confirmSave, setConfirmSave] = useState(false);
  const [userResources, setUserResources] = useState({});
  const [organizations, setOrganizations] = useState([]);

  const getUserResourcesCall = useApiCall(Auth.fetchV1UserResources);
  const getOrganizationsCall = useApiCall(Auth.fetchV1Organizations);

  async function getOrganizations() {
    const [data] = await getOrganizationsCall();
    if (data) setOrganizations(data);
  }

  async function getUserResources() {
    if (!userResources.products?.length) {
      const [data] = await getUserResourcesCall(user.uuid);
      if (data) setUserResources(data);
    }
  }

  useEffect(() => {
    if (canEditOrg) getOrganizations();
    getUserResources();
  }, []);

  const onOrgChange = (e) => {
    if (e.detail.value !== user.organization._id && userResources.products.length) {
      setConfirmSave(true);
    } else {
      setConfirmSave(false);
    }
  };

  const initialValues = {
    roles: user.role,
    organizationId: user.organization?._id,
  };

  const validationSchema = Yup.object().shape({
    roles: Yup.string().required('Required'),
    organizationId: Yup.string().required('Required'),
  });

  const roleItems = Object.keys(roles).map((key) => ({
    value: key,
    label: roles[key],
  }));

  const organizationItems = organizations.map((organization) => ({
    value: organization._id,
    label: organization.enterpriseName,
  }));

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ dirty, isValid }) => (
        <Form>
          <Grid
            container
            direction="column"
            pb={2}
          >
            <Grid item>
              <FormikSelect
                name="roles"
                label="Role"
                menuItems={roleItems}
              />
            </Grid>
            {canEditOrg && organizations.length && (
              <Grid item>
                <FormikSelect
                  name="organizationId"
                  label="Organization"
                  menuItems={organizationItems}
                  onChange={onOrgChange}
                />
              </Grid>
            )}
            {confirmSave && (
              <Grid item>
                <ProductUnassignment resources={userResources} />
              </Grid>
            )}
            <Grid
              container
              justifyContent="end"
              gap={1}
            >
              <Grid item>
                <ScaleButton
                  variant="primary"
                  disabled={!dirty || !isValid}
                >
                  Save
                </ScaleButton>
              </Grid>
              <Grid item>
                <ScaleButton
                  type="button"
                  variant="secondary"
                  onClick={() => {
                    onCancel();
                    setConfirmSave(false);
                  }}
                >
                  Cancel
                </ScaleButton>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

EditUserForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  roles: PropTypes.objectOf(PropTypes.string).isRequired,
  canEditOrg: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    role: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
    organization: PropTypes.shape({
      _id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default EditUserForm;
