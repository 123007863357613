import { createTheme } from '@mui/material/styles';

export function createCustomTheme(mode) {
  return createTheme({
    palette: {
      mode,
      primary: {
        main: '#E20074',
      },
      secondary: {
        main: '#D90000',
      },
      error: {
        main: '#E82010',
      },
    },
    components: {
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            color: mode === 'dark' ? '#7d94f9' : 'blue',
            fontWeight: 'bold',
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            border: 'none',
            '&:before': {
              display: 'none',
            },
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            padding: 0,
          },
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            padding: 0,
          },
        },
      },
      MuiFormControl: {
        defaultProps: {
          size: 'small',
          fullWidth: true,
          margin: 'dense',
        },
        variants: [{
          props: { 'data-variant': 'padded' },
          style: {
            paddingBottom: '1rem',
          },
        }],
        styleOverrides: {
          root: {
            '& input:-webkit-autofill': {
              WebkitBoxShadow: '0 0 0 1000px transparent inset !important',
            },
            '& .MuiInputBase-root.Mui-disabled': {
              backgroundColor: mode === 'dark' ? '#262626' : '#cfcfcf',
              cursor: 'not-allowed',
            },
            '& label.Mui-focused': {
              color: '#777',
            },
            '& .MuiInput-underline:after': {
              borderBottomColor: '#777',
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#E0E3E7',
              },
              '&:hover fieldset': {
                borderColor: '#B2BAC2',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#777',
              },
            },
            '& .MuiInputBase-input': {
              color: mode === 'dark' ? 'white' : 'black',
            },
            color: '#777',
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          size: 'small',
          fullWidth: true,
          margin: 'dense',
        },
        styleOverrides: {
          root: {
            '& label.Mui-focused': {
              color: '#777',
            },
            '& .MuiInput-underline:after': {
              borderBottomColor: '#777',
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#E0E3E7',
              },
              '&:hover fieldset': {
                borderColor: '#B2BAC2',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#777',
              },
            },
            color: '#777',
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          option: {
            '&[aria-selected="true"]': {
              backgroundColor: '#D0D0D2 !important',
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            '&.Mui-disabled': {
              backgroundColor: '#D0D0D2',
              borderRadius: '.5rem',
              border: 'none',
            },
          },
          input: {
            '&::placeholder': {
              color: '#777',
              opacity: 1,
            },
            color: '#777',
          },
          inputSizeSmall: {
            '&::placeholder': {
              color: '#777',
              opacity: 1,
            },
            color: '#777',
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '.MuiOutlinedInput-notchedOutline': {
              borderRadius: '.5rem',
              border: '.0625rem solid rgba(0, 0, 0, 0.44)',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#777',
            },
          },
          input: {
            '&::placeholder': {
              color: '#777',
              opacity: 1,
            },
            color: '#777',
          },
          inputSizeSmall: {
            '&::placeholder': {
              color: '#777',
              opacity: 1,
            },
            color: '#777',
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: '#777',
            '&.Mui-focused': {
              color: '#777',
            },
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: '#777',
            '&.Mui-focused': {
              color: '#777',
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              backgroundColor: mode === 'dark' ? '#5c5c5c !important' : '#D0D0D2 !important',
              '&:hover': {
                backgroundColor: '#D0D0D2',
              },
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
            },
          },
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiPaper: {
        defaultProps: {
          square: true,
          variant: 'outlined',
        },
        styleOverrides: {
          root: {
            borderRadius: '.5rem',
          },
        },
      },
      MuiBreadcrumbs: {
        styleOverrides: {
          root: {
            color: '#E20074',
          },
          ol: {
            '& .MuiBreadcrumbs-separator': {
              color: '#E20074',
            },
            '& .MuiBreadcrumbs-li': {
              color: '#E20074',
            },
          },
        },
      },
    },
    typography: {
      fontFamily: 'TeleNeoWeb',
    },
  });
}
