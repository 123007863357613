import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import validator from 'validator';
import { Grid, Container, Typography } from '@mui/material';
import {
  ScaleButton,
  ScaleTextField,
  ScaleLink,
  ScaleCheckbox,
  ScaleDivider,
  ScaleIconAlertInformation,
  ScaleIconUserFileFamilies,
} from '@telekom/scale-components-react';

const terms = (
  <>
    <Typography gutterBottom>
      By accessing this web portal, You explicitly confirm and acknowledge that
      You are officially authorized and designated as &quot; IaaS &quot; user to represent
      Your Employer Company (as &quot;Service Receiver&quot;),
      whilst undertaking activities in accordance
      to the General Terms and Conditions given on the following
      &nbsp;
      <ScaleLink href="/gtc" target="blank"> General Terms and Conditions </ScaleLink>
      &nbsp;
      which must be explicitly accepted by You as requested below.
    </Typography>
    <Typography gutterBottom>
      Prior accepting the above stated General Terms and Conditions
      and Agreement on the Processing of Personal Data,
      please read it carefully and make sure that you understand the terms,
      conditions and provisions thereof,
      since by accepting these terms and conditions,
      You expressly agree to apply thereof when evaluating &quot;IaaS&quot;.
    </Typography>
    <Typography gutterBottom>
      By accepting the above stated General Terms and Conditions,
      please be informed that on the following
      &nbsp;
      <ScaleLink href="/data-privacy" target="_blank">Privacy</ScaleLink>
      &nbsp;
      you will find our &quot;Data privacy information for DT Cloud Services portal&quot;,
      for your own informative purposes and awareness, only.
    </Typography>
  </>
);

const SetPassword = (props) => {
  const { onSubmit, organizationName } = props;
  const intl = useIntl();
  const validationSchema = Yup.object().shape({
    termsAccepted: Yup.boolean()
      .oneOf([true], 'Required'),
    newPassword: Yup.string()
      .test(
        'Strong pasword',
        intl.formatMessage({ id: 'ERR_PASSWORD_INVALID' }),
        (value) => (value ? validator.isStrongPassword(value, { minLength: 10 }) : true),
      ).required('Required'),
    newPasswordConfirm: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], intl.formatMessage({ id: 'userPasswordMismatch' }))
      .required('Required'),
  });
  return (
    <Container maxWidth="md">
      <Typography gutterBottom variant="h4" color="primary">User activation</Typography>
      <Formik
        initialValues={{ newPassword: '', newPasswordConfirm: '', termsAccepted: false }}
        validationSchema={validationSchema}
        onSubmit={(data) => onSubmit(data.newPassword)}
      >
        {({
          isValid, dirty, isSubmitting, setFieldValue,
        }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <ScaleIconUserFileFamilies />
                <Typography gutterBottom>
                  {`You were invited to ${organizationName}. Please create your password.`}
                </Typography>
                <ScaleIconAlertInformation />
                <Typography gutterBottom>
                  Read about DT Cloud Services&nbsp;
                  <ScaleLink href="/about-us">here</ScaleLink>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Field name="newPassword">
                  {({ field, form: { errors } }) => (
                    <ScaleTextField
                      label="New Password"
                      type="password"
                      helperText={errors.newPassword}
                      onScale-change={field.onChange}
                      {...field}
                    />
                  )}
                </Field>
                <Field name="newPasswordConfirm">
                  {({ field, form: { errors } }) => (
                    <ScaleTextField
                      label="Verify password"
                      type="password"
                      helperText={errors.newPasswordConfirm}
                      onScale-change={field.onChange}
                      {...field}
                    />
                  )}
                </Field>
              </Grid>
            </Grid>
            <ScaleDivider />
            <Grid
              container
              spacing={2}
              direction="column"
              justifyContent="center"
              alignItems="flex-end"
            >
              <Grid item>
                {terms}
                <Field type="checkbox" name="termsAccepted">
                  {({ field }) => (
                    <ScaleCheckbox
                      label="Accept"
                      onScale-change={(event) => {
                        field.onChange(event);
                        setFieldValue('termsAccepted', !field.value);
                      }}
                      {...field}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item>
                <ScaleButton disabled={!dirty || (!isValid || isSubmitting)} type="submit">Submit</ScaleButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

SetPassword.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  organizationName: PropTypes.string.isRequired,
};

export default SetPassword;
