import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Table from '../../../../../components/Table';

import Caas from '../../../../../api/caas';

import useApiCall from '../../../../../hooks/useApiCall';


const OpenstackClusters = ({ product, setActiveResources }) => {
  const navigate = useNavigate();
  const [clusters, setClusters] = useState([]);
  const fetchClusters = useApiCall(Caas.getClusters);

  async function getProductResources() {
    const [data] = await fetchClusters({ juiceGroup: product.details.juiceGroup });
    if (data) {
      setClusters(data);
      setActiveResources(data.some((cluster) => ['Pending', 'Approved', 'Provisioned'].includes(cluster.status)));
    }
  }

  const ClustersTableRows = clusters?.map((cluster) => ({
    key: cluster.name,
    onRowClick: () => navigate(`/clusters/${cluster.name}`),
    data: [
      { field: 'name', value: cluster.name },
      { field: 'cloud', value: cluster.cloud },
      { field: 'status', value: cluster.status.operationStatus },
    ],
  }));


  useEffect(() => {
    if (product.details.juiceGroup) {
      getProductResources();
    }
  }, [product.details.juiceGroup]);

  return (
    <Table
      header="Clusters"
      fields={[
        { id: 'name', title: 'Name' },
        { id: 'cloud', title: 'Cloud' },
        { id: 'status', title: 'Status' },
      ]}
      rows={ClustersTableRows}
    />
  );
};

OpenstackClusters.propTypes = {
  product: PropTypes.shape({
    details: PropTypes.shape({
      juiceGroup: PropTypes.string,
    }),
  }).isRequired,
  setActiveResources: PropTypes.func.isRequired,
};

export default OpenstackClusters;
