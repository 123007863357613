import { ofType, combineEpics } from 'redux-observable';
import { mergeMap, catchError } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { handleError } from '../../errorHandler';
import Safescarf from '../../../api/safescarf';
import {
  FETCH_PRODUCT_MEMBERS_STARTED,
  fetchProductMembersFulfilled,
  UPDATE_PRODUCT_MEMBER_ROLE_STARTED,
  updateProductMemberRoleFulfilled,
} from './actions';

const fetchProductMembersEpic = (action$) => action$.pipe(
  ofType(FETCH_PRODUCT_MEMBERS_STARTED),
  mergeMap((action) => from(Safescarf.fetchSafescarfProductMembers(action.payload)).pipe(
    mergeMap((response) => of(
      fetchProductMembersFulfilled(response.data),
    )),
    catchError(handleError),
  )),
);
const updateProductMemberRoleEpic = (action$) => action$.pipe(
  ofType(UPDATE_PRODUCT_MEMBER_ROLE_STARTED),
  mergeMap((action) => from(Safescarf.updateProductMemberRole(action.payload)).pipe(
    mergeMap((response) => of(
      updateProductMemberRoleFulfilled(response.data),
    )),
    catchError(handleError),
  )),
);

export default combineEpics(
  fetchProductMembersEpic,
  updateProductMemberRoleEpic,
);
