import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { LineChart } from '@mui/x-charts';

import MonitoringMs from '../../../../api/monitoring';
import useApiCall from '../../../../hooks/useApiCall';

const TimeSeries = ({ panelInfo, dateRange }) => {
  const { dashboardId } = useParams();
  const [values, setValues] = useState({ xAxis: [], series: [] });
  const getPanelDataApiCall = useApiCall(MonitoringMs.getPanel);
  const getPanel = async (targets) => {
    const [data] = await getPanelDataApiCall(targets, dashboardId);
    if (data) {
      const refIds = panelInfo.targets.filter((target) => !target.hide)
        .map((target) => target.refId);
      const panelData = refIds.map((refId) => data.results[refId].frames[0]);
      const xAxis = panelData[0].data.values[0].map((v) => new Date(v));
      setValues({
        xAxis: [{ data: xAxis, scaleType: 'time' }],
        series: panelData[0].data.values.slice(1).map((d, index) => {
          const label = panelData[0].schema.fields[index + 1]?.labels
            ? `${panelData[0].schema.fields[index + 1].name} ${Object.values(panelData[0].schema.fields[index + 1]?.labels)}`
            : panelData[0].schema.fields[index + 1].name;
          return {
            curve: 'linear',
            label,
            data: d.map((v) => (v !== null ? v : 0)),
            showMark: false,
          };
        }),
      });
    }
  };


  useEffect(() => {
    const toDate = new Date(dateRange.to).getTime();
    const fromDate = new Date(dateRange.from).getTime();
    if (panelInfo.targets) {
      getPanel({ queries: panelInfo.targets, to: toDate.toString(), from: fromDate.toString() });
    }
  }, [dateRange]);

  return (
    <LineChart
      key={panelInfo.title}
      xAxis={values.xAxis}
      series={values.series}
      colors={['#E20074', '#04e000', '#f399c7']}
      grid={{ vertical: true, horizontal: true }}
      sx={{
        maxHeight: '100%',
        marginBottom: '2rem',
      }}
    />
  );
};

TimeSeries.propTypes = {
  panelInfo: PropTypes.shape({
    title: PropTypes.string,
    targets: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    gridPos: PropTypes.shape({
      h: PropTypes.number,
      w: PropTypes.number,
      x: PropTypes.number,
      y: PropTypes.number,
    }),
  }).isRequired,
  dateRange: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
  }).isRequired,
};


export default TimeSeries;
