/* eslint-disable camelcase */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ScaleTable } from '@telekom/scale-components-react';
import Provision from '../../../../../api/provision';
import useApiCall from '../../../../../hooks/useApiCall';

const CloudTable = ({ vms }) => {
  const totals = vms.reduce((acc, vm) => {
    acc.cpu_amount += vm.cpu_amount;
    acc.ram_amount += vm.ram_amount;
    acc.root_standard += vm.storage.root_standard.amount;
    acc.standard += vm.storage.standard.amount;
    return acc;
  }, {
    cpu_amount: 0,
    ram_amount: 0,
    root_standard: 0,
    standard: 0,
  });

  const decoratedVms = vms.map((vm) => {
    const { compute_node_constraints } = vm;

    function getCNType() {
      if (
        compute_node_constraints.cpu[0] === 'shared_cpus'
        && compute_node_constraints.root_disk.length === 0
      ) return 'shared';
      if (
        compute_node_constraints.cpu[0] === 'dedicated_cpus'
        && compute_node_constraints.root_disk.length === 0
      ) return 'dedicated';
      if (
        compute_node_constraints.cpu[0] === 'dedicated_cpus'
        && compute_node_constraints.root_disk.length > 0
      ) return 'dedicated-local';
      return 'shared-local';
    }
    return {
      ...vm,
      CNType: getCNType(),
      cpuVendor: compute_node_constraints.cpu_vendor.length > 0 ? 'intel' : '-',
    };
  });

  return (
    <ScaleTable>
      <table>
        <thead>
          <tr>
            <th>CN Type</th>
            <th>vCPU</th>
            <th>RAM (GB)</th>
            <th>Root Disc (GB)</th>
            <th>Standard Storage (GB)</th>
            <th>CPU Vendor</th>
          </tr>
        </thead>
        <tbody>
          {decoratedVms.map((vm) => (
            <tr key={vm.name}>
              <td>{vm.CNType}</td>
              <td>{vm.cpu_amount}</td>
              <td>{vm.ram_amount}</td>
              <td>{vm.storage.root_standard.amount}</td>
              <td>{vm.storage.standard.amount}</td>
              <td>{vm.cpuVendor}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td>Total</td>
            <td>{totals.cpu_amount}</td>
            <td>{totals.ram_amount}</td>
            <td>{totals.root_standard}</td>
            <td>{totals.standard}</td>
            <td>-</td>
          </tr>
        </tfoot>
      </table>
    </ScaleTable>
  );
};

const ForecastSummary = ({ juiceGroup, cloudId }) => {
  const [forecasts, setForecasts] = useState([]);
  const fetchForecasts = useApiCall(Provision.fetchForecasts, null, null);

  useEffect(() => {
    async function getForecasts() {
      const [data] = await fetchForecasts(juiceGroup, cloudId);
      if (data) setForecasts(data);
    }
    if (cloudId && juiceGroup) getForecasts();
  }, [cloudId, juiceGroup]);

  if (!forecasts.length) return <p>No Forecast was found</p>;
  return (
    <CloudTable vms={forecasts[0].vms} />
  );
};


CloudTable.propTypes = {
  vms: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType(
        [
          PropTypes.string,
          PropTypes.number,
          PropTypes.object,
        ],
      ),
    ),
  )
    .isRequired,
};
ForecastSummary.propTypes = {
  juiceGroup: PropTypes.string.isRequired,
  cloudId: PropTypes.string.isRequired,
};

export default ForecastSummary;
