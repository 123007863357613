export const FETCH_PRODUCT_TYPES_STARTED = 'FETCH_PRODUCT_TYPES_STARTED';
export const FETCH_PRODUCT_TYPES_FULFILLED = 'FETCH_PRODUCT_TYPES_FULFILLED';
export const UPDATE_PRODUCT_TYPE_STARTED = 'UPDATE_PRODUCT_TYPE_STARTED';
export const UPDATE_PRODUCT_TYPE_FULFILLED = 'UPDATE_PRODUCT_TYPE_FULFILLED';
export const CREATE_PRODUCT_TYPE_STARTED = 'CREATE_PRODUCT_TYPE_STARTED';
export const CREATE_PRODUCT_TYPE_FULFILLED = 'CREATE_PRODUCT_TYPE_FULFILLED';

export const fetchProductTypes = () => (
  { type: FETCH_PRODUCT_TYPES_STARTED }
);

export const fetchProductTypesFulfilled = (data) => (
  { type: FETCH_PRODUCT_TYPES_FULFILLED, payload: data }
);

export const updateProductType = (data) => (
  { type: UPDATE_PRODUCT_TYPE_STARTED, payload: data }
);

export const updateProductTypeFulfilled = (data) => (
  { type: UPDATE_PRODUCT_TYPE_FULFILLED, payload: data }
);

export const createProductType = (data) => (
  { type: CREATE_PRODUCT_TYPE_STARTED, payload: data }
);

export const createProductTypeFulfilled = (data) => (
  { type: CREATE_PRODUCT_TYPE_FULFILLED, payload: data }
);
