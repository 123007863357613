import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import {
  ScaleButton,
  ScaleIconActionCopyPaste,
  ScaleCheckbox,
} from '@telekom/scale-components-react';

import { Typography } from '@mui/material';
import { enqueueSnackbar } from '../../../../../redux/notifier/actions';


const CopyApiKey = ({ machineAccountName, machineAccountToken, onSubmit }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [copied, setCopied] = useState(false);

  const copy = async () => {
    try {
      await navigator.clipboard.writeText(machineAccountToken);
      dispatch(enqueueSnackbar({
        message: 'copied',
        options: { variant: 'success' },
      }));
    } catch (error) {
      dispatch(enqueueSnackbar({
        message: 'copyFailed',
        options: { variant: 'error' },
      }));
    }
  };

  return (
    <>
      <Typography gutterBottom>
        {intl.formatMessage({ id: 'SafescarfMachineAccountCreated' }, { name: machineAccountName })}
      </Typography>
      <Typography color="primary" gutterBottom>
        {machineAccountToken}
      </Typography>
      <Typography gutterBottom>
        Please save your token.
      </Typography>
      <ScaleCheckbox
        checked={copied}
        label="I copied api Token"
        onScale-change={() => {
          setCopied(!copied);
        }}
      />
      <ScaleButton
        slot="action"
        disabled={!copied}
        onClick={() => {
          copy();
          onSubmit();
        }}
      >
        Copy
        <ScaleIconActionCopyPaste />
      </ScaleButton>
    </>
  );
};

CopyApiKey.propTypes = {
  machineAccountName: PropTypes.string,
  machineAccountToken: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

CopyApiKey.defaultProps = {
  machineAccountName: '',
  machineAccountToken: '',
};


export default CopyApiKey;
