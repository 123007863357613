import { TextField as TextFieldMUI } from '@mui/material';
import PropTypes from 'prop-types';

export const NumberField = ({
  id,
  name,
  value,
  onChange,
  onBlur,
  label,
  placeholder,
  autoFocus,
  disabled,
  helperText,
  onWheel,
}) => (
  <TextFieldMUI
    type="number"
    id={id}
    name={name}
    value={value}
    onChange={onChange}
    onBlur={onBlur}
    label={label}
    placeholder={placeholder}
    autoFocus={autoFocus}
    disabled={disabled}
    helperText={helperText}
    onWheel={onWheel}
    min={0}
    inputProps={{ step: 'any', min: 0 }}
  />
);

NumberField.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
  onWheel: PropTypes.func,
};

NumberField.defaultProps = {
  onBlur: () => {},
  placeholder: '',
  autoFocus: false,
  disabled: false,
  helperText: '',
  onWheel: () => {},
};
