import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Markdown from 'markdown-to-jsx';
import { Typography, Grid } from '@mui/material';
import {
  ScaleAccordion, ScaleCollapsible, ScaleCard,
  ScaleIconAlertInformation,
  ScaleIconActionFullScreen,
  ScaleTooltip,
} from '@telekom/scale-components-react';
import { useNavigate, useOutletContext, useLocation } from 'react-router-dom';

import DateTimeFilter from './DateTimeFilter';
import Stat from './Stat';
import BarChartComponent from './BarChart';
import TimeSeries from './TimeSeries';
import GaugeComponent from './Gauge';
import MonitoringTable from './Table';
import GeoMap from './GeoMap';

import Section from '../../../../components/Section';

import './index.css';


const MonitoringComponents = ({ panelInfo, dateRange }) => {
  if (panelInfo.type === 'text') {
    return (
      <Markdown>{panelInfo.content}</Markdown>
    );
  }
  if (panelInfo.type === 'stat') {
    return (
      <Stat
        panelInfo={panelInfo}
        dateRange={dateRange}
        styles={{
          height: `${(panelInfo.gridPos.h * 2.3) - 3}rem`,
          fontSize: `${panelInfo.gridPos.h / 3 + 0.5}rem`,
        }}
      />
    );
  }
  if (panelInfo.type === 'barchart') {
    return (
      <BarChartComponent
        panelInfo={panelInfo}
        dateRange={dateRange}
        styles={{ height: panelInfo.gridPos.h * 33 }}
      />
    );
  }
  if (panelInfo.type === 'timeseries') {
    return (
      <TimeSeries
        panelInfo={panelInfo}
        dateRange={dateRange}
        styles={{ height: panelInfo.gridPos.h * 33 }}
      />
    );
  }
  if (panelInfo.type === 'gauge') {
    return (
      <GaugeComponent
        panelInfo={panelInfo}
        dateRange={dateRange}
        styles={{ height: panelInfo.gridPos.h * 30 }}
      />
    );
  }
  if (panelInfo.type === 'table') {
    return (
      <MonitoringTable
        panelInfo={panelInfo}
        dateRange={dateRange}
        styles={{ height: `${panelInfo.gridPos.h + 2}rem` }}
      />
    );
  }
  if (panelInfo.type === 'geomap') {
    return (
      <GeoMap
        panelInfo={panelInfo}
        dateRange={dateRange}
        mapZoom={4}
        styles={{ height: '85%', width: '100%' }}
      />
    );
  }
  return null;
};

MonitoringComponents.propTypes = {
  panelInfo: PropTypes.shape({
    type: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.string,
    gridPos: PropTypes.shape({
      h: PropTypes.number,
      w: PropTypes.number,
      x: PropTypes.number,
      y: PropTypes.number,
    }).isRequired,
  }).isRequired,
  dateRange: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
  }).isRequired,
};

// will be fixed in the next iteration
const notWorkingPanels = [
  'EU Region Availability',
];


const MonitoringDashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const context = useOutletContext();
  const [dashboardData, setDashboardData] = context;
  const searchParams = new URLSearchParams(location.search);

  const [dateRange, setDateRange] = useState({
    from: searchParams.get('from'),
    to: searchParams.get('to'),
  });

  const groupPanels = (panels) => {
    const sortedPanels = panels.sort((a, b) => a.gridPos.x - b.gridPos.x)
      .filter((panel) => !notWorkingPanels.includes(panel.title));
    const rows = [];
    const uniqueY = [...new Set(panels.map((panel) => panel.gridPos.y))];

    const groupedPanels = sortedPanels.reduce((acc, panel) => {
      const key = `${panel.gridPos.x}-${panel.gridPos.w}`;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(panel);
      return acc;
    }, {});


    uniqueY.forEach((y) => {
      rows.push(Object.values(groupedPanels).filter((panel) => panel[0].gridPos.y === y));
    });

    return rows.filter((row) => row.length > 0);
  };

  const getDashboard = async () => {
    if (dashboardData) {
      const updatedRows = dashboardData.rows.map((row) => ({
        ...row,
        rows: groupPanels(row.panels),
      }));

      setDashboardData({ ...dashboardData, accordions: updatedRows });
    }
  };

  useEffect(() => {
    getDashboard();
  }, [searchParams]);

  return dashboardData.accordions && searchParams ? (
    <div style={{ margin: '1rem -10%' }}>
      <Section header={dashboardData.title}>
        <DateTimeFilter dateRange={dateRange} setDateRange={setDateRange} />

        {dashboardData.accordions.map((accordion) => (
          <ScaleAccordion key={accordion.title} expanded>
            <ScaleCollapsible>
              <span slot="heading">{accordion.title}</span>
              {accordion.rows.map((row) => (
                <Grid key={row[0][0].title} container spacing={0.5} columns={24} pb={0.5}>
                  {row.map((panels) => (
                    <Grid key={panels[0].title} item xs={panels[0].gridPos.w}>
                      {panels.map((panel) => (
                        <ScaleCard key={panel.title} className="grid-container">
                          <div style={{ height: `${panel.gridPos.h * 2.3}rem`, margin: `${-1.45 - ((panels.length - 1) * 0.05)}rem` }}>
                            <div style={{ display: 'flex', padding: '0.5rem' }}>
                              <div style={{ display: 'flex', width: '-webkit-fill-available', paddingRight: '1rem' }}>
                                <Typography sx={{ fontWeight: 'bold' }} noWrap>{panel.title}</Typography>
                                <ScaleTooltip content={panel.description}>
                                  <ScaleIconAlertInformation size={15} style={{ marginTop: '0.1rem', marginLeft: '0.5rem' }} />
                                </ScaleTooltip>
                              </div>
                              <div
                                className="fullscreen-icon"
                                style={{
                                  display: 'flex',
                                  justifyContent: 'end',
                                  marginLeft: '-0.8rem',
                                }}
                              >
                                <ScaleTooltip content="view details">
                                  <ScaleIconActionFullScreen
                                    style={{ cursor: 'pointer' }}
                                    size={15}
                                    onClick={() => navigate(`${panel.id.toString()}?from=${dateRange.from}&to=${dateRange.to}`)}
                                  />
                                </ScaleTooltip>
                              </div>
                            </div>
                            <MonitoringComponents
                              key={panel.title}
                              panelInfo={panel}
                              dateRange={dateRange}
                            />
                          </div>
                        </ScaleCard>
                      ))}
                    </Grid>
                  ))}
                </Grid>
              ))}
            </ScaleCollapsible>
          </ScaleAccordion>
        ))}
      </Section>
    </div>
  ) : null;
};
export default MonitoringDashboard;
