import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ScaleButton } from '@telekom/scale-components-react';
import { Grid, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import ProductUnassignment from './ProductUnassignment';
import useApiCall from '../../../hooks/useApiCall';
import Auth from '../../../api/auth';

const DeleteUserForm = ({ onSubmit, onCancel, user }) => {
  const intl = useIntl();
  const [userResources, setUserResources] = useState({});
  const getUserResourcesCall = useApiCall(Auth.fetchV1UserResources);

  async function getUserResources() {
    if (!userResources.products?.length) {
      const [data] = await getUserResourcesCall(user.uuid);
      if (data) setUserResources(data);
    }
  }
  useEffect(() => {
    getUserResources();
  }, []);

  return (
    <Grid
      container
      direction="column"
      pb={2}
    >
      <Grid item>
        <Typography>{`${intl.formatMessage({ id: 'userDeleteConfirm' })} ${user.email} ?`}</Typography>
      </Grid>

      {userResources.products?.length ? (
        <Grid item>
          <ProductUnassignment resources={userResources} />
        </Grid>
      ) : null }

      <Grid
        container
        justifyContent="end"
        gap={1}
        pt={2}
      >
        <Grid item>
          <ScaleButton
            variant="primary"
            onClick={onSubmit}
          >
            Delete
          </ScaleButton>
        </Grid>
        <Grid item>
          <ScaleButton
            type="button"
            variant="secondary"
            onClick={onCancel}
          >
            Cancel
          </ScaleButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

DeleteUserForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  user: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
};

export default DeleteUserForm;
