import http from './httpClient';

class Provision {
  static fetchClouds() {
    return http.get(`${import.meta.env.VITE_PROVISION_V0_BASE}/clouds`);
  }

  static fetchCloudDetails(juiceGroup, cloudId) {
    return http.get(
      `${import.meta.env.VITE_PROVISION_V0_BASE}/clouds/${cloudId}`,
      { params: { juiceGroup } },
    );
  }

  static fetchHosts(cloudName) {
    return http.get(
      `${import.meta.env.VITE_PROVISION_V1_BASE}/capacity/${cloudName}`,
    );
  }

  static createOsProject(data) {
    return http.post(`${import.meta.env.VITE_PROVISION_V1_BASE}/os-projects`, data);
  }

  static fetchOsProjectsV1(juiceGroup, juiceOsProjects) {
    return http.get(
      `${import.meta.env.VITE_PROVISION_V1_BASE}/os-projects`,
      { params: { juiceGroup, juiceOsProjects } },
    );
  }

  static fetchOsProjectDetails(juiceGroup, cloudId, osprojectName) {
    return http.get(
      `${import.meta.env.VITE_PROVISION_V1_BASE}/os-projects/details/${juiceGroup}/${cloudId}/${osprojectName}`,
    );
  }

  static deleteOsProject(cloudId, name) {
    return http.delete(
      `${import.meta.env.VITE_PROVISION_V1_BASE}/os-projects/${cloudId}/${name}`,
    );
  }

  static patchOsProject(juiceGroup, cloudId, name, data) {
    return http.patch(`${import.meta.env.VITE_PROVISION_V1_BASE}/os-projects/${juiceGroup}/${cloudId}/${name}`, data);
  }

  static fetchBareMetalClustersV1(juiceGroup) {
    return http.get(
      `${import.meta.env.VITE_PROVISION_V1_BASE}/bare-metal/clusters`,
      { params: { juiceGroup } },
    );
  }

  static updateCloudPassword(juiceGroup, cloudId, password) {
    return http.put(
      `${import.meta.env.VITE_PROVISION_V0_BASE}/osUsers/${cloudId}`,
      { juiceGroup, password },
    );
  }

  static fetchVirtualClouds(data) {
    return http.get(
      `${import.meta.env.VITE_PROVISION_V1_BASE}/virtual-clouds`,
      { params: data },
    );
  }

  static createVirtualClouds(forecasts) {
    return http.post(
      `${import.meta.env.VITE_PROVISION_V1_BASE}/virtual-clouds`,
      { virtualClouds: forecasts },
    );
  }

  static fetchNetworkPolicies(juiceGroup, cloudId) {
    return http.get(
      `${import.meta.env.VITE_PROVISION_V1_BASE}/networks/policies`,
      { params: { juiceGroup, cloudId } },
    );
  }

  static fetchForecasts(juiceGroup, cloudId) {
    return http.get(
      `${import.meta.env.VITE_PROVISION_V1_BASE}/forecast`,
      { params: { juiceGroup, cloudId } },
    );
  }

  static fetchForecastCapacity(juiceGroup) {
    return http.get(
      `${import.meta.env.VITE_PROVISION_V1_BASE}/forecast/aggregates-capacity`,
      { params: { juiceGroup } },
    );
  }

  static fetchOSVirtualResources(juiceGroup, cloudIds) {
    return http.get(
      `${import.meta.env.VITE_PROVISION_V1_BASE}/reports/os/virtual-resources/summary`,
      { params: { juiceGroup, cloudIds } },
    );
  }

  static fetchInstanceDetails(params) {
    return http.get(
      `${import.meta.env.VITE_PROVISION_V1_BASE}/reports/os/virtual-resources/instances/usage`,
      { params },
    );
  }

  static fetchJuiceGroupByName(groupname) {
    return http.get(
      `${import.meta.env.VITE_PROVISION_V1_BASE}/juice-group`,
      { params: { groupname } },
    );
  }

  static fetchJuiceGroupInfo(juiceGroup) {
    return http.get(
      `${import.meta.env.VITE_PROVISION_V1_BASE}/juice-group-info`,
      { params: juiceGroup },
    );
  }

  static addContact(contact) {
    return http.patch(
      `${import.meta.env.VITE_PROVISION_V1_BASE}/juice-group-info/add-contact`,
      contact,
    );
  }

  static editContact(contact) {
    return http.patch(
      `${import.meta.env.VITE_PROVISION_V1_BASE}/juice-group-info/edit-contact`,
      contact,
    );
  }

  static removeContact(contact) {
    return http.patch(
      `${import.meta.env.VITE_PROVISION_V1_BASE}/juice-group-info/remove-contact`,
      contact,
    );
  }

  static setGeoRedundancy(data) {
    return http.patch(
      `${import.meta.env.VITE_PROVISION_V1_BASE}/juice-group-info/set-georedundancy`,
      data,
    );
  }

  static addExternalService(data) {
    return http.patch(
      `${import.meta.env.VITE_PROVISION_V1_BASE}/juice-group-info/add-external-service`,
      data,
    );
  }

  static removeExternalService(data) {
    return http.patch(
      `${import.meta.env.VITE_PROVISION_V1_BASE}/juice-group-info/remove-external-service`,
      data,
    );
  }

  static fetchTags() {
    return http.get(`${import.meta.env.VITE_PROVISION_V1_BASE}/tags`);
  }
}
export default Provision;
