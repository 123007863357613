import { useState } from 'react';
import {
  IconButton as IconButtonMUI,
  InputAdornment as InputAdornmentMUI,
  TextField as TextFieldMUI,
} from '@mui/material';
import PropTypes from 'prop-types';
import {
  ScaleIconActionShowPassword,
  ScaleIconActionHidePassword,
} from '@telekom/scale-components-react';

export const PasswordField = ({
  id,
  name,
  value,
  onChange,
  onBlur,
  label,
  placeholder,
  autoFocus,
  disabled,
  helperText,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => { event.preventDefault(); };

  return (
    <TextFieldMUI
      id={id}
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      label={label}
      placeholder={placeholder}
      autoFocus={autoFocus}
      disabled={disabled}
      helperText={helperText}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        autoComplete: 'new-password',
        endAdornment: (
          <InputAdornmentMUI position="end">
            <IconButtonMUI
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
              tabIndex={-1}
            >
              {showPassword ? <ScaleIconActionHidePassword style={{ padding: 5 }} size={16} />
                : <ScaleIconActionShowPassword style={{ padding: 5 }} size={16} />}
            </IconButtonMUI>
          </InputAdornmentMUI>
        ),
      }}
    />
  );
};

PasswordField.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
};

PasswordField.defaultProps = {
  onBlur: () => {},
  placeholder: '',
  autoFocus: false,
  disabled: false,
  helperText: '',
};
