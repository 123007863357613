import PropTypes from 'prop-types';
import { ScaleAccordion, ScaleCollapsible } from '@telekom/scale-components-react';

const AccordionContainer = ({ title, details, children }) => (
  <ScaleAccordion>
    <ScaleCollapsible expanded>
      <span slot="heading">{title}</span>
      {details}
    </ScaleCollapsible>
    {children}
  </ScaleAccordion>
);

AccordionContainer.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  details: PropTypes.node,
  children: PropTypes.node,
};

AccordionContainer.defaultProps = {
  details: undefined,
  children: undefined,
};

export default AccordionContainer;
