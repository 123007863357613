const roleHierarchy = ['Owner', 'Maintainer', 'Writer', 'API_Importer', 'Reader'];

function getHigherRole(roles, globalRoleId, memberRoleId) {
  const globalRoleName = roles.find((role) => role.id === globalRoleId)?.name;
  const memberRoleName = roles.find((role) => role.id === memberRoleId)?.name;

  if (globalRoleName && memberRoleName) {
    const globalRoleRank = roleHierarchy.indexOf(globalRoleName);
    const memberRoleRank = roleHierarchy.indexOf(memberRoleName);

    if (globalRoleRank <= memberRoleRank) {
      return `Global ${globalRoleName}`;
    }
    return memberRoleName;
  }

  if (globalRoleName) return `Global ${globalRoleName}`;
  if (memberRoleName) return memberRoleName;

  return 'Not assigned';
}

export { getHigherRole };
