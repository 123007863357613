import { useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import { ScaleTag, ScaleModal } from '@telekom/scale-components-react';
import rbacCheck from '../../../../../rbac/rbacCheck';

import EditProductForm from './EditProductForm';
import OverviewTable from '../../../../../components/OverviewTable/OverviewTable';
import Summaries from './Summaries';
import ForecastTable from './ForecastsTable';
import DeleteForm from '../../../../../components/DeleteForm';

import useApiCall from '../../../../../hooks/useApiCall';
import Auth from '../../../../../api/auth';

const Overview = ({
  product,
  activeResources,
  onChange,
}) => {
  const navigate = useNavigate();
  const intl = useIntl();
  const userData = useSelector((state) => state.user);
  const formRef = useRef(null);

  const [summariesModalOpened, setSummariesModalOpened] = useState(false);
  const [modalSize, setModalSize] = useState('small');
  const [forecastModalOpened, setForecastModalOpened] = useState(false);
  const [editModalOpened, setEditModalOpened] = useState(false);
  const [deleteModalOpened, setDeleteModalOpened] = useState(false);

  const deleteProductCall = useApiCall(Auth.deleteV2Product);

  const deleteProduct = async () => {
    const [data, error] = await deleteProductCall(product._id);
    if (error) setDeleteModalOpened(false);
    if (data) navigate(-1);
  };

  const canSeeForecast = rbacCheck(userData.roles, '/forecast:VIEW');
  const canEditProduct = rbacCheck(userData.roles, '/products:PUT');
  const canDeleteProduct = ['compute', 'caasonopenstack', 'caasonbaremetal'].includes(product.type)
    && rbacCheck(userData.roles, '/products:DELETE')
    && !activeResources;

  const cantDeleteProductMsg = {
    caasonopenstack: 'productDeleteDisabledCaasClusters',
    compute: 'productDeleteDisabledOsProjects',
    evaluation: 'productDeleteDisabledOsProjects',
    caasonbaremetal: 'productDeleteDisabledCaasBm',
  };

  const cantDeleteProductMessage = (productType) => {
    if (activeResources) {
      return cantDeleteProductMsg[productType];
    }
    if (productType === 'evaluation') {
      return 'productDeleteUndoable';
    }
    return 'productDeleteDisabled';
  };

  const getProductInfo = () => [
    { title: 'Description', value: product.description },
    { title: 'Type', value: product.type },
    {
      title: 'Organization',
      component: (
        <Link to={`/organizations/${product.organizationId}`}>
          {product.organization?.enterpriseName}
        </Link>
      ),
    },
    {
      title: 'Project',
      component: (
        <Link to={`/projects/${product.project?._id}`}>
          {product.project?.name}
        </Link>
      ),
    },
    { title: 'Juice Group', value: product.details?.juiceGroup },
    { title: 'Approved', value: product.approved ? 'True' : 'False' },
    {
      title: 'Tags',
      component: product.details?.tags?.length && product.details?.tags?.map((tag) => (
        <ScaleTag key={tag} type="strong" style={{ marginLeft: '0.5rem' }}>{tag}</ScaleTag>
      )),
    },
  ];

  return (
    <>
      {forecastModalOpened && (
        <ScaleModal
          opened
          heading={intl.formatMessage({ id: 'productForecasts' }, { name: product.name })}
          size="large"
          onScale-close={() => setForecastModalOpened(false)}
        >
          <ForecastTable
            juiceGroup={product.details.juiceGroup}
            onSubmit={() => {
              setForecastModalOpened(false);
              onChange();
            }}
          />
        </ScaleModal>
      )}
      {summariesModalOpened && (
        <ScaleModal
          opened
          heading="Virtual Cloud Summaries"
          size={modalSize}
          onScale-close={() => setSummariesModalOpened(false)}
        >
          <Summaries juiceGroup={product.details.juiceGroup} setModalSize={setModalSize} />
        </ScaleModal>
      )}
      {editModalOpened && (
        <ScaleModal
          opened
          heading="Edit Product"
          size="small"
          onScale-close={() => {
            setEditModalOpened(false);
          }}
        >
          <EditProductForm
            product={product}
            onSubmit={() => {
              setEditModalOpened(false);
              onChange();
            }}
            onCancel={() => { setEditModalOpened(false); }}
          />
        </ScaleModal>
      )}
      {deleteModalOpened && (
        <ScaleModal
          opened
          heading="Delete Product"
          size="small"
          onScale-before-close={() => setDeleteModalOpened(false)}
          onScale-close={() => {
            formRef.current?.resetForm();
            setDeleteModalOpened(false);
          }}
        >
          <DeleteForm
            fieldName="product name"
            fieldValue={product.name}
            messages={['productDeleteMessage', 'productDeleteConfirmation']}
            deleteFunc={deleteProduct}
            onClose={() => setDeleteModalOpened(false)}
          />
        </ScaleModal>
      )}
      <OverviewTable
        header={product.name}
        rows={getProductInfo()}
        editAction={{
          onClick: () => setEditModalOpened(true),
          disabled: !canEditProduct,
          tooltip: 'editSafescarfProductDisabled',
        }}
        deleteAction={{
          onClick: () => setDeleteModalOpened(true),
          disabled: !canDeleteProduct,
          tooltip: cantDeleteProductMessage(product.type),
        }}
        extraActions={
          product.type !== 'caasonbaremetal'
            ? [
              {
                label: 'Summaries',
                onClick: () => setSummariesModalOpened(true),
              },
              {
                label: 'Forecast',
                onClick: () => setForecastModalOpened(true),
                disabled: !canSeeForecast && product.type?.includes('compute', 'caasonopenstack'),
              },
            ]
            : []
        }
      />
    </>
  );
};

Overview.propTypes = {
  product: PropTypes.shape({
    _id: PropTypes.string,
    type: PropTypes.string,
    description: PropTypes.string,
    approved: PropTypes.bool,
    details: PropTypes.shape({
      juiceGroup: PropTypes.string,
      tags: PropTypes.arrayOf(PropTypes.string),
    }),
    project: PropTypes.shape({
      name: PropTypes.string,
      _id: PropTypes.string,
    }),
    organization: PropTypes.shape({
      enterpriseName: PropTypes.string,
    }),
    organizationId: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  activeResources: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Overview;
