import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const http = axios.create();

http.interceptors.request.use((config) => {
  if (config.method !== 'get') {
    return {
      ...config,
      data: {
        ...config.data,
        transactionId: uuidv4(),
      },
    };
  }
  return config;
}, (error) => Promise.reject(error));

http.interceptors.response.use((response) => response, (error) => {
  if (error.response) {
    const { status, data, statusText } = error.response;
    const method = (error.config?.method || 'unknown').toUpperCase();

    const filteredError = {
      status,
      message: data?.message || data || statusText || 'Unknown error occurred',
      method,
      ...(data?.cause && { cause: data.cause }),
    };

    return Promise.reject(filteredError);
  }
  return Promise.reject(error);
});

export default http;
