import { Container } from '@mui/material';
import useMarkdownFile from '../../hooks/useMarkdownFile';
import contactSecurity from '../../markdowns/contactSecurity.md';

const ContactSecurity = () => {
  const { markdownContent } = useMarkdownFile(contactSecurity);

  return (
    <Container maxWidth="md">
      {markdownContent}
    </Container>
  );
};

export default ContactSecurity;
