import { useEffect, useState } from 'react';
import useApiCall from './useApiCall';

const usePaginatedApiCall = (endpoint, offset = 0, limit = 10, search = '') => {
  const apiCall = useApiCall(endpoint);
  const [totalItems, setTotalItems] = useState(0);
  const [cache, setCache] = useState({});
  const [data, setData] = useState(null);

  // Returns a unqiue key for cache based of the query
  const queryToCacheKey = (query) => {
    const queryEntries = Object.entries(query).reduce((acc, [key, value], index) => {
      if (index === 0) return acc.concat(`${key}: ${value}`);
      return acc.concat(`, ${key}: ${value}`);
    }, '');
    return `${endpoint.name}(${queryEntries})`;
  };

  const fetchData = async (query) => {
    const cacheKey = queryToCacheKey(query);
    const cachedData = cache[cacheKey];
    if (cachedData) {
      setData(cachedData);
      setTotalItems(cachedData.metadata.totalItems);
      return;
    }

    const [response] = await apiCall(query);
    if (response?.metadata) {
      setTotalItems(response.metadata.totalItems);
      setCache({ ...cache, [cacheKey]: response });
      setData(response);
      return;
    }
    setData(null);
    setTotalItems(0);
  };

  useEffect(() => {
    fetchData({ offset, limit, search });
  }, [offset, limit, search]);

  return [data, totalItems];
};

export default usePaginatedApiCall;
