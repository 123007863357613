import { ofType, combineEpics } from 'redux-observable';
import { mergeMap, catchError } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { handleError } from '../../errorHandler';
import Safescarf from '../../../api/safescarf';
import {
  FETCH_MACHINE_ACCOUNTS_STARTED,
  fetchMachineAccountsFulfilled,
  createMachineAccountFulfilled,
  CREATE_MACHINE_ACCOUNT_STARTED,
} from './actions';

const fetchMachineAccountsEpic = (action$) => action$.pipe(
  ofType(FETCH_MACHINE_ACCOUNTS_STARTED),
  mergeMap(() => from(Safescarf.fetchMachineAccounts()).pipe(
    mergeMap((response) => of(
      fetchMachineAccountsFulfilled(response.data),
    )),
    catchError(handleError),
  )),
);

const createMachineAccountEpic = (action$) => action$.pipe(
  ofType(CREATE_MACHINE_ACCOUNT_STARTED),
  mergeMap(() => from(Safescarf.createMachineAccount()).pipe(
    mergeMap((response) => of(
      createMachineAccountFulfilled(response.data),
    )),
    catchError(handleError),
  )),
);

export default combineEpics(
  createMachineAccountEpic,
  fetchMachineAccountsEpic,
);
