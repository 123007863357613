import {
  Autocomplete as AutocompleteMUI,
  TextField as TextFieldMUI,
} from '@mui/material';
import PropTypes from 'prop-types';

const optionShape = PropTypes.shape({
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
});

export const Autocomplete = ({
  id,
  name,
  value,
  onChange,
  onBlur,
  label,
  autoFocus,
  disabled,
  helperText,
  onOpen,
  options,
}) => (
  <AutocompleteMUI
    multiple
    autoComplete
    autoHighlight
    id={id}
    name={name}
    value={value}
    onChange={onChange}
    onBlur={onBlur}
    label={label}
    autoFocus={autoFocus}
    disabled={disabled}
    onOpen={onOpen}
    options={options}
    isOptionEqualToValue={
      (option, optionValue) => option.value === optionValue || option.value === optionValue.value
    }
    renderInput={(params) => (
      <TextFieldMUI
        {...params}
        id={name}
        label={label}
        helperText={helperText}
      />
    )}
  />
);

Autocomplete.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(optionShape).isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  label: PropTypes.string.isRequired,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
  onOpen: PropTypes.func,
  options: PropTypes.arrayOf(optionShape).isRequired,
};

Autocomplete.defaultProps = {
  onBlur: () => {},
  autoFocus: false,
  disabled: false,
  helperText: '',
  onOpen: () => {},
};
