function filterDataUpdates(oldData, newData) {
  const keys = Object.keys(newData);
  const updatedKeys = keys.filter((key) => (
    JSON.stringify(oldData[key]) !== JSON.stringify(newData[key])
  ));
  const updates = updatedKeys.reduce((acc, value) => {
    acc[value] = newData[value];
    return acc;
  }, {});
  return updates;
}

export {
  filterDataUpdates,
};
