import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Grid } from '@mui/material';
import { BehaviorSubject, from, of } from 'rxjs';
import {
  mergeMap, debounceTime, filter, catchError,
} from 'rxjs/operators';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import {
  ScaleButton,
  ScaleTextField,
  ScaleCheckbox,
  ScaleLoadingSpinner, ScaleModal,
} from '@telekom/scale-components-react';
import * as Sentry from '@sentry/react';

import OverviewTable from '../../../components/OverviewTable/OverviewTable';
import Section from '../../../components/Section';

import Safescarf from '../../../api/safescarf';
import useApiCall from '../../../hooks/useApiCall';
import Success from '../../../components/Success/index';

const fqdnRegex = /^(?=^.{1,254}$)(^([a-zA-Z0-9_-]{1,63}\.?)+([a-zA-Z]{2,})$)/;
const fqdnSubject = new BehaviorSubject('');
const fqdnObservable = fqdnSubject.pipe(
  debounceTime(3000),
  filter((fqdn) => fqdn !== ''),
  mergeMap((fqdn) => from(Safescarf.fetchFQDNAvailability(fqdn)).pipe(
    catchError((error) => {
      Sentry.withScope((scope) => {
        scope.setContext('FQDN Check', {
          fqdn,
          errorMessage: error.message,
          status: error.response?.status,
          statusText: error.response?.statusText,
        });
        Sentry.captureException(error);
      });
      return of({ data: { result: 'SAFESCARF_ERROR' } });
    }),
  )),
);

const OrderForm = () => {
  const navigate = useNavigate();
  const createSafescarfInstance = useApiCall(Safescarf.createSafescarfInstance);
  const [safescarfCreated, setSafescarfCreated] = useState(false);

  const OrderValidationSchema = object({
    fqdn: string()
      .required()
      .matches(fqdnRegex, 'Invalid hostname'),
  });

  const formik = useFormik({
    initialValues: { hostname: '', fqdn: '', publicIP: false },
    initialErrors: { hostname: '', fqdn: '', publicIP: '' },
    validationSchema: OrderValidationSchema,
    onSubmit: async ({ hostname, publicIP }) => {
      const [data] = await createSafescarfInstance({ hostname, publicIP });
      if (data !== null) {
        setSafescarfCreated(true);
      }
    },
  });

  useEffect(() => {
    const subscription = fqdnObservable.subscribe((data) => {
      const fqdnAvailability = data.data?.result;
      if (fqdnAvailability !== 'FQDN_AVAILABLE') {
        if (fqdnAvailability === 'SAFESCARF_ERROR') {
          formik.setFieldError('fqdn', 'Safescarf is currently unavailable');
        } else {
          formik.setFieldError('fqdn', 'hostname is unavailable');
        }
      }
      formik.setStatus();
    });
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    if (formik.isValid) {
      formik.setStatus('checking fqdn');
      fqdnSubject.next(formik.values.fqdn);
    }
  }, [formik.isValid, formik.values.hostname]);

  return (
    <Container
      maxWidth="md"
      style={{ padding: '2rem 0' }}
    >
      { safescarfCreated && (
        <ScaleModal
          opened
          size="small"
          heading="SafeSCARF instance ordered"
          onScale-close={() => {
            navigate('/products/safescarf');
          }}
        >
          <Success
            messageId="safescarfOrderCreated"
            onSubmit={() => {
              navigate('/products/safescarf');
            }}
          />
        </ScaleModal>
      )}
      <Section header="SafeSCARF instance order form">
        <form>
          <Grid
            container
            direction="column"
            alignItems="stretch"
            rowGap={2}
          >
            <Grid item sm={11}>
              <ScaleTextField
                minLength={1}
                maxLength={254}
                id="hostname"
                type="text"
                label="Hostname"
                placeholder="your instance name"
                helperText={formik.errors.fqdn}
                inputAutofocus
                onScale-change={(event) => {
                  if (formik.values.hostname !== event.detail.value) {
                    formik.setFieldError('fqdn', 'just invalidating the form');
                  }
                  formik.setFieldValue('fqdn', `${event.detail.value}.safescarf.in.pan-net.eu`);
                  formik.handleChange(event);
                }}
              />
            </Grid>
            <Grid item sm={1} style={{ textAlign: 'end' }}>
              {formik.status && <ScaleLoadingSpinner />}
            </Grid>
            <Grid item sm={12}>
              <ScaleCheckbox
                id="publicIP"
                label="Public IP"
                inputId="publicIp"
                onScale-change={(event) => {
                  formik.handleChange(event);
                  formik.setFieldValue('publicIP', !formik.values.publicIP);
                }}
              />
            </Grid>
            <Grid item sm={12}>
              <OverviewTable rows={[
                {
                  title: 'SafeSCARF instance',
                  value: `${formik.values.hostname}${formik.values.publicIP ? '.safescarf.pan-net.cloud' : '.safescarf.in.pan-net.eu'}`,
                },
              ]}
              />
            </Grid>
          </Grid>
        </form>
      </Section>
      <Grid item sm={10}>
        <ScaleButton
          type="submit"
          disabled={formik.status || !formik.isValid || formik.isSubmitting}
          onClick={formik.handleSubmit}
        >
          Submit
        </ScaleButton>
      </Grid>
    </Container>
  );
};

export default OrderForm;
