import { TextField as TextFieldMUI } from '@mui/material';
import PropTypes from 'prop-types';

export const MultilineTextField = ({
  id,
  name,
  value,
  onChange,
  onBlur,
  label,
  placeholder,
  autoFocus,
  disabled,
  helperText,
  minRows,
  maxRows,
}) => (
  <TextFieldMUI
    multiline
    type="text"
    id={id}
    name={name}
    value={value}
    onChange={onChange}
    onBlur={onBlur}
    label={label}
    placeholder={placeholder}
    autoFocus={autoFocus}
    disabled={disabled}
    helperText={helperText}
    minRows={minRows}
    maxRows={maxRows}
  />
);

MultilineTextField.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
  minRows: PropTypes.number,
  maxRows: PropTypes.number,
};

MultilineTextField.defaultProps = {
  onBlur: () => {},
  placeholder: '',
  autoFocus: false,
  disabled: false,
  helperText: '',
  minRows: 3,
  maxRows: 4,
};
