const initialState = {
  loading: false,
  deletionStatus: null,
  error: null,
};

const pendingActionTypes = [];

const statusReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'DELETE_PROJECT_STARTED':
    case 'DELETE_SAFESCARF_PRODUCT_STARTED':
      pendingActionTypes.push(action.type);
      return { ...state, loading: pendingActionTypes.length > 0, deletionStatus: 'pending' };

    case 'DELETE_PROJECT_FULFILLED':
    case 'DELETE_SAFESCARF_PRODUCT_FULFILLED':
      pendingActionTypes.pop();
      return { ...state, loading: pendingActionTypes.length > 0, deletionStatus: 'success' };

    case 'RESET_DELETION_STATUS':
      return { ...state, deletionStatus: null };

    case 'SET_ERROR':
      pendingActionTypes.push(action.type);
      return { ...state, error: action.payload };

    case 'RESET_ERROR':
      pendingActionTypes.pop();
      return { ...state, error: null };

    default:
      if (action.type.includes('STARTED')) {
        pendingActionTypes.push(action.type);
        return { ...state, loading: pendingActionTypes.length > 0 };
      }
      if (action.type.includes('FULFILLED')) {
        pendingActionTypes.pop();
        return { ...state, loading: pendingActionTypes.length > 0 };
      }
      if (action.type.includes('FAILED')) {
        pendingActionTypes.pop();
        return { ...state, loading: pendingActionTypes.length > 0, deletionStatus: 'failed' };
      }
      return state;
  }
};

export { statusReducer };
