import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { ScalePagination, ScaleSwitch, ScaleTable } from '@telekom/scale-components-react';
import { useIntl } from 'react-intl';
import Header from './Header';

const NewTable = ({
  isPaginated,
  header,
  fields,
  rows,
  itemsPerPage,
  currentPage,
  setCurrentPage,
  filter,
  setFilter,
  totalItems,
  createAction,
  exportData,
}) => {
  const intl = useIntl();

  const getStartItemFromPage = (page) => (page - 1) * itemsPerPage;
  const getPageFromStartItem = (startItem) => Math.floor(startItem / itemsPerPage) + 1;

  const filteredRows = useMemo(() => {
    if (isPaginated) return rows;

    return rows.filter((row) => row.data.some((item) => {
      const itemValue = String(item.value || '').toLowerCase();
      return itemValue.includes(filter || '');
    }));
  }, [rows, filter]);

  const paginatedRows = useMemo(() => {
    if (isPaginated) return filteredRows;

    const startItem = getStartItemFromPage(currentPage);
    return filteredRows.slice(startItem, startItem + itemsPerPage);
  }, [filteredRows, currentPage]);

  const renderRows = useMemo(() => (
    paginatedRows.length > 0
      ? paginatedRows.map((row) => (
        <tr
          key={row.key}
          style={{ cursor: row.onRowClick ? 'pointer' : 'default' }}
          onClick={() => row.onRowClick(row.key)}
        >
          {row.data.map((cell) => (
            <td key={`${row.key}-${cell.field}`}>
              {cell.type === 'switch' ? (
                <ScaleSwitch
                  {...cell.props}
                  color="primary"
                  size="small"
                />
              ) : cell.value}
            </td>
          ))}
        </tr>
      ))
      : (
        <tr>
          <td
            colSpan={fields.length}
            style={{ textAlign: 'center', padding: '3rem' }}
          >
            {intl.formatMessage({ id: 'EmptyTable' })}
          </td>
        </tr>
      )
  ), [paginatedRows, fields, intl]);

  return (
    <>
      <Header
        header={header}
        setFilter={setFilter}
        setCurrentPage={setCurrentPage}
        createAction={createAction}
        exportData={exportData}
      />
      <ScaleTable>
        <table>
          <thead>
            <tr>
              {fields.map((field) => (
                <th key={field.id}>{field.title}</th>
              ))}
            </tr>
          </thead>
          <tbody>{renderRows}</tbody>
        </table>
      </ScaleTable>
      <Grid
        container
        justifyContent="center"
        mt={2}
      >
        <ScalePagination
          hideBorder
          pageSize={itemsPerPage}
          startElement={getStartItemFromPage(currentPage)}
          totalElements={isPaginated ? totalItems : filteredRows.length}
          onScale-pagination={(e) => {
            setCurrentPage(getPageFromStartItem(e.detail.startElement));
          }}
        />
      </Grid>
    </>
  );
};

NewTable.propTypes = {
  isPaginated: PropTypes.bool,
  header: PropTypes.string,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.object,
        ]),
      ),
      onRowClick: PropTypes.func,
    }),
  ),
  itemsPerPage: PropTypes.number,
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  filter: PropTypes.string.isRequired,
  setFilter: PropTypes.func.isRequired,
  totalItems: PropTypes.number.isRequired,
  createAction: PropTypes.shape({
    tooltip: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
  }),
  exportData: PropTypes.shape({
    file: PropTypes.instanceOf(Blob),
    fileName: PropTypes.string,
    name: PropTypes.string,
  }),
};

NewTable.defaultProps = {
  isPaginated: false,
  header: '',
  rows: [],
  itemsPerPage: 10,
  createAction: null,
  exportData: null,
};

export default NewTable;
