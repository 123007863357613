import { useState, useEffect } from 'react';
import Markdown from 'markdown-to-jsx';
import { markdownOptions } from '../helpers/markdownOptions';

const useMarkdownFile = (file) => {
  const [markdownContent, setMarkdownContent] = useState(null);

  useEffect(() => {
    const fetchFileContent = async () => {
      const response = await fetch(file);
      let fileContent = await response.text();

      fileContent = fileContent.replace(
        '{{CookieDeclaration}}',
        '<div id="cookiebot-script"></div>',
      );

      const renderedMarkdown = (
        <Markdown options={markdownOptions}>{fileContent}</Markdown>
      );

      setMarkdownContent(renderedMarkdown);
    };

    fetchFileContent();
  }, [file]);

  useEffect(() => {
    if (!markdownContent) return;

    const script = document.createElement('script');
    script.id = 'CookieDeclaration';
    script.src = 'https://consent.cookiebot.com/5a4f8371-6fd5-4978-a070-b396f956eb13/cd.js';
    script.async = true;

    const placeholder = document.getElementById('cookiebot-script');
    if (placeholder) {
      placeholder.appendChild(script);
    }

    // eslint-disable-next-line consistent-return
    return () => {
      if (placeholder) placeholder.removeChild(script);
    };
  }, [markdownContent]);

  return { markdownContent };
};

export default useMarkdownFile;
