export const FETCH_USER_DATA_STARTED = 'FETCH_USER_DATA_STARTED';
export const FETCH_USER_DATA_FULFILLED = 'FETCH_USER_DATA_FULFILLED';

export const fetchUserData = (params) => (
  { type: FETCH_USER_DATA_STARTED, payload: params }
);

export const fetchUserDataFulfilled = (payload) => (
  { type: FETCH_USER_DATA_FULFILLED, payload }
);
