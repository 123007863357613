import { useSelector } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import { ScaleDivider } from '@telekom/scale-components-react';

import CloudResetPassword from './CloudPasswordReset';
import PasswordChange from './UserPasswordChange';
import ApiKeyForm from './ApiKeyForm';
import rbacCheck from '../../../rbac/rbacCheck';

const Settings = () => {
  const userData = useSelector((state) => state.user);
  const canSeeChangeCloudPwd = rbacCheck(userData.roles, '/settings/cloud-reset-password');

  return (
    <Grid
      container
      justifyContent="space-between"
      rowGap={2}
    >
      <Grid item>
        <Typography variant="h4" color="primary">API Key</Typography>
        <Typography variant="h6">Generate Api Key:</Typography>
        <Typography>Issue an API-key to use in your automation.</Typography>
      </Grid>
      <Grid item>
        <ApiKeyForm />
      </Grid>
      <ScaleDivider />
      <Grid item>
        <Typography variant="h4" color="primary">User Password</Typography>
        <Typography variant="h6">Change your password:</Typography>
      </Grid>
      <Grid item>
        <PasswordChange />
      </Grid>
      <ScaleDivider />
      {canSeeChangeCloudPwd && (
        <>
          <Grid item>
            <Typography variant="h4" color="primary">Cloud Password</Typography>
            <Typography variant="h6">Change a cloud password:</Typography>
          </Grid>
          <Grid item>
            <CloudResetPassword />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default Settings;
