import { TextField as TextFieldMUI } from '@mui/material';
import PropTypes from 'prop-types';

export const DateField = ({
  id,
  name,
  value,
  onChange,
  onBlur,
  label,
  autoFocus,
  disabled,
  helperText,
  inputProps,
  InputLabelProps,
}) => (
  <TextFieldMUI
    type="date"
    id={id}
    name={name}
    value={value}
    onChange={onChange}
    onBlur={onBlur}
    label={label}
    autoFocus={autoFocus}
    disabled={disabled}
    helperText={helperText}
    inputProps={inputProps}
    InputLabelProps={InputLabelProps}
  />
);

DateField.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  label: PropTypes.string.isRequired,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
  inputProps: PropTypes.shape({
    min: PropTypes.string,
    max: PropTypes.string,
    step: PropTypes.number,
  }),
  InputLabelProps: PropTypes.shape({
    shrink: PropTypes.bool,
  }),
};

DateField.defaultProps = {
  onBlur: () => {},
  autoFocus: false,
  disabled: false,
  helperText: '',
  inputProps: {},
  InputLabelProps: {},
};
