import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { ScaleModal } from '@telekom/scale-components-react';
import rbacCheck from '../../../rbac/rbacCheck';
import CreateProjectForm from './CreateProjectForm';
import Table from '../../../components/Table';

import useApiCall from '../../../hooks/useApiCall';
import Auth from '../../../api/auth';


const Projects = () => {
  const { organizationId } = useParams();
  const navigate = useNavigate();
  const intl = useIntl();
  const userData = useSelector((state) => state.user);
  const [createModalOpened, setCreateModalOpened] = useState(false);
  const canCreateProject = rbacCheck(userData.roles, '/projects:POST');
  const [projects, setProjects] = useState([]);

  const createProjectCall = useApiCall(Auth.createV2Project);
  const getProjectsCall = useApiCall(Auth.fetchV2Projects);

  async function getProjects() {
    const [data] = await getProjectsCall({ organizationId });
    if (data) setProjects(data);
  }

  const createProject = async (formValues) => {
    const [data, error] = await createProjectCall({
      ...formValues,
      organizationId,
    });
    if (error) setCreateModalOpened(false);
    if (data) {
      getProjects();
      setCreateModalOpened(false);
    }
  };

  useEffect(() => {
    getProjects();
  }, []);

  const mapProjectsToRows = projects.map((project) => ({
    key: project._id,
    onRowClick: () => navigate(`/projects/${project._id}`),
    data: [
      {
        field: 'Name',
        value: project.name,
      },
      {
        field: 'Description',
        value: project.description,
      },
      {
        field: 'BusinessUnit',
        value: project.businessUnit?.name,
      },
      {
        field: 'TechnicalLead',
        value: project.technicalLead?.email,
      },
    ],
  }));

  return (
    <>
      {createModalOpened && (
        <ScaleModal
          opened
          size="small"
          heading="Create Project"
          onScale-close={() => setCreateModalOpened(false)}
        >
          <CreateProjectForm
            onSubmit={createProject}
            onCancel={() => setCreateModalOpened(false)}
          />
        </ScaleModal>
      )}
      <Table
        header="Projects"
        fields={[
          {
            title: 'Name',
            id: 'name',
          },
          {
            title: 'Description',
            id: 'description',
          },
          {
            title: 'BusinessUnit',
            id: 'businessUnit',
          },
          {
            title: 'TechnicalLead',
            id: 'technicalLead',
          },
        ]}
        rows={mapProjectsToRows}
        createAction={{
          disabled: !canCreateProject,
          onClick: () => setCreateModalOpened(true),
          tooltip: !canCreateProject ? intl.formatMessage({ id: 'projectCreationPermissionErr' }) : '',
        }}
      />
    </>
  );
};

export default Projects;
