import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import Provision from '../../api/provision';
import JuiceGroupContacts from './JuiceGroupContacts';
import JuiceGroupInfo from './JuiceGroupInfo';
import useApiCall from '../../hooks/useApiCall';
import Table from '../../components/Table';

const VMFields = [
  { id: 'instance', title: 'VM [instance]' },
  { id: 'host', title: 'Host [compute]' },
  { id: 'status', title: 'Status' },
  { id: 'cpu', title: 'CPU' },
  { id: 'ram', title: 'RAM (GB)' },
  { id: 'disk', title: 'DISK (GB)' },
  { id: 'flavor', title: 'Flavor Name' },
  { id: 'createdAt', title: 'Created At' },
  { id: 'deletedAt', title: 'Deleted At' },
];

const TenantDetails = () => {
  const { juiceGroup, cloudId, tenant } = useParams();
  const [virtualMachines, setVirtualMachines] = useState([]);
  const fetchInstanceDetails = useApiCall(Provision.fetchInstanceDetails);

  async function generateReport() {
    const [data] = await fetchInstanceDetails({ juice_group: juiceGroup, cloudIds: [cloudId] });
    if (data[0]) {
      const cloudData = data[0].instances.map((instance) => ({
        key: instance.id,
        data: [
          { field: 'instance', value: instance.name },
          { field: 'host', value: instance.host_name },
          { field: 'status', value: instance.status },
          { field: 'cpu', value: instance.cpus_total },
          { field: 'ram', value: instance.ram_gigabytes },
          { field: 'disk', value: instance.disk_gigabytes },
          { field: 'flavor', value: instance.flavor_name },
          { field: 'createdAt', value: instance.created_at ? new Date(instance.created_at).toISOString().slice(0, 16) : 'N/A' },
          { field: 'deletedAt', value: instance.deleted_at ? new Date(instance.deleted_at).toISOString().slice(0, 16) : 'N/A' },
        ],
      }
      ));
      setVirtualMachines(cloudData);
    }
  }

  useEffect(() => {
    generateReport();
  }, []);

  return (
    <Grid
      container
      direction="column"
      rowSpacing={10}
    >
      <Grid item style={{ maxWidth: '100%' }}>
        <JuiceGroupInfo tenant={tenant} />
      </Grid>
      <Grid item style={{ maxWidth: '100%' }}>
        <Table
          header="Virtual Machines"
          fields={VMFields}
          rows={virtualMachines}
        />
      </Grid>
      <Grid item style={{ maxWidth: '100%' }}>
        <JuiceGroupContacts />
      </Grid>
    </Grid>
  );
};

export default TenantDetails;
