import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import OverviewTable from '../../components/OverviewTable/OverviewTable';

const AccountOverview = () => {
  const {
    email,
    beautifiedRole,
    uuid,
    organization,
  } = useSelector((state) => state.user);


  const rows = [
    {
      title: 'Organization',
      value: organization.enterpriseName,
      component: (
        <Link to={`/organizations/${organization._id}`}>
          {organization.enterpriseName}
        </Link>
      ),
    },
    { title: 'Role', value: beautifiedRole },
    { title: 'UUID', value: uuid },
  ];

  return (
    <OverviewTable header={email} rows={rows} />
  );
};

export default AccountOverview;
