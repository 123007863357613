import { useLocation } from 'react-router-dom';
import NewPassword from './NewPassword';
import TokenExpired from '../Error/TokenExpired';

const UserPasswordUpdate = () => {
  const { search } = useLocation();

  const tokenExpirationTime = search.split('?t=').pop(-1);

  return tokenExpirationTime > Date.now()
    ? <NewPassword />
    : <TokenExpired />;
};

export default UserPasswordUpdate;
