import {
  FETCH_USERS_FULFILLED,
} from './actions';

const usersReducer = (state = [], action) => {
  switch (action.type) {
    case FETCH_USERS_FULFILLED:
      return action.payload;
    default:
      return state;
  }
};

export { usersReducer };
