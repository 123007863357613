import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import {
  Formik,
  Form,
} from 'formik';
import {
  ScaleButton,
  ScaleCheckbox,
  ScaleTag,
} from '@telekom/scale-components-react';
import {
  Grid,
  Typography,
} from '@mui/material';
import { FormikTextField, FormikSelect } from '../../../../../components/Formik/index';
import Provision from '../../../../../api/provision';
import useApiCall from '../../../../../hooks/useApiCall';
import rbacCheck from '../../../../../rbac/rbacCheck';
import Auth from '../../../../../api/auth';

const Tags = ({ tags, setTags }) => {
  const fetchTags = useApiCall(Provision.fetchTags, null, null);
  const [juiceTags, setJuiceTags] = useState([]);

  useEffect(() => {
    async function getTags() {
      const [data] = await fetchTags();
      if (data) setJuiceTags(data);
    }
    getTags();
  }, []);

  return (
    <Grid
      container
      direction="column"
    >
      <Grid item>
        <Typography align="left">
          Juice Group Tags:
        </Typography>
      </Grid>
      <Grid
        item
        display="grid"
        gridTemplateColumns="50% 50%"
      >
        {juiceTags.map((tag) => (
          <ScaleCheckbox
            key={tag}
            style={{ marginTop: '0.5rem' }}
            checked={tags.includes(tag)}
            onScale-change={(event) => {
              if (event.target.checked) {
                setTags([...tags, tag]);
              } else {
                setTags(tags.filter((t) => t !== tag));
              }
            }}
          >
            <ScaleTag type="strong">{tag}</ScaleTag>
          </ScaleCheckbox>
        ))}
      </Grid>
    </Grid>
  );
};

Tags.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  setTags: PropTypes.func.isRequired,
};

const EditProductForm = ({ product, onSubmit, onCancel }) => {
  const updateProductCall = useApiCall(Auth.updateV2Product);
  const getProjectsCall = useApiCall(Auth.fetchV2Projects);

  const [projects, setProjects] = useState([]);
  const [tags, setTags] = useState(product?.details?.tags || []);
  const userData = useSelector((state) => state.user);
  const canEditTags = rbacCheck(userData.roles, '/products:PUT:TAGS');

  useEffect(() => {
    async function getProjects() {
      const [data] = await getProjectsCall({ organizationId: product.organizationId });
      if (data) setProjects(data);
    }
    getProjects();
  }, []);

  const updateProduct = async (payload) => {
    const [data] = await updateProductCall({
      ...payload,
      tags: canEditTags ? tags : undefined,
      id: product?._id,
    });
    if (data) onSubmit();
  };

  const initialValues = {
    description: product?.description || '',
    projectId: product?.projectId || '',
  };

  const validationSchema = Yup.object().shape({
    description: Yup.string().required('Required'),
  });

  const submit = (formValues) => {
    updateProduct(formValues);
  };

  const projectsItems = projects.map((project) => ({
    value: project._id,
    label: project.name,
  }));

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={submit}
    >
      {({ isValid, dirty }) => (
        <Form>
          <Grid
            container
            direction="column"
            pb={2}
          >
            <Grid item>
              <FormikTextField
                name="description"
                label="Description"
              />
            </Grid>
            {projects && (
              <FormikSelect
                name="projectId"
                label="Project"
                menuItems={projectsItems}
              />
            )}
            {canEditTags && <Tags tags={tags} setTags={setTags} />}
            <Grid
              container
              justifyContent="end"
              gap={1}
            >
              <Grid item>
                <ScaleButton
                  variant="primary"
                  disabled={!isValid || (!dirty && !canEditTags)}
                >
                  Save
                </ScaleButton>
              </Grid>
              <Grid item>
                <ScaleButton
                  name="cancel"
                  type="button"
                  variant="secondary"
                  onClick={() => {
                    setTags([]);
                    onCancel();
                  }}
                >
                  Cancel
                </ScaleButton>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

EditProductForm.propTypes = {
  formRef: PropTypes.shape({
    current: PropTypes.shape({ resetForm: PropTypes.func.isRequired }),
  }).isRequired,
  product: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.object,
    ]),
  ).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default EditProductForm;
