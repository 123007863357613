import http from './httpClient';

class Caas {
  static getClouds() {
    return http.get(`${import.meta.env.VITE_CAAS_V0_BASE}/clouds`);
  }

  static getCloudDetails(cloudId) {
    return http.get(`${import.meta.env.VITE_CAAS_V0_BASE}/clouds/${cloudId}`);
  }

  static createClusterRequest(data) {
    return http.post(`${import.meta.env.VITE_CAAS_V0_BASE}/cluster-requests`, data);
  }

  static getClusters(params) {
    return http.get(`${import.meta.env.VITE_CAAS_V0_BASE}/clusters`, { params });
  }

  static getClusterDetails(clusterId) {
    return http.get(`${import.meta.env.VITE_CAAS_V0_BASE}/clusters/${clusterId}`);
  }

  static getClusterKubeConfig(clusterId) {
    return http.get(`${import.meta.env.VITE_CAAS_V0_BASE}/clusters/${clusterId}/kubeconfig`);
  }
}

export default Caas;
