import http from './httpClient';

class DocumentationMS {
  static getChildrenPages(pageId) {
    return http.get(`${import.meta.env.VITE_DOCUMENTATION_V0_BASE}/confluence/content/pages/${pageId}/children`);
  }

  static getPageContent(pageTitle) {
    return http.get(`${import.meta.env.VITE_DOCUMENTATION_V0_BASE}/confluence/content/pages/${pageTitle}`);
  }
}

export default DocumentationMS;
