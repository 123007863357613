import { Link } from 'react-router-dom';
import { ScaleIconAlertImprintDataprivacy, ScaleTelekomFooterContent } from '@telekom/scale-components-react';
import { Typography } from '@mui/material';

const FooterContent = () => (
  <ScaleTelekomFooterContent>
    <span slot="notice">
      <Typography>© DT Europe Holding GmbH</Typography>
    </span>
    <ul slot="navigation">
      <li>
        <Link to="/contact-us">
          <Typography>Contact</Typography>
        </Link>
      </li>
      <li>
        <Link to="/gtc">
          <Typography>Terms and conditions</Typography>
        </Link>
      </li>
      <li>
        <Link to="/about-us">
          <Typography>Imprint</Typography>
        </Link>
      </li>
      <li>
        <Link to="/data-privacy">
          <ScaleIconAlertImprintDataprivacy />
          <Typography>&nbsp;Data Privacy</Typography>
        </Link>
      </li>
    </ul>
  </ScaleTelekomFooterContent>
);

export default FooterContent;
