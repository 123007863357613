import { Container } from '@mui/material';
import useMarkdownFile from '../../hooks/useMarkdownFile';
import dataPrivacy from '../../markdowns/dataPrivacy.md';

const DataPrivacy = () => {
  const { markdownContent } = useMarkdownFile(dataPrivacy);

  return (
    <Container maxWidth="lg">
      {markdownContent}
    </Container>
  );
};

export default DataPrivacy;
