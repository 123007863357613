import { ofType } from 'redux-observable';
import { mergeMap, catchError } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { handleError } from '../../errorHandler';
import Safescarf from '../../../api/safescarf';
import {
  FETCH_ROLES_STARTED,
  fetchRolesFulfilled,
} from './actions';

const fetchRolesEpic = (action$) => action$.pipe(
  ofType(FETCH_ROLES_STARTED),
  mergeMap(() => from(Safescarf.fetchSafescarfRoles()).pipe(
    mergeMap((response) => of(
      fetchRolesFulfilled(response.data),
    )),
    catchError(handleError),
  )),
);

export default fetchRolesEpic;
