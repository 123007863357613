import { Grid } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useOutletContext, useParams } from 'react-router-dom';
import { ScaleModal } from '@telekom/scale-components-react';

import MarketplaceItems from './MarketplaceItems';
import OverviewTable from '../../../components/OverviewTable/OverviewTable';
import EditOrganizationForm from './EditOrganizationForm';
import rbacCheck from '../../../rbac/rbacCheck';
import useApiCall from '../../../hooks/useApiCall';
import Auth from '../../../api/auth';


const Overview = () => {
  const { organizationId } = useParams();
  const user = useSelector((state) => state.user);

  const canSeeMarketplace = rbacCheck(user.roles, '/marketplace:VIEW');
  const canEditOrg = rbacCheck(user.roles, '/organization:PUT');

  const [editModalOpened, setEditModalOpened] = useState(false);
  const [organization, setOrganization] = useOutletContext();

  const updateOrganizationCall = useApiCall(Auth.updateV1Organization);

  const updateOrganization = async (formValues) => {
    const [data, error] = await updateOrganizationCall({
      ...formValues,
      accountOwnerId: formValues.accountOwnerId || null,
      _id: organizationId,
    });
    if (error) setEditModalOpened(false);
    if (data) {
      setOrganization(data);
      setEditModalOpened(false);
    }
  };

  const rows = [
    { title: 'CRM ID', value: organization.CRMId },
    {
      title: 'Account owner',
      value: organization.accountOwner?.email,
      component: (
        <Link to={`/users/${organization.accountOwner?.uuid}`}>
          {organization.accountOwner?.email}
        </Link>
      ),
    },
  ];

  return (
    <>
      {editModalOpened && (
        <ScaleModal
          opened
          size="small"
          heading="Edit Organization"
          onScale-close={() => setEditModalOpened(false)}
        >
          <EditOrganizationForm
            organization={organization}
            onSubmit={updateOrganization}
            onCancel={() => setEditModalOpened(false)}
          />
        </ScaleModal>
      )}
      <Grid
        container
        direction="column"
        rowGap={8}
      >
        <Grid item>
          <OverviewTable
            header={organization.enterpriseName}
            rows={rows}
            editAction={{
              onClick: () => setEditModalOpened(true),
              disabled: !canEditOrg,
              tooltip: 'editOrganizationDisabled',
            }}
          />
        </Grid>
        {canSeeMarketplace && (
          <Grid item>
            <MarketplaceItems organization={organization} setOrganization={setOrganization} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default Overview;
