import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Container } from '@mui/material';
import {
  ScaleButton,
  ScaleIconActionCopyPaste,
  ScaleLink,
  ScaleModal,
} from '@telekom/scale-components-react';

import CopyToClipboard from '../../../components/CopyToClipboard';
import OverviewTable from '../../../components/OverviewTable/OverviewTable';
import DeleteForm from '../../../components/DeleteForm';
import rbacCheck from '../../../rbac/rbacCheck';

import Provision from '../../../api/provision';
import useApiCall from '../../../hooks/useApiCall';

const createConfig = (osProject, userEmail) => `
    export OS_REGION_NAME=RegionOne
    export OS_AUTH_URL=${osProject.cloudId}.in.pan-net.eu:5000/v3
    export OS_USER_DOMAIN_NAME=users_domain
    export OS_USERNAME=${userEmail}
    echo "Please enter your OpenStack Password: "
    read -sr OS_PASSWORD_INPUT
    export OS_PASSWORD=$OS_PASSWORD_INPUT
    export OS_PROJECT_NAME=${osProject.name}
    export OS_PROJECT_DOMAIN_NAME=${osProject.juiceGroup}
    # set prompt - optional
    export BDCNAME=${osProject.cloudId}
    export PS1=\\[\\e[32m\\]$OS_USERNAME:$OS_USER_DOMAIN_NAME\\[\\e[m\\]@\\h (\\W)[\\[\\e[34m\\]$OS_PROJECT_NAME:$OS_PROJECT_DOMAIN_NAME\\[\\e[m\\]@\\[\\e[36m\\]\${BDC_NAME}\\[\\e[m\\]]\\[\\033[01;31m\\]$(parse_git_branch)\\[\\033[00m\\]\\$
    # explicit placement and compute api version - optional
    # export OS_PLACEMENT_API_VERSION=1.28
    # export OS_COMPUTE_API_VERSION=2.53
    # if no DT Cloud Services certs installed on your PC, cert file path should be defined
    # export OS_CACERT=<cert_path>
`;

const OsProject = () => {
  const {
    productId, juiceGroup, cloudId, osprojectName,
  } = useParams();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.user);

  const fetchOsProjectDetails = useApiCall(Provision.fetchOsProjectDetails);
  const deleteOsProject = useApiCall(Provision.deleteOsProject, 'osProjectDeleted', null);
  const patchOsProject = useApiCall(Provision.patchOsProject, 'osProjectSetDeleted', 'osProjectPatchFailed');

  const [osConfigModalOpened, setOsConfigModalOpened] = useState(false);
  const [deleteModalOpened, setDeleteModalOpened] = useState(false);
  const [osProject, setOsProject] = useState({});

  const deleteOsProjectCall = async () => {
    const [data] = await deleteOsProject(cloudId, osprojectName);
    if (data !== null) navigate(-1);
  };

  const patchOsProjectCall = async () => {
    const [data] = await patchOsProject(
      osProject.juiceGroup,
      osProject.cloudId,
      osProject.name,
      {
        status: 'deleted',
      },
    );
    if (data !== null) {
      navigate(-1);
    }
  };

  const checkPermissions = () => {
    const canPatch = rbacCheck(userData.roles, '/osprojects:PATCH', {
      productId,
      osProjectType: osProject.type,
      userProducts: userData.productIds,
    });

    const canDelete = rbacCheck(userData.roles, '/osprojects:DELETE', {
      productId,
      osProjectType: osProject.type,
      userProducts: userData.productIds,
    });

    if (osProject.status === 'deletionFailed') {
      return canPatch && canDelete;
    }

    return osProject.status === 'active' && canDelete;
  };

  useEffect(() => {
    async function getOsProjectDetails() {
      const [data] = await fetchOsProjectDetails(juiceGroup, cloudId, osprojectName);
      if (data) setOsProject(data);
    }
    getOsProjectDetails();
  }, []);

  const config = createConfig(osProject, userData.email);
  const permissionsResult = checkPermissions();

  let tooltip;
  if (osProject.createdFromJuice) {
    tooltip = 'osProjectDeleteDisabledCreatedFromJuice';
  } else if (!permissionsResult) {
    tooltip = 'osProjectDeleteDisabled';
  }

  const deleteActionProps = {
    onClick: osProject.status === 'deletionFailed' ? patchOsProjectCall : () => setDeleteModalOpened(true),
    label: osProject.status === 'deletionFailed' ? 'Set As Deleted' : undefined,
    tooltip,
    disabled: !permissionsResult || osProject.createdFromJuice,
  };

  return (
    (osProject?.status === 'active' || osProject?.status === 'deletionFailed') && (
      <Container maxWidth="md">
        { osConfigModalOpened && (
          <ScaleModal
            opened
            heading="Configuration"
            onScale-close={() => setOsConfigModalOpened(false)}
          >
            <CopyToClipboard
              data={config}
              onSubmit={() => setOsConfigModalOpened(false)}
            />
          </ScaleModal>
        )}
        {deleteModalOpened && (
          <ScaleModal
            heading="Delete OsProject"
            size="small"
            opened
            onScale-close={() => setDeleteModalOpened(false)}
          >
            <DeleteForm
              fieldName="Os Project name"
              fieldValue={osProject?.name}
              onClose={() => setDeleteModalOpened(false)}
              messages={['osProjectsDeleteMessage', 'osProjectsDeleteConfirmation']}
              deleteFunc={deleteOsProjectCall}
            />
          </ScaleModal>
        )}
        <OverviewTable
          header={osProject?.name}
          rows={[
            {
              title: 'Dev. Project Name',
              value: osProject?.name,
            },
            {
              title: 'OpenStack User Name:',
              value: userData.productIds.includes(productId) ? userData.email : '',
            },
            {
              title: 'OpenStack working source file:',
              component: (
                <ScaleButton
                  variant="secondary"
                  size="small"
                  disabled={!osProject?.name}
                  onClick={() => setOsConfigModalOpened(true)}
                >
                  <ScaleIconActionCopyPaste />
                  Copy working source file
                </ScaleButton>
              ),
            },
            {
              title: 'Horizon URL:',
              component: (
                <ScaleLink role="link" target="_blank" rel="noopener" href={osProject.horizonUrl}>{osProject.horizonUrl}</ScaleLink>
              ),
            },
          ]}
          deleteAction={deleteActionProps}
        />
      </Container>
    )
  );
};

export default OsProject;
