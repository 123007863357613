import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import { Select } from '../../../../../components/InputFields/index';
import ForecastSummary from './ForecastSummary';
import VirtualCloudsSummary from './VirtualCloudsSummary';
import UsageSummary from './UsageSummary';

import Provision from '../../../../../api/provision';
import useApiCall from '../../../../../hooks/useApiCall';

const Summaries = ({ juiceGroup, setModalSize }) => {
  const getVirtualCloudsCall = useApiCall(Provision.fetchVirtualClouds);
  const [virtualClouds, setVirtualClouds] = useState([]);
  const [cloudId, setCloudId] = useState('');

  async function getVirtualClouds() {
    const [data] = await getVirtualCloudsCall({ juiceGroups: [juiceGroup] });
    if (data?.length > 0) { setVirtualClouds(data); }
  }

  useEffect(() => {
    if (juiceGroup) getVirtualClouds();
  }, [juiceGroup]);

  const juiceGroupCloudsItems = virtualClouds?.map((virtualCloud) => ({
    value: virtualCloud.cloud.name,
    label: virtualCloud.cloud.name,
  }));

  useEffect(() => {
    if (cloudId) {
      setModalSize('large');
    } else {
      setModalSize('small');
    }
  }, [cloudId, setModalSize]);

  return (
    <Grid
      container
      direction="column"
      pb={2}
    >
      <Grid item>
        <Select
          id="cloudDropdown"
          name="cloudDropdown"
          value={cloudId}
          label="Select a cloud to see the available hypervisors"
          onChange={(event) => setCloudId(event.target.value)}
          menuItems={juiceGroupCloudsItems}
        />
      </Grid>
      {cloudId && (
        <Grid
          container
          direction="column"
          gap={2}
        >
          <Grid item>
            <Typography variant="h5" color="primary">
              Forecast Summary
            </Typography>
            <ForecastSummary juiceGroup={juiceGroup} cloudId={cloudId} />
          </Grid>
          <Grid item>
            <Typography variant="h5" color="primary">
              Virtual Cloud Summary
            </Typography>
            <VirtualCloudsSummary
              virtualCloud={virtualClouds.find((vc) => vc.cloud.name === cloudId)}
            />
          </Grid>
          <Grid item>
            <Typography variant="h5" color="primary">
              Usage Summary
            </Typography>
            <UsageSummary juiceGroup={juiceGroup} cloudId={cloudId} />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

Summaries.propTypes = {
  juiceGroup: PropTypes.string.isRequired,
  setModalSize: PropTypes.func.isRequired,
};

export default Summaries;
