import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {
  Formik,
  Form,
} from 'formik';
import {
  ScaleButton,
} from '@telekom/scale-components-react';
import { Grid } from '@mui/material';
import { FormikSelect, FormikTextField } from '../../../components/Formik/index';

const CreateUserForm = ({ roles, onSubmit, onCancel }) => {
  const initialValues = {
    email: '',
    role: '',
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required('Required').email('Invalid email address'),
    role: Yup.string().required('Required'),
  });

  const roleItems = Object.keys(roles).map((key) => ({
    value: key,
    label: roles[key],
  }));

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ dirty, isValid }) => (
        <Form>
          <Grid
            container
            direction="column"
            pb={2}
          >
            <Grid item>
              <FormikTextField
                name="email"
                label="Email"
              />
            </Grid>
            <Grid item>
              <FormikSelect
                name="role"
                label="Role"
                menuItems={roleItems}
              />
            </Grid>
            <Grid
              container
              justifyContent="end"
              gap={1}
            >
              <Grid item>
                <ScaleButton
                  variant="primary"
                  disabled={!dirty || !isValid}
                >
                  Create
                </ScaleButton>
              </Grid>
              <Grid item>
                <ScaleButton
                  type="button"
                  variant="secondary"
                  onClick={onCancel}
                >
                  Cancel
                </ScaleButton>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

CreateUserForm.propTypes = {
  roles: PropTypes.objectOf(PropTypes.string).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};


export default CreateUserForm;
