import { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ScaleModal } from '@telekom/scale-components-react';
import { Container } from '@mui/material';
import EditContactForm from './EditContactForm';
import DeleteForm from '../../components/DeleteForm';

import OverviewTable from '../../components/OverviewTable/OverviewTable';
import Section from '../../components/Section';
import useApiCall from '../../hooks/useApiCall';
import Provision from '../../api/provision';
import rbacCheck from '../../rbac/rbacCheck';

const lookupType = {
  stakeHolder: 'Stakeholder',
  serviceOwner: 'Service Owner',
  tenantOwner: 'Tenant Owner',
};

const ContactDetails = () => {
  const formRef = useRef(null);
  const navigate = useNavigate();
  const { juiceGroup, contactId } = useParams();
  const roles = useSelector((state) => state.user.roles);

  const [contactDetail, setContactDetail] = useState({});
  const [editContactModalOpened, setEditContactModalOpened] = useState(false);
  const [removeContactModalOpened, setRemoveContactModalOpened] = useState(false);


  const canAddEditContacts = rbacCheck(roles, '/products:CONTACTS_PATCH');
  const canDeleteContacts = rbacCheck(roles, '/products:CONTACTS_DELETE');

  const getJuicegroupInfo = useApiCall(Provision.fetchJuiceGroupInfo, null, null);
  const removeContact = useApiCall(Provision.removeContact, 'ContactRemoved', null);

  const getContactDetails = async () => {
    const [data] = await getJuicegroupInfo({ juiceGroup });
    if (data) {
      const contact = data.contacts.find((c) => c._id === contactId);
      setContactDetail(contact);
    }
  };

  const removeContactCall = async () => {
    const [data, error] = await removeContact({ id: contactId, juiceGroup });
    if (error) setRemoveContactModalOpened(false);
    if (data) navigate(-1);
  };

  useEffect(() => {
    getContactDetails();
  }, []);

  const rows = [
    { title: 'Name', value: contactDetail.name },
    { title: 'role', value: lookupType[contactDetail.type] },
    { title: 'phone', value: contactDetail.phoneNumber },
    { title: 'slack', value: contactDetail.slackChannel },
  ];


  return (
    <>
      {editContactModalOpened && (
      <ScaleModal
        opened
        size="small"
        heading="Edit Contact Info"
        onScale-close={() => {
          formRef.current.resetForm();
          setEditContactModalOpened(false);
        }}
      >
        <EditContactForm
          formRef={formRef}
          contact={contactDetail}
          juiceGroup={juiceGroup}
          onSubmit={() => {
            setContactDetail(getContactDetails());
            setEditContactModalOpened(false);
          }}
          onCancel={() => setEditContactModalOpened(false)}
        />
      </ScaleModal>
      )}
      { removeContactModalOpened && (
      <ScaleModal
        opened
        size="small"
        heading="Delete Contact"
        onScale-close={() => setRemoveContactModalOpened(false)}
      >
        <DeleteForm
          fieldName="email"
          fieldValue={contactDetail.email}
          messages={['ContactDeleteConfirmation']}
          deleteFunc={removeContactCall}
          onClose={() => setRemoveContactModalOpened(false)}
        />
      </ScaleModal>
      )}
      <Container maxWidth="xl">
        <Section header={contactDetail.email}>
          <OverviewTable
            rows={rows}
            editAction={{
              onClick: () => setEditContactModalOpened(true),
              disabled: !canAddEditContacts,
              tooltip: 'contactEditDisabled',
            }}
            deleteAction={{
              onClick: () => setRemoveContactModalOpened(true),
              disabled: !canDeleteContacts,
              tooltip: 'contactDeleteDisabled',
            }}
          />
        </Section>
      </Container>
    </>
  );
};

export default ContactDetails;
