import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ScaleModal } from '@telekom/scale-components-react';

import {
  createMachineAccount,
  fetchMachineAccounts,
  removeMachineAccountTokens,
} from '../../../../../redux/safescarf/machineAccounts/actions';
import { updateUser } from '../../../../../redux/safescarf/users/actions';

import rbacCheck from '../../../../../rbac/rbacCheck';
import Table from '../../../../../components/Table';
import CopyApiKey from './CopyApiKey';


const MachineAccounts = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);
  const machineAccounts = useSelector((state) => state.safescarf.machineAccounts);

  useEffect(() => {
    if (!machineAccounts.length) dispatch(fetchMachineAccounts());
  }, []);


  const machineAccountToken = machineAccounts.length > 0
    ? machineAccounts[machineAccounts.length - 1]?.token : null;
  const machineAccountName = machineAccounts.length > 0
    ? machineAccounts[machineAccounts.length - 1]?.username : '';

  const fields = [
    { id: 'username', title: 'Username' },
    { id: 'createdBy', title: 'Created by' },
    { id: 'status', title: 'Status' },
  ];

  const rows = machineAccounts.map((ma) => {
    const canUpdateMachineAccount = rbacCheck(userData.roles, '/products/safescarf/users:PUT', { userEmail: userData.email, rowEmail: ma.email });
    return {
      key: ma.username,
      data: [
        { field: 'username', value: ma.username },
        { field: 'createdBy', value: ma.email },
        {
          field: 'status',
          type: 'switch',
          props: {
            disabled: !canUpdateMachineAccount,
            checked: ma.is_active,
            label: ma.is_active ? 'Active' : 'Inactive',
            'onScale-change': () => {
              dispatch(updateUser({
                id: ma.id,
                username: ma.username,
                is_active: !ma.is_active,
              }));
            },
          },
        },
      ],
    };
  });


  return (
    <>
      {machineAccountToken && (
        <ScaleModal
          opened
          size="small"
          heading="Machine Account was Created"
          onScale-before-close={(event) => event.preventDefault()}
        >
          <CopyApiKey
            machineAccountName={machineAccountName}
            machineAccountToken={machineAccountToken}
            onSubmit={() => dispatch(removeMachineAccountTokens())}
          />
        </ScaleModal>
      )}
      <Table
        header="Machine Accounts"
        fields={fields}
        rows={rows}
        createAction={{
          onClick: () => dispatch(createMachineAccount()),
        }}
      />
    </>
  );
};


export default MachineAccounts;
