export const FETCH_MACHINE_ACCOUNTS_STARTED = 'FETCH_MACHINE_ACCOUNTS_STARTED';
export const FETCH_MACHINE_ACCOUNTS_FULFILLED = 'FETCH_MACHINE_ACCOUNTS_FULFILLED';
export const CREATE_MACHINE_ACCOUNT_STARTED = 'CREATE_MACHINE_ACCOUNT_STARTED';
export const CREATE_MACHINE_ACCOUNT_FULFILLED = 'CREATE_MACHINE_ACCOUNT_FULFILLED';
export const REMOVE_MACHINE_ACCOUNT_TOKENS = 'REMOVE_MACHINE_ACCOUNT_TOKENS';

export const fetchMachineAccounts = () => (
  { type: FETCH_MACHINE_ACCOUNTS_STARTED }
);

export const fetchMachineAccountsFulfilled = (data) => (
  { type: FETCH_MACHINE_ACCOUNTS_FULFILLED, payload: data }
);

export const createMachineAccount = () => (
  { type: CREATE_MACHINE_ACCOUNT_STARTED }
);

export const createMachineAccountFulfilled = (data) => (
  { type: CREATE_MACHINE_ACCOUNT_FULFILLED, payload: data }
);

export const removeMachineAccountTokens = () => (
  { type: REMOVE_MACHINE_ACCOUNT_TOKENS }
);
