import {
  useField,
  useFormikContext,
} from 'formik';
import PropTypes from 'prop-types';
import { NumberField } from '../../InputFields';

export const FormikNumberField = ({
  name,
  label,
  placeholder,
  autoFocus,
  disabled,
  onWheel,
}) => {
  const [field, meta] = useField(name);
  const { values } = useFormikContext();
  const isDisabled = typeof disabled === 'string' ? !values[disabled] : disabled;

  return (
    <NumberField
      id={field.name}
      name={field.name}
      value={field.value}
      onChange={field.onChange}
      onBlur={field.onBlur}
      label={label}
      placeholder={placeholder}
      autoFocus={autoFocus}
      disabled={isDisabled}
      helperText={meta.touched ? meta.error : ''}
      onWheel={onWheel}
    />
  );
};

FormikNumberField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  onWheel: PropTypes.func,
};

FormikNumberField.defaultProps = {
  placeholder: '',
  autoFocus: false,
  disabled: false,
  onWheel: () => {},
};
