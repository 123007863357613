import OsProjects from './OsProjects';
import Users from './Users';
import CloudReport from './CloudReport';
import OpenstackClusters from './OpenstackClusters';
import BareMetalClusters from './BareMetalClusters';


export default {
  compute: [
    { name: 'osprojects', Component: (props) => <OsProjects {...props} /> },
    { name: 'users', Component: (props) => <Users {...props} /> },
    { name: 'cloudreport', Component: (props) => <CloudReport {...props} /> },
  ],
  evaluation: [
    { name: 'osprojects', Component: (props) => <OsProjects {...props} /> },
    { name: 'users', Component: (props) => <Users {...props} /> },
    { name: 'cloudreport', Component: (props) => <CloudReport {...props} /> },
  ],
  caasonopenstack: [
    { name: 'clusters', Component: (props) => <OpenstackClusters {...props} /> },
    { name: 'users', Component: (props) => <Users {...props} /> },
    { name: 'cloudreport', Component: (props) => <CloudReport {...props} /> },
  ],
  caasonbaremetal: [
    { name: 'clusters', Component: (props) => <BareMetalClusters {...props} /> },
  ],
};

