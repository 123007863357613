import { combineReducers } from 'redux';

import { instanceReducer } from './instance/reducer';
import { usersReducer } from './users/reducer';
import { machineAccountsReducer } from './machineAccounts/reducer';
import { rolesReducer } from './roles/reducer';
import { productTypesReducer } from './productTypes/reducer';
import { productTypeMembersReducer } from './productTypeMembers/reducer';
import { productsReducer } from './products/reducer';
import { productMembersReducer } from './productMembers/reducer';

export default combineReducers({
  instance: instanceReducer,
  users: usersReducer,
  machineAccounts: machineAccountsReducer,
  roles: rolesReducer,
  productTypes: productTypesReducer,
  productTypeMembers: productTypeMembersReducer,
  products: productsReducer,
  productMembers: productMembersReducer,
});
