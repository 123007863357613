import {
  ENQUEUE_SNACKBAR,
  REMOVE_SNACKBAR,
} from './actions';


const notifierReducer = (state = [], action) => {
  switch (action.type) {
    case ENQUEUE_SNACKBAR:
      return [{
        key: action.key,
        ...action.notification,
      }];

    case REMOVE_SNACKBAR:
      return state.filter(
        (notification) => notification.key !== action.key,
      );

    default:
      return state;
  }
};

export { notifierReducer };
