import { useEffect } from 'react';
import {
  Outlet,
  ScrollRestoration,
  useLocation,
} from 'react-router-dom';
import {
  ScaleTelekomAppShell,
  ScaleTelekomFooter,
  ScaleTelekomHeader,
} from '@telekom/scale-components-react';

import { useDispatch, useSelector } from 'react-redux';
import { resetError } from './redux/status/actions';
import Authenticator from './components/SecureRoutes/Authenticator';
import CookieBanner from './pages/Cookies/CookieBanner';
import useNotifier from './hooks/useNotifier';
import FooterContent from './components/AppLayout/FooterContent';
import Overlay from './components/Overlay';
import MainNavigation from './components/AppLayout/MainNavigation';
import FunctionNavigation from './components/AppLayout/FunctionNavigation';
import ScrollToTop from './components/AppLayout/ScrollToTop';
import SomethingWentWrong from './pages/Error/SomethingWentWrong';

import './App.css';

const AppLayout = () => {
  useNotifier();
  const dispatch = useDispatch();
  const location = useLocation();
  const reduxError = useSelector((state) => state.status.error);

  useEffect(() => {
    if (reduxError) {
      dispatch(resetError());
    }
  }, [location, dispatch]);

  if (reduxError === 'NETWORK_ERROR') {
    return <SomethingWentWrong />;
  }

  return (
    <>
      {/* We need ScrollToTop in addition to ScrollRestoration because the latter
      misbehaves in some cases. */}
      <ScrollToTop />
      <ScrollRestoration />
      <Overlay />
      <CookieBanner />
      <ScaleTelekomAppShell>
        <ScaleTelekomHeader
          slot="header"
          app-name="TDI Connect"
          app-name-link="/"
          logoHref="/"
          type="slim"
        >
          <MainNavigation />
          <FunctionNavigation />
        </ScaleTelekomHeader>

        <div className="app-content">
          <Authenticator>
            <Outlet />
          </Authenticator>
        </div>

        <ScaleTelekomFooter slot="footer" type="minimal">
          <FooterContent />
        </ScaleTelekomFooter>
      </ScaleTelekomAppShell>
    </>
  );
};

export default AppLayout;
