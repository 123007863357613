import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { ScaleButton } from '@telekom/scale-components-react';
import { Grid } from '@mui/material';
import { utils, writeFile } from 'xlsx';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { FormikDateField } from '../../../../../components/Formik';

import Section from '../../../../../components/Section';

import Provision from '../../../../../api/provision';
import { requestStarted, requestFulfilled, requestFailed } from '../../../../../redux/status/actions';
import { enqueueSnackbar } from '../../../../../redux/notifier/actions';
import useApiCall from '../../../../../hooks/useApiCall';

const [nowDate] = new Date().toISOString().split('T');

const CloudReport = ({ product }) => {
  const getVirtualCloudsCall = useApiCall(Provision.fetchVirtualClouds);
  const dispatch = useDispatch();
  const [virtualClouds, setVirtualClouds] = useState([]);

  useEffect(() => {
    async function getVirtualClouds() {
      const [data] = await getVirtualCloudsCall({ juiceGroups: [product.details.juiceGroup] });
      if (data) {
        setVirtualClouds(data);
      }
    }
    if (product.details.juiceGroup) getVirtualClouds();
  }, [product.details.juiceGroup]);

  async function downloadReport(data) {
    const cloudIds = virtualClouds.map((virtualCloud) => virtualCloud.cloud.name);
    try {
      dispatch(requestStarted());
      const constructCloudData = (cloudDetails) => cloudDetails.instances.map((instance) => ({
        'Project Name': cloudDetails.metadata.projects[instance.project_id].name,
        'Instance Name': instance.name,
        'Instance Id': instance.id,
        'Hypervisor Name': instance.host_name,
        'Hypervisor Type': cloudDetails.metadata.hosts[instance.host_name].compute_role,
        'CPU Allocation Ratio': cloudDetails.metadata.hosts[instance.host_name].cpu_ratio,
        vCPUs: instance.cpus_total,
        'Cpu Policy': cloudDetails.metadata.hosts[instance.host_name].cpu_policy,
        'Cpu Usage (hours)': instance.cpu_usage_hours,
        'Memory GB': instance.ram_gigabytes,
        'RAM Usage (hours)': instance.ram_usage_hours,
        'Root Disk GB': instance.disk_gigabytes,
        'Disk Usage (hours)': instance.disk_usage_hours,
        State: instance.status,
        Flavor: instance.flavor_name,
        'Created At': instance.created_at,
        'Deleted At': instance.deleted_at,
      }));
      const instanceDetails = await Provision.fetchInstanceDetails({
        juice_group: product.details.juiceGroup,
        cloudIds,
        begin: new Date(data.fromDate).setUTCHours(0, 0, 0),
        end: new Date(data.toDate).setUTCHours(23, 59, 59),
      });

      // Check if there are any details to be exported, and inform the user if not
      const fetchedInstances = instanceDetails.data.map((detail) => detail.instances).flat();
      if (!fetchedInstances.length) {
        dispatch(requestFulfilled());
        dispatch(enqueueSnackbar({
          message: 'noUsageData',
          options: { variant: 'info' },
        }));
        return;
      }

      const workbook = utils.book_new();
      instanceDetails.data.forEach((cloudDetails) => {
        const rows = constructCloudData(cloudDetails);
        const worksheet = utils.json_to_sheet(rows);
        utils.book_append_sheet(workbook, worksheet, cloudDetails.context.cloud_id);
      });

      dispatch(requestFulfilled());
      writeFile(workbook, `cloud-report-${data.fromDate}-${data.toDate}.xls`);
    } catch (error) {
      dispatch(requestFailed());
    }
  }

  return (
    <Section header="Cloud Report" divider>
      <Formik
        initialValues={{
          fromDate: '',
          toDate: '',
        }}
        validationSchema={Yup.object().shape({
          fromDate: Yup.date().required('Required')
            .max(Yup.ref('toDate'), 'From date must be before To date'),
          toDate: Yup.date().max(nowDate).required('Required')
            .min(Yup.ref('fromDate'), 'To date must be after From date'),
        })}
        onSubmit={(data) => downloadReport(data)}
      >
        {({ isValid, dirty }) => (
          <Form>
            <Grid
              container
              justifyContent="space-between"
              pb={1}
            >
              <Grid item xs={4}>
                <FormikDateField
                  name="fromDate"
                  label="From"
                  inputProps={{ max: nowDate }}
                />
              </Grid>
              <Grid item xs={4}>
                <FormikDateField
                  name="toDate"
                  label="To"
                  inputProps={{ max: nowDate }}
                />
              </Grid>
              <Grid item xs={2}>
                <ScaleButton
                  type="submit"
                  disabled={!dirty || !isValid}
                >
                  Submit
                </ScaleButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Section>
  );
};

CloudReport.propTypes = {
  product: PropTypes.shape({
    details: PropTypes.shape({
      juiceGroup: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default CloudReport;
