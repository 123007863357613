import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { ScaleModal } from '@telekom/scale-components-react';
import rbacCheck from '../../../rbac/rbacCheck';
import NewTable from '../../../components/NewTable';
import CreateUserForm from './CreateUserForm';
import { assignableRoles, userRolesLookup } from '../../../helpers/lookups';
import useApiCall from '../../../hooks/useApiCall';
import Auth from '../../../api/auth';
import useTableApiCall from '../../../hooks/useTableApiCall';

const Users = () => {
  const { organizationId } = useParams();
  const intl = useIntl();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.user);
  const createUserCall = useApiCall(Auth.createV2User);

  const userRoles = rbacCheck(userData.roles, '/users:PUT_ROLE_CHECK');
  const canCreateUser = rbacCheck(userData.roles, '/users:POST');
  const [createModalOpened, setCreateModalOpened] = useState(false);
  const [rows, setRows] = useState([]);

  const {
    fetchData,
    ...tableData
  } = useTableApiCall(true, setRows, Auth.fetchV2Users, { organizationId }, 10, 'email');

  const toggleCreateModal = () => setCreateModalOpened((prev) => !prev);

  const handleCreateUser = async (formValues) => {
    const [data] = await createUserCall({
      ...formValues,
      organizationId,
    });
    toggleCreateModal();
    if (data) fetchData();
  };

  const fields = useMemo(() => [
    { title: 'Email', id: 'email' },
    { title: 'Role', id: 'role' },
    { title: 'Activated', id: 'activated' },
  ], []);

  const transformedRows = useMemo(() => rows.map((user) => ({
    key: user.uuid,
    onRowClick: () => navigate(`/users/${user.uuid}`),
    data: [
      { field: 'email', value: user.email },
      { field: 'role', value: userRolesLookup[user.role] },
      { field: 'activated', value: user.activated ? 'True' : 'False' },
    ],
  })), [rows]);

  return (
    <>
      {createModalOpened && (
        <ScaleModal
          opened
          size="small"
          heading="Create a new user"
          onScale-close={toggleCreateModal}
        >
          {userRoles.length > 0 && (
            <CreateUserForm
              roles={assignableRoles(userRoles)}
              onSubmit={handleCreateUser}
              onCancel={toggleCreateModal}
            />
          )}
        </ScaleModal>
      )}
      <NewTable
        header="Users"
        fields={fields}
        rows={transformedRows}
        {...tableData}
        createAction={{
          disabled: !canCreateUser,
          onClick: toggleCreateModal,
          tooltip: !canCreateUser
            ? intl.formatMessage({ id: 'userCreationPermissionErr' })
            : '',
        }}
      />
    </>
  );
};

export default Users;
