import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { Container, Paper, Typography } from '@mui/material';
import { ScaleButton, ScaleTextField } from '@telekom/scale-components-react';

import { enqueueSnackbar } from '../../redux/notifier/actions';
import Auth from '../../api/auth';

import './PasswordReset.css';

const PasswordReset = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const resetForgottenPassword = async (data) => {
    try {
      await Auth.resetUserPasswordLink(data);
      dispatch(enqueueSnackbar({
        message: 'userPasswordResetEmail',
        options: { variant: 'success' },
      }));
      navigate('/');
    } catch (error) {
      dispatch(enqueueSnackbar({
        message: error.message,
        options: { variant: 'error' },
      }));
    }
  };

  const validateEmail = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }

    return errors;
  };

  return (
    <Container maxWidth="sm">
      <Paper style={{ padding: '2.125rem' }}>
        <Typography align="center" variant="h4" color="primary">Reset Password</Typography>
        <Typography align="center">Enter your portal email address in order to receive a password reset link:</Typography>
        <Formik
          initialErrors={{ email: '' }}
          initialValues={{ email: '' }}
          validate={validateEmail}
          onSubmit={resetForgottenPassword}
        >
          {({ isValid }) => (
            <Form>
              <Field
                class="resetPassword-form__input"
                type="email"
                name="email"
              >
                {({ field, form: { errors } }) => (
                  <ScaleTextField
                    label="Email address"
                    placeholder="example@pan-net.eu"
                    helperText={errors.email}
                    onScale-change={field.onChange}
                    {...field}
                  />
                )}
              </Field>
              <ScaleButton
                class="resetPassword-form__button"
                size="small"
                type="submit"
                disabled={!isValid}
              >
                Reset Password
              </ScaleButton>
            </Form>
          )}
        </Formik>
      </Paper>
    </Container>
  );
};

export default PasswordReset;
