import { ofType, combineEpics } from 'redux-observable';
import { mergeMap, catchError } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { handleError } from '../../errorHandler';
import Safescarf from '../../../api/safescarf';
import {
  FETCH_PRODUCT_TYPE_MEMBERS_STARTED,
  fetchProductTypeMembersFulfilled,
  UPDATE_PRODUCT_TYPE_MEMBER_ROLE_STARTED,
  updateProductTypeMemberRoleFulfilled,
} from './actions';

const fetchProductTypeMembersEpic = (action$) => action$.pipe(
  ofType(FETCH_PRODUCT_TYPE_MEMBERS_STARTED),
  mergeMap((action) => from(Safescarf.fetchSafescarfProductTypeMembers(action.payload)).pipe(
    mergeMap((response) => of(
      fetchProductTypeMembersFulfilled(response.data),
    )),
    catchError(handleError),
  )),
);

const updateProductTypeMemberRoleEpic = (action$) => action$.pipe(
  ofType(UPDATE_PRODUCT_TYPE_MEMBER_ROLE_STARTED),
  mergeMap((action) => from(Safescarf.updateProductTypeMemberRole(action.payload)).pipe(
    mergeMap((response) => of(
      updateProductTypeMemberRoleFulfilled(response.data),
    )),
    catchError(handleError),
  )),
);

export default combineEpics(
  fetchProductTypeMembersEpic,
  updateProductTypeMemberRoleEpic,
);
