import Keycloak from 'keycloak-js';

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const keycloak = new Keycloak({
  clientId: 'piaas',
  realm: 'pan-net.cloud',
  url: window.PORTAL_IDP_AUTH_SERVER_URL || import.meta.env.VITE_IDP_AUTH_SERVER_URL,
  'auth-server-url': window.PORTAL_IDP_AUTH_SERVER_URL || import.meta.env.VITE_IDP_AUTH_SERVER_URL,
  'ssl-required': 'external',
  resource: 'piaas',
  'public-client': true,
  'confidential-port': 0,
});

export default keycloak;
