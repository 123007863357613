import http from './httpClient';

class MonitoringMs {
  static getDashboard(dashboardId) {
    return http.get(`${import.meta.env.VITE_MONITORING_V1_BASE}/dashboard/${dashboardId}`);
  }

  static getPanel(data, dashboardId) {
    return http.post(`${import.meta.env.VITE_MONITORING_V1_BASE}/panel`, { ...data, dashboardId });
  }
}

export default MonitoringMs;
