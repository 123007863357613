import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ScaleButton,
  ScaleTextField,
  ScaleDropdownSelect,
  ScaleDropdownSelectItem,
} from '@telekom/scale-components-react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Formik,
  Form,
  useFormikContext,
  useField,
} from 'formik';
import { Container, Typography } from '@mui/material';
import * as Yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import { requestStarted, requestFulfilled, requestFailed } from '../../../redux/status/actions';

import useApiCall from '../../../hooks/useApiCall';
import Auth from '../../../api/auth';
import Provision from '../../../api/provision';
import Caas from '../../../api/caas';
import { regexValidator } from '../../../helpers/regexValidator';

const initialValues = {
  projectId: '',
  productId: '',
  juiceGroup: '',
  cloudName: '',
  clusterName: '',
  workersAmount: '',
  workersFlavor: '',
  diskQuota: '',
  floatingIps: '',
  enviroment: '',
  clusterExposure: '',
  k8sVersion: '',
  unusedQuota: {},
  availableFlavors: [],
  availableK8sVersions: [],
};

const numberInputOnWheelPreventChange = (e) => {
  e.target.blur();
  e.stopPropagation();
  requestAnimationFrame(() => e.target.focus());
};

const ProjectField = () => {
  const dispatch = useDispatch();
  const [projects, setProjects] = useState([]);
  const [field, meta] = useField({ name: 'projectId' });
  const { values: { clusterName }, setValues } = useFormikContext();

  async function getProjects() {
    try {
      dispatch(requestStarted());
      const response = await Auth.fetchV2Projects();
      setProjects(response.data);
      dispatch(requestFulfilled());
    } catch (error) {
      dispatch(requestFailed());
    }
  }

  useEffect(() => {
    getProjects();
  }, []);

  return (
    <div className="form-field">
      <ScaleDropdownSelect
        {...field}
        label="Project"
        helperText={meta.touched ? meta.error : null}
        onScale-change={(e) => {
          field.onChange(e);
          setValues({
            ...initialValues,
            clusterName,
            projectId: e.detail.value,
          });
        }}
      >
        {projects.map((project) => (
          <ScaleDropdownSelectItem
            key={project._id}
            value={project._id}
          >
            {project.name}
          </ScaleDropdownSelectItem>
        ))}
      </ScaleDropdownSelect>
    </div>
  );
};

const ProductsField = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);
  const [products, setProducts] = useState([]);
  const { values: { projectId }, setFieldValue, setFieldTouched } = useFormikContext();
  const [field, meta] = useField({ name: 'productId' });

  async function getProducts() {
    try {
      dispatch(requestStarted());
      const response = await Auth.fetchV2Products({ projectId, type: 'caasonopenstack' });
      setProducts(response.data.filter((product) => (
        product.approved && userData.productIds.includes(product._id)
      )));
      dispatch(requestFulfilled());
    } catch (error) {
      dispatch(requestFailed());
    }
  }

  useEffect(() => {
    if (projectId) {
      getProducts();
    }
  }, [projectId]);

  return (
    <div className="form-field">
      <ScaleDropdownSelect
        {...field}
        label="Product"
        helperText={meta.touched ? meta.error : null}
        disabled={!projectId}
        onScale-change={(e) => {
          field.onChange(e);
          setFieldTouched('productId');
          setFieldValue('juiceGroup', products.find((p) => p._id === e.detail.value).details.juiceGroup);
        }}
      >
        {products.map((product) => (
          <ScaleDropdownSelectItem
            key={product._id}
            value={product._id}
          >
            {product.name}
          </ScaleDropdownSelectItem>
        ))}
      </ScaleDropdownSelect>
    </div>
  );
};

const CloudField = () => {
  const dispatch = useDispatch();
  const [availableVirtaulClouds, setAvailableVirtualClouds] = useState([]);
  const { values: { juiceGroup }, setFieldValue, setFieldTouched } = useFormikContext();
  const [field, meta] = useField({ name: 'cloudName' });

  async function getAvailableClouds() {
    try {
      dispatch(requestStarted());
      const response = await Provision.fetchVirtualClouds({ juiceGroups: [juiceGroup] });
      // VirtualClouds decorated with an extra `unusedQuota` field
      setAvailableVirtualClouds(response.data.map((vc) => ({
        ...vc,
        unusedQuota: {
          compute: {
            cores: vc.allowed_quota.compute.cores - vc.used_quota.compute.cores,
            ram: vc.allowed_quota.compute.ram - vc.used_quota.compute.ram,
          },
          volume: {
            gigabytes: vc.allowed_quota.volume.gigabytes - vc.used_quota.volume.gigabytes,
          },
          network: {
            floatingIps: vc.allowed_quota.network.floating_ips - vc.used_quota.network.floating_ips,
          },
        },
      })));
      dispatch(requestFulfilled());
    } catch (error) {
      dispatch(requestFailed());
    }
  }

  async function getCloudDetails(cloudName) {
    try {
      dispatch(requestStarted());
      const response = await Caas.getCloudDetails(uuidv4());
      const { flavors, availableKubernetesVersions } = response.data;
      setFieldValue('unusedQuota', availableVirtaulClouds.find((vc) => vc.cloud.name === cloudName).unusedQuota);
      setFieldValue('availableFlavors', flavors);
      setFieldValue('availableK8sVersions', availableKubernetesVersions);
      dispatch(requestFulfilled());
    } catch (error) {
      dispatch(requestFailed());
    }
  }

  useEffect(() => {
    if (juiceGroup) {
      getAvailableClouds();
    }
  }, [juiceGroup]);

  return (
    <div className="form-field">
      <ScaleDropdownSelect
        {...field}
        label="Cloud"
        helperText={meta.touched ? meta.error : null}
        disabled={!juiceGroup}
        onScale-change={(event) => {
          field.onChange(event);
          setFieldTouched('cloudName');
          getCloudDetails(event.detail.value);
        }}
      >
        {availableVirtaulClouds.map((vc) => (
          <ScaleDropdownSelectItem
            key={vc.id}
            value={vc.cloud.name}
          >
            {vc.cloud.name}
          </ScaleDropdownSelectItem>
        ))}
      </ScaleDropdownSelect>
    </div>
  );
};

const WorkersFlavorField = () => {
  const { values: { availableFlavors }, setFieldTouched } = useFormikContext();
  const [field, meta] = useField({ name: 'workersFlavor' });

  return (
    <div className="form-field">
      <ScaleDropdownSelect
        {...field}
        label="Worker Flavors"
        helperText={meta.touched ? meta.error : null}
        disabled={!availableFlavors.length}
        onScale-change={(e) => {
          field.onChange(e);
          setFieldTouched('workersFlavor');
        }}
      >
        {availableFlavors.map((flavor) => (
          <ScaleDropdownSelectItem key={flavor.name} value={flavor.name}>
            {`${flavor.name} - ${flavor.cpu} vCPU, ${flavor.memory} RAM, ${flavor.disk} GB Disk`}
          </ScaleDropdownSelectItem>
        ))}
      </ScaleDropdownSelect>
    </div>
  );
};

const K8sVersionField = () => {
  const { values: { availableK8sVersions }, setFieldTouched } = useFormikContext();
  const [field, meta] = useField({ name: 'k8sVersion' });

  return (
    <div className="form-field">
      <ScaleDropdownSelect
        {...field}
        label="K8s Version"
        helperText={meta.touched ? meta.error : null}
        disabled={!availableK8sVersions.length}
        onScale-change={(e) => {
          field.onChange(e);
          setFieldTouched('k8sVersion');
        }}
      >
        {availableK8sVersions.map((k8sVersion) => (
          <ScaleDropdownSelectItem
            key={k8sVersion}
            value={k8sVersion}
          >
            {k8sVersion}
          </ScaleDropdownSelectItem>
        ))}
      </ScaleDropdownSelect>
    </div>
  );
};

const WorkersAmountField = () => {
  const {
    values: { unusedQuota, availableFlavors, workersFlavor },
    setFieldTouched,
  } = useFormikContext();
  const selectedFlavorDetails = availableFlavors.find((f) => f.name === workersFlavor);
  const getWorkersAmount = () => {
    if (!unusedQuota || !selectedFlavorDetails) return 0;
    const flavorMemory = parseInt(selectedFlavorDetails.memory.match(/\d+/), 10);
    const flavorCpu = unusedQuota.compute.cores / selectedFlavorDetails.cpu;
    const flavorRam = unusedQuota.compute.ram / flavorMemory;
    const flavorDisk = unusedQuota.volume.gigabytes / selectedFlavorDetails.disk;
    return Math.floor(Math.min(flavorCpu, flavorRam, flavorDisk));
  };
  const allowedWorkerAmount = getWorkersAmount();
  const [field, meta] = useField({
    name: 'workersAmount',
    validate: (value) => {
      if (value > allowedWorkerAmount) return `You can use up to ${allowedWorkerAmount} workers`;
      return undefined;
    },
  });

  return (
    <div className="form-field">
      <ScaleTextField
        {...field}
        disabled={!availableFlavors.length || !workersFlavor}
        label="Workers Amount"
        type="number"
        onWheel={numberInputOnWheelPreventChange}
        placeholder={`Enter Workers Amount (${allowedWorkerAmount} available)`}
        helperText={meta.touched ? meta.error : null}
        onScale-change={(e) => {
          field.onChange(e);
          setFieldTouched('workersAmount');
        }}
      />
    </div>
  );
};

const FloatingIpsField = () => {
  const { values: { unusedQuota }, setFieldTouched } = useFormikContext();
  const availableFloatingIps = unusedQuota.network?.floatingIps;
  const [field, meta] = useField({
    name: 'floatingIps',
    validate: (value) => {
      if (value > availableFloatingIps) return `You can use up to ${availableFloatingIps} floating IPs`;
      return undefined;
    },
  });

  return (
    <div className="form-field">
      <ScaleTextField
        {...field}
        disabled={!availableFloatingIps}
        label="Floating Ips"
        type="number"
        onWheel={numberInputOnWheelPreventChange}
        placeholder={`Enter Floating Ips (${unusedQuota.network?.floatingIps} available)`}
        helperText={meta.touched ? meta.error : null}
        onScale-change={(e) => {
          field.onChange(e);
          setFieldTouched('floatingIps');
        }}
      />
    </div>
  );
};

const DiskQuotaField = () => {
  const { values: { unusedQuota }, setFieldTouched } = useFormikContext();
  const availableDiskQuota = unusedQuota.volume?.gigabytes;
  const [field, meta] = useField({
    name: 'diskQuota',
    validate: (value) => {
      if (value > availableDiskQuota) return `You can use up to ${availableDiskQuota} gigabytes`;
      return undefined;
    },
  });

  return (
    <div className="form-field">
      <ScaleTextField
        {...field}
        disabled={!availableDiskQuota}
        label="Disk Quota"
        type="number"
        onWheel={numberInputOnWheelPreventChange}
        placeholder={`Enter Disk Quota (${availableDiskQuota} GB available)`}
        helperText={meta.touched ? meta.error : null}
        onScale-change={(e) => {
          field.onChange(e);
          setFieldTouched('diskQuota');
        }}
      />
    </div>
  );
};

const EnviromentField = () => {
  const { setFieldTouched } = useFormikContext();
  const [field, meta] = useField({ name: 'enviroment' });
  const availableEnvironments = ['development', 'staging', 'production'];

  return (
    <div className="form-field">
      <ScaleDropdownSelect
        {...field}
        label="Enviroment"
        helperText={meta.touched ? meta.error : null}
        onScale-change={(e) => {
          field.onChange(e);
          setFieldTouched('enviroment');
        }}
      >
        {availableEnvironments.map((enviroment) => (
          <ScaleDropdownSelectItem key={enviroment} value={enviroment}>
            {enviroment}
          </ScaleDropdownSelectItem>
        ))}
      </ScaleDropdownSelect>
    </div>
  );
};

const ClusterExposureField = () => {
  const { setFieldTouched } = useFormikContext();
  const [field, meta] = useField({ name: 'clusterExposure' });
  const availableExposures = ['DTH Network', 'Internet'];

  return (
    <div className="form-field">
      <ScaleDropdownSelect
        {...field}
        label="Cluster Exposure"
        helperText={meta.touched ? meta.error : null}
        onScale-change={(e) => {
          field.onChange(e);
          setFieldTouched('clusterExposure');
        }}
      >
        {availableExposures.map((exposure) => (
          <ScaleDropdownSelectItem key={exposure} value={exposure}>
            {exposure}
          </ScaleDropdownSelectItem>
        ))}
      </ScaleDropdownSelect>
    </div>
  );
};

const ClusterNameField = () => {
  const { setFieldTouched } = useFormikContext();
  const [field, meta] = useField({ name: 'clusterName' });

  return (
    <div className="form-field">
      <ScaleTextField
        {...field}
        label="Cluster Name"
        placeholder="Enter Cluster Name"
        helperText={meta.touched ? meta.error : null}
        onScale-change={(e) => {
          field.onChange(e);
          setFieldTouched('clusterName');
        }}
      />
    </div>
  );
};

const CaasOrder = () => {
  const navigate = useNavigate();
  const createCaasCluster = useApiCall(Caas.createClusterRequest, null, null);
  const userData = useSelector((state) => state.user);

  const validationSchema = Yup.object().shape({
    projectId: Yup.string().required('Required'),
    productId: Yup.string().required('Required'),
    cloudName: Yup.string().required('Required'),
    clusterName: Yup.string().required('Required').matches(regexValidator.noSpace, 'Spaces not allowed'),
    workersFlavor: Yup.string().required('Required'),
    workersAmount: Yup.number().required('Required').min(0),
    diskQuota: Yup.number().required('Required').min(0),
    floatingIps: Yup.number().required('Required').min(0),
    enviroment: Yup.string().required('Required'),
    clusterExposure: Yup.string().required('Required'),
    k8sVersion: Yup.string().required('Required'),
  });

  const createCluster = async (values) => {
    const [data] = await createCaasCluster({
      projectId: values.projectId,
      productId: values.productId,
      cloudId: values.cloudName,
      name: values.clusterName,
      workersAmount: values.workersAmount,
      workersFlavor: values.workersFlavor,
      totalDiskQuota: values.diskQuota,
      floatingIps: values.floatingIps,
      enviroment: values.enviroment,
      clusterExposure: values.clusterExposure,
      k8sVersion: values.k8sVersion,
      requestedBy: userData.email,
      type: 'Create',
    });
    if (data !== null) {
      navigate('/products/caas-on-openstack', { state: { productOrdered: true, messageId: 'CaasRequestCreated' } });
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" color="primary" display="inline">
        Cluster Request
      </Typography>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={createCluster}
      >
        {({ dirty, isValid }) => (
          <Form>
            <ClusterNameField />
            <ProjectField />
            <ProductsField />
            <CloudField />
            <K8sVersionField />
            <DiskQuotaField />
            <FloatingIpsField />
            <WorkersFlavorField />
            <WorkersAmountField />
            <EnviromentField />
            <ClusterExposureField />
            <div className="form-buttons">
              <ScaleButton disabled={!dirty || !isValid} variant="primary">
                Submit
              </ScaleButton>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  );
};


export default CaasOrder;
