import { Typography } from '@mui/material';
import { scrollWithOffset } from './scrollWithOffset';

const markdownOptions = {
  forceBlock: true,
  overrides: {
    h1: {
      component: Typography,
      props: {
        variant: 'h4',
        color: 'primary',
        gutterBottom: true,
        align: 'center',
      },
    },
    h2: {
      component: Typography,
      props: {
        variant: 'h5',
        color: 'primary',
        gutterBottom: true,
      },
    },
    h3: {
      component: Typography,
      props: {
        variant: 'h6',
        color: 'primary',
        gutterBottom: true,
      },
    },
    h4: {
      component: Typography,
      props: {
        variant: 'subtitle1',
        color: 'primary',
        gutterBottom: true,
      },
    },
    h5: {
      component: Typography,
      props: {
        variant: 'subtitle2',
        gutterBottom: true,
      },
    },
    p: {
      component: Typography,
      props: {
        variant: 'body1',
        paragraph: true,
      },
    },
    li: {
      component: Typography,
      props: {
        component: 'li',
      },
    },
    td: {
      component: Typography,
      props: {
        component: 'td',
        variant: 'body1',
        style: {
          border: '1px solid #c9c9c9',
          padding: '0 1rem 0 .5rem',
        },
      },
    },
    th: {
      component: Typography,
      props: {
        component: 'th',
        variant: 'body',
        style: {
          border: '1px solid #c9c9c9',
          padding: '0 1rem 0 .5rem',
        },
      },
    },
    table: {
      props: {
        style: { marginBottom: 15 },
      },
    },
    a: {
      props: {
        onClick: (e) => {
          const href = e.target.getAttribute('href');
          if (href.startsWith('#')) {
            e.preventDefault();
            scrollWithOffset(document.getElementById(href.substring(1)));
          }
        },
      },
    },
  },
};

export {
  markdownOptions,
};
