export const ORDER_INSTANCE_STARTED = 'ORDER_INSTANCE_STARTED';
export const ORDER_INSTANCE_FULFILLED = 'ORDER_INSTANCE_FULFILLED';
export const FETCH_INSTANCE_STARTED = 'FETCH_INSTANCE_STARTED';
export const FETCH_INSTANCE_FULFILLED = 'FETCH_INSTANCE_FULFILLED';

export const orderInstance = (data) => (
  { type: ORDER_INSTANCE_STARTED, payload: data }
);

export const orderInstanceFulfilled = () => (
  { type: ORDER_INSTANCE_FULFILLED }
);

export const fetchInstance = () => (
  { type: FETCH_INSTANCE_STARTED }
);

export const fetchInstancefulfilled = (data) => (
  { type: FETCH_INSTANCE_FULFILLED, payload: data }
);
