import { ofType, combineEpics } from 'redux-observable';
import { mergeMap, catchError } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { handleError } from '../../errorHandler';
import Safescarf from '../../../api/safescarf';
import {
  ORDER_INSTANCE_STARTED,
  orderInstanceFulfilled,
  FETCH_INSTANCE_STARTED,
  fetchInstancefulfilled,
} from './actions';

const orderInstanceEpic = (action$) => action$.pipe(
  ofType(ORDER_INSTANCE_STARTED),
  mergeMap((action) => from(Safescarf.createSafescarfInstance(action.payload)).pipe(
    mergeMap(() => of(
      orderInstanceFulfilled(),
    )),
    catchError(handleError),
  )),
);

const fetchInstanceEpic = (action$) => action$.pipe(
  ofType(FETCH_INSTANCE_STARTED),
  mergeMap(() => from(Safescarf.fetchSafescarfInstance()).pipe(
    mergeMap((response) => of(
      fetchInstancefulfilled(response),
    )),
    catchError(handleError),
  )),
);

export default combineEpics(
  orderInstanceEpic,
  fetchInstanceEpic,
);
