import { Typography, Grid } from '@mui/material';

const SomethingWentWrong = () => (
  <Grid
    container
    direction="column"
    alignItems="center"
    spacing={1}
    pt={20}
  >
    <Grid item>
      <Typography variant="h2" color="primary">
        Apologies.
      </Typography>
    </Grid>
    <Grid item>
      <Typography variant="h6">
        Something went wrong.
      </Typography>
    </Grid>
    <Grid item>
      <Typography variant="h6" paragraph>
        We are working on it.
      </Typography>
    </Grid>
  </Grid>
);

export default SomethingWentWrong;
