import rules from './rules';

const rbacCheck = (userRoles, action, data) => {
  const permissions = rules[userRoles?.[0]];
  // Return FALSE if rules DO NOT include this role
  if (!permissions) return false;

  const staticPermissions = permissions.static;
  // Return TRUE if user has static permission for the specified action
  if (staticPermissions && staticPermissions.includes(action)) return true;

  const dynamicPermissions = permissions.dynamic;
  if (dynamicPermissions) {
    // Return FALSE if dynamic rules DO NOT include the specified action
    const permissionCondition = dynamicPermissions[action];
    if (!permissionCondition) return false;
    // Return the function that will determine if user is entitled based on data param
    return permissionCondition(data);
  }
  return false;
};

export default rbacCheck;
