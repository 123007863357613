export const FETCH_SAFESCARF_PRODUCTS_STARTED = 'FETCH_SAFESCARF_PRODUCTS_STARTED';
export const FETCH_SAFESCARF_PRODUCTS_FULFILLED = 'FETCH_SAFESCARF_PRODUCTS_FULFILLED';
export const UPDATE_SAFESCARF_PRODUCT_STARTED = 'UPDATE_SAFESCARF_PRODUCT_STARTED';
export const UPDATE_SAFESCARF_PRODUCT_FULFILLED = 'UPDATE_SAFESCARF_PRODUCT_FULFILLED';
export const CREATE_SAFESCARF_PRODUCT_STARTED = 'CREATE_SAFESCARF_PRODUCT_STARTED';
export const CREATE_SAFESCARF_PRODUCT_FULFILLED = 'CREATE_SAFESCARF_PRODUCT_FULFILLED';
export const DELETE_SAFESCARF_PRODUCT_STARTED = 'DELETE_SAFESCARF_PRODUCT_STARTED';
export const DELETE_SAFESCARF_PRODUCT_FULFILLED = 'DELETE_SAFESCARF_PRODUCT_FULFILLED';

export const fetchProducts = (productId) => (
  { type: FETCH_SAFESCARF_PRODUCTS_STARTED, payload: productId }
);

export const fetchProductsFulfilled = (data) => (
  { type: FETCH_SAFESCARF_PRODUCTS_FULFILLED, payload: data }
);

export const updateProduct = (data) => (
  { type: UPDATE_SAFESCARF_PRODUCT_STARTED, payload: data }
);

export const updateProductFulfilled = (data) => (
  { type: UPDATE_SAFESCARF_PRODUCT_FULFILLED, payload: data }
);

export const createProduct = (data) => (
  { type: CREATE_SAFESCARF_PRODUCT_STARTED, payload: data }
);

export const createProductFulfilled = (data) => (
  { type: CREATE_SAFESCARF_PRODUCT_FULFILLED, payload: data }
);

export const deleteProduct = (id) => (
  { type: DELETE_SAFESCARF_PRODUCT_STARTED, payload: id }
);

export const deleteProductFulfilled = (id) => (
  { type: DELETE_SAFESCARF_PRODUCT_FULFILLED, payload: id }
);
