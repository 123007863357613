import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { ScaleTable, ScaleButton } from '@telekom/scale-components-react';
import { Typography } from '@mui/material';
import Provision from '../../../../../api/provision';
import useApiCall from '../../../../../hooks/useApiCall';

function ramToMegabytes(ram) {
  return ram * 1024;
}

const ForecastTable = ({ juiceGroup, onSubmit }) => {
  const intl = useIntl();
  const fetchForecasts = useApiCall(Provision.fetchForecastCapacity, null, null);
  const createVirtualClouds = useApiCall(Provision.createVirtualClouds, 'forecastsApplySuccess', 'forecastsApplyFailure');
  const [forecasts, setForecasts] = useState([]);

  useEffect(() => {
    async function getForecasts() {
      const [data] = await fetchForecasts(juiceGroup);
      if (data) setForecasts(data.length ? data : null);
    }
    getForecasts();
  }, []);

  return (
    <>
      {forecasts
        ? (
          <ScaleTable>
            <table>
              <thead>
                <tr>
                  <th>Cloud Id</th>
                  <th>vCPU</th>
                  <th>RAM (GB)</th>
                  <th>Storage (GB)</th>
                  <th>Floating Ips</th>
                </tr>
              </thead>
              <tbody>
                {forecasts.map((forecast) => (
                  <tr key={forecast.cloudId}>
                    <td>{forecast.cloudId}</td>
                    <td>{forecast.compute.cores}</td>
                    <td>{forecast.compute.ram}</td>
                    <td>{forecast.volume.gigabytes}</td>
                    <td>{forecast.network.floatingIps}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </ScaleTable>
        ) : <Typography gutterBottom>{intl.formatMessage({ id: 'forecastsNotFound' })}</Typography>}
      <ScaleButton
        slot="action"
        disabled={!forecasts || !forecasts?.length}
        variant="primary"
        onClick={async () => {
          const [data] = await createVirtualClouds(forecasts.map((forecast) => ({
            ...forecast,
            compute: {
              ...forecast.compute,
              ram: ramToMegabytes(forecast.compute.ram),
            },
          })));
          if (data) onSubmit();
        }}
      >
        Apply
      </ScaleButton>
    </>
  );
};

ForecastTable.propTypes = {
  juiceGroup: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ForecastTable;
