/* eslint-disable react/no-danger */
import { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import useApiCall from '../../hooks/useApiCall';
import DocumentationMS from '../../api/documentation';


const PageContent = ({ pageTitle }) => {
  const getPageContent = useApiCall(DocumentationMS.getPageContent);

  const [htmlContent, setHtmlContent] = useState('');

  async function getContent(title) {
    const [data] = await getPageContent(title);
    if (data) setHtmlContent(data);
  }

  useEffect(() => {
    getContent(pageTitle);
  }, [pageTitle]);

  return htmlContent ? (
    <>
      <Typography variant="h4" color="primary">{pageTitle}</Typography>
      <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
    </>
  ) : null;
};

PageContent.propTypes = {
  pageTitle: PropTypes.string.isRequired,
};

export default PageContent;
