import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import SetPassword from './SetPassword';
import TokenExpired from '../Error/TokenExpired';

import useApiCall from '../../hooks/useApiCall';
import Auth from '../../api/auth';


const UserActivation = () => {
  const navigate = useNavigate();
  const { token: verificationToken } = useParams();
  const validateVerificationTokenCall = useApiCall(Auth.validateVerificationToken, null, 'incorrectToken');
  const activateUserCall = useApiCall(Auth.activateUser, 'userActivated', 'userActivationErr');
  const [userVerification, setUserVerification] = useState(undefined);

  const activateUser = async (newPassword) => {
    const [data] = await activateUserCall({ verificationToken, newPassword, gtacAccepted: true });
    if (data) navigate('/');
  };

  const validateToken = async () => {
    const [data] = await validateVerificationTokenCall(verificationToken);
    if (data) setUserVerification(data);
  };

  useEffect(() => {
    validateToken();
  }, []);


  if (userVerification?.verificationTokenExpired) return <TokenExpired />;
  return (
    userVerification?.organizationName
      ? <SetPassword onSubmit={activateUser} organizationName={userVerification.organizationName} />
      : null
  );
};

export default UserActivation;
