export const REQUEST_STARTED = 'REQUEST_STARTED';
export const REQUEST_FULFILLED = 'REQUEST_FULFILLED';
export const REQUEST_FAILED = 'REQUEST_FAILED';
export const RESET_DELETION_STATUS = 'RESET_DELETION_STATUS';
export const SET_ERROR = 'SET_ERROR';
export const RESET_ERROR = 'RESET_ERROR';

export const requestStarted = () => (
  { type: REQUEST_STARTED }
);

export const requestFulfilled = () => (
  { type: REQUEST_FULFILLED }
);

export const requestFailed = () => (
  { type: REQUEST_FAILED }
);

export const resetDeletionStatus = () => (
  { type: RESET_DELETION_STATUS }
);

export const setError = (message) => ({
  type: SET_ERROR,
  payload: message,
});

export const resetError = () => (
  { type: RESET_ERROR }
);
