import { FETCH_ROLES_FULFILLED } from './actions';


const rolesReducer = (state = [], action) => {
  switch (action.type) {
    case FETCH_ROLES_FULFILLED:
      return action.payload;
    default:
      return state;
  }
};

export { rolesReducer };

