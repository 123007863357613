import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Grid, Typography } from '@mui/material';
import {
  ScaleTable,
  ScaleButton,
  ScaleTooltip,
} from '@telekom/scale-components-react';

const OverviewTable = ({
  header,
  rows,
  editAction,
  deleteAction,
  extraActions = [],
}) => {
  const intl = useIntl();

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
    >
      <Grid item>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          paddingBottom="1rem"
        >
          <Grid item>
            <Typography variant="h4" color="primary">{header}</Typography>
          </Grid>
          <Grid item>
            <Grid container columnGap={1}>
              {extraActions.map((action) => (
                <Grid item key={action.label}>
                  <ScaleButton
                    disabled={action.disabled}
                    onClick={action.onClick}
                  >
                    {action.label}
                  </ScaleButton>
                </Grid>
              ))}
              {editAction && (
              <Grid item>
                <ScaleTooltip
                  content={intl.formatMessage({ id: editAction?.tooltip })}
                  disabled={!editAction.disabled}
                >
                  <ScaleButton
                    disabled={editAction.disabled}
                    onClick={editAction.onClick}
                  >
                    {editAction.label || 'Edit'}
                  </ScaleButton>
                </ScaleTooltip>
              </Grid>
              )}
              {deleteAction && (
              <Grid item>
                <ScaleTooltip
                  content={deleteAction.tooltip && intl.formatMessage({ id: deleteAction.tooltip })}
                  disabled={!deleteAction.disabled}
                >
                  <ScaleButton
                    variant="secondary"
                    disabled={deleteAction.disabled}
                    onClick={deleteAction.onClick}
                  >
                    {deleteAction.label || 'Delete'}
                  </ScaleButton>
                </ScaleTooltip>
              </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <ScaleTable>
          <table>
            <tbody>
              {rows?.map((row) => (
                <tr key={row.title}>
                  <td><b>{row.title}</b></td>
                  <td align="right">
                    {row.component || <Typography>{row.value || 'N/A'}</Typography>}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </ScaleTable>
      </Grid>
    </Grid>
  );
};

OverviewTable.propTypes = {
  header: PropTypes.string,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      path: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.node,
      ]),
    }),
  ),
  editAction: PropTypes.shape({
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    tooltip: PropTypes.string,
    label: PropTypes.string,
  }),
  deleteAction: PropTypes.shape({
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    tooltip: PropTypes.string,
    label: PropTypes.string,
  }),
  extraActions: PropTypes.arrayOf(
    PropTypes.shape({
      onClick: PropTypes.func,
      disabled: PropTypes.bool,
      label: PropTypes.string,
    }),
  ),
};

OverviewTable.defaultProps = {
  header: '',
  rows: [{}],
  editAction: undefined,
  deleteAction: undefined,
  extraActions: [],
};

export default OverviewTable;
