import { Container } from '@mui/material';
import useMarkdownFile from '../../hooks/useMarkdownFile';
import gtac from '../../markdowns/gtac.md';

const Gtac = () => {
  const { markdownContent } = useMarkdownFile(gtac);

  return (
    <Container maxWidth="lg">
      {markdownContent}
    </Container>
  );
};

export default Gtac;
