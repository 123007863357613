import {
  useField,
  useFormikContext,
} from 'formik';
import PropTypes from 'prop-types';
import { DateField } from '../../InputFields';

const today = new Date().toISOString().split('T')[0];

export const FormikDateField = ({
  name,
  label,
  autoFocus,
  disabled,
  inputProps,
}) => {
  const [field, meta] = useField(name);
  const { values } = useFormikContext();
  const isDisabled = typeof disabled === 'string' ? !values[disabled] : disabled;

  return (
    <DateField
      id={field.name}
      name={field.name}
      value={field.value}
      onChange={field.onChange}
      onBlur={field.onBlur}
      label={label}
      autoFocus={autoFocus}
      disabled={isDisabled}
      helperText={meta.touched ? meta.error : ''}
      inputProps={inputProps}
      InputLabelProps={{ shrink: true }}
    />
  );
};

FormikDateField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  inputProps: PropTypes.shape({
    min: PropTypes.string,
    max: PropTypes.string,
    step: PropTypes.number,
  }),
};

FormikDateField.defaultProps = {
  autoFocus: false,
  disabled: false,
  inputProps: { min: today },
};
