import { ofType, combineEpics } from 'redux-observable';
import { mergeMap, catchError } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { handleError } from '../../errorHandler';
import Safescarf from '../../../api/safescarf';
import {
  CREATE_SAFESCARF_USER_STARTED,
  createUserFulfilled,
  FETCH_SAFESCARF_USERS_STARTED,
  fetchUsersFulfilled,
  UPDATE_SAFESCARF_USER_STARTED,
  updateUserFulfilled,
} from './actions';

const createUserEpic = (action$) => action$.pipe(
  ofType(CREATE_SAFESCARF_USER_STARTED),
  mergeMap((action) => from(Safescarf.createSafescarfUser(action.payload)).pipe(
    mergeMap((response) => of(
      createUserFulfilled(response.data),
    )),
    catchError(handleError),
  )),
);
const fetchUsersEpic = (action$) => action$.pipe(
  ofType(FETCH_SAFESCARF_USERS_STARTED),
  mergeMap((action) => from(Safescarf.fetchSafescarfUsers(action.payload)).pipe(
    mergeMap((response) => of(
      fetchUsersFulfilled(response.data),
    )),
    catchError(handleError),
  )),
);
const updateUserEpic = (action$) => action$.pipe(
  ofType(UPDATE_SAFESCARF_USER_STARTED),
  mergeMap((action) => from(Safescarf.updateSafescarfUser(action.payload)).pipe(
    mergeMap((response) => of(
      updateUserFulfilled(response.data),
    )),
    catchError(handleError),
  )),
);

export default combineEpics(
  createUserEpic,
  fetchUsersEpic,
  updateUserEpic,
);
