import { useRouteError } from 'react-router-dom';

import Page404 from './Page404';
import SomethingWentWrong from './SomethingWentWrong';

const FallBackError = () => {
  const error = useRouteError();

  if (error.message === 'ERR_NOT_FOUND') return <Page404 />;

  return <SomethingWentWrong />;
};

export default FallBackError;
