import {
  FormControl as FormControlMUI,
  FormControlLabel as FormControlLabelMUI,
  Checkbox as CheckboxMUI,
} from '@mui/material';
import PropTypes from 'prop-types';

export const Checkbox = ({
  id,
  name,
  onChange,
  onBlur,
  label,
  disabled,
  checked,
  importanceRef,
}) => (
  <FormControlMUI
    onChange={importanceRef && importanceRef.current?.scrollIntoView()}
  >
    <FormControlLabelMUI
      label={label}
      control={(
        <CheckboxMUI
          id={id}
          name={name}
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          onBlur={onBlur}
          disableRipple
        />
      )}
    />
  </FormControlMUI>
);

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  checked: PropTypes.bool.isRequired,
  importanceRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
};

Checkbox.defaultProps = {
  onBlur: () => {},
  disabled: false,
  importanceRef: null,
};
